import { Component, ViewChild, Injector, Output, EventEmitter} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { EmplolyeeCodeSettingsServiceProxy, CreateOrEditEmplolyeeCodeSettingDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';



@Component({
    selector: 'createOrEditEmplolyeeCodeSettingModal',
    templateUrl: './create-or-edit-emplolyeeCodeSetting-modal.component.html'
})
export class CreateOrEditEmplolyeeCodeSettingModalComponent extends AppComponentBase {
   
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;

    emplolyeeCodeSetting: CreateOrEditEmplolyeeCodeSettingDto = new CreateOrEditEmplolyeeCodeSettingDto();



    constructor(
        injector: Injector,
        private _emplolyeeCodeSettingsServiceProxy: EmplolyeeCodeSettingsServiceProxy
    ) {
        super(injector);
    }
    
    show(emplolyeeCodeSettingId?: number): void {
    

        if (!emplolyeeCodeSettingId) {
            this.emplolyeeCodeSetting = new CreateOrEditEmplolyeeCodeSettingDto();
            this.emplolyeeCodeSetting.id = emplolyeeCodeSettingId;

            this.active = true;
            this.modal.show();
        } else {
            this._emplolyeeCodeSettingsServiceProxy.getEmplolyeeCodeSettingForEdit(emplolyeeCodeSettingId).subscribe(result => {
                this.emplolyeeCodeSetting = result.emplolyeeCodeSetting;


                this.active = true;
                this.modal.show();
            });
        }
        
    }

    save(): void {
            this.saving = true;

			
			
            this._emplolyeeCodeSettingsServiceProxy.createOrEdit(this.emplolyeeCodeSetting)
             .pipe(finalize(() => { this.saving = false;}))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
             });
    }







    close(): void {
        this.active = false;
        this.modal.hide();
    }
}
