import { Component, ViewChild, Injector, Output, EventEmitter} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { DesignationsServiceProxy, CreateOrEditDesignationDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
//import * as moment from 'moment';

@Component({
    selector: 'createOrEditDesignationModal',
    templateUrl: './create-or-edit-designation-modal.component.html'
})
export class CreateOrEditDesignationModalComponent extends AppComponentBase {
   
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;

    designation: CreateOrEditDesignationDto = new CreateOrEditDesignationDto();



    constructor(
        injector: Injector,
        private _designationsServiceProxy: DesignationsServiceProxy
    ) {
        super(injector);
    }
    onShown(): void {

        document.getElementById('Designation_Name').focus();
    }
   
    show(designationId?: number): void {
    

        if (!designationId) {
            this.designation = new CreateOrEditDesignationDto();
            this.designation.id = designationId;

            this.active = true;
            this.modal.show();
        } else {
            this._designationsServiceProxy.getDesignationForEdit(designationId).subscribe(result => {
                this.designation = result.designation;


                this.active = true;
                this.modal.show();
            });
        }
        
    }

    save(): void {
            this.saving = true;
            if(this.designation.name=="" || this.designation.name==undefined)
            {
                this.notify.error("Please enter designation")
                this.saving = false;
                return ;
            }
			
			
            this._designationsServiceProxy.createOrEdit(this.designation)
             .pipe(finalize(() => { this.saving = false;}))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
             });
    }







    close(): void {
        this.active = false;
        this.modal.hide();
    }
}
