import { Component, ViewChild, Injector, Output, EventEmitter} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { DivisionsServiceProxy, CreateOrEditDivisionDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';

@Component({
    selector: 'createOrEditDivisionModal',
    templateUrl: './create-or-edit-division-modal.component.html'
})
export class CreateOrEditDivisionModalComponent extends AppComponentBase {
   
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;

    division: CreateOrEditDivisionDto = new CreateOrEditDivisionDto();



    constructor(
        injector: Injector,
        private _divisionsServiceProxy: DivisionsServiceProxy
    ) {
        super(injector);
    }
    
    show(divisionId?: number): void {
    

        if (!divisionId) {
            this.division = new CreateOrEditDivisionDto();
            this.division.id = divisionId;

            this.active = true;
            this.modal.show();
        } else {
            this._divisionsServiceProxy.getDivisionForEdit(divisionId).subscribe(result => {
                this.division = result.division;


                this.active = true;
                this.modal.show();
            });
        }
        
    }
    onShown(): void {

        document.getElementById('Division_Name').focus();
    }
    save(): void {
            this.saving = true;
           
			
			
            this._divisionsServiceProxy.createOrEdit(this.division)
             .pipe(finalize(() => { this.saving = false;}))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
             });
    }







    close(): void {
        this.active = false;
        this.modal.hide();
    }
}
