import { Injector, Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { AbpSessionService } from '@abp/session/abp-session.service';
import { ImpersonationService } from '@app/admin/users/impersonation.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { LinkedAccountService } from '@app/shared/layout/linked-account.service';
import { AppConsts } from '@shared/AppConsts';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { ChangeUserLanguageDto, EmployeesServiceProxy, LinkedUserDto, ProfileServiceProxy, UserLinkServiceProxy, TenantDashboardServiceProxy, NewHireingOutput, AttendancesServiceProxy, GetPunchesForViewDto, ReviewStatusServiceProxy } from '@shared/service-proxies/service-proxies';
import * as _ from 'lodash';
import { PupUpMarkattendanceComponent } from '@app/main/markattendance/pup-up-markattendance/pup-up-markattendance.component';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
    templateUrl: './topbar.component.html',
    selector: 'topbar',
    encapsulation: ViewEncapsulation.None
})


export class TopBarComponent extends ThemesLayoutBaseComponent implements OnInit {

    isHost = false;
    languages: abp.localization.ILanguageInfo[];
    currentLanguage: abp.localization.ILanguageInfo;
    isImpersonatedLogin = false;
    isMultiTenancyEnabled = false;
    shownLoginName = '';
    tenancyName = '';
    userName = '';
    Name = '';
    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + this.currentTheme.baseSettings.menu.asideSkin + '.svg';
    recentlyLinkedUsers: LinkedUserDto[];
    unreadChatMessageCount = 0;
    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;
    chatConnected = false;
    isQuickThemeSelectEnabled: boolean = this.setting.getBoolean('App.UserManagement.IsQuickThemeSelectEnabled');
    adminuser: boolean = false;
    advanceRequests: number;
    AdminconformationPending: number = 0;
    newHire: NewHireingOutput[];
    punchCount: number;
    noPunch: boolean = true;
    evenPunch: boolean = false;
    oddPunch: boolean = false;
    punchdata: GetPunchesForViewDto[];
    len: number;
    minFormDateDateFilter: moment.Moment;
    maxToDateDateFilter: moment.Moment;
    // buttonText = 'New Review';
    reviewID : number;
    working_hours:string="";
    constructor(
        private router: Router,
        injector: Injector,
        private _attendancesServiceProxy:AttendancesServiceProxy,
        private _abpSessionService: AbpSessionService,
        private _abpMultiTenancyService: AbpMultiTenancyService,
        private _profileServiceProxy: ProfileServiceProxy,
        private _userLinkServiceProxy: UserLinkServiceProxy,
        private _authService: AppAuthService,
        private _impersonationService: ImpersonationService,
        private _linkedAccountService: LinkedAccountService,
        private _dashboardService: TenantDashboardServiceProxy,
        private _attendanceServiceProxy: AttendancesServiceProxy,
        private _employee: EmployeesServiceProxy,
        private _reviewStatusServiceProxy: ReviewStatusServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit() {
        
        this.isHost = !this._abpSessionService.tenantId;
        this.isMultiTenancyEnabled = this._abpMultiTenancyService.isEnabled;
        this.languages = _.filter(this.localization.languages, l => (l).isDisabled === false);
        this.currentLanguage = this.localization.currentLanguage;
        this.isImpersonatedLogin = this._abpSessionService.impersonatorUserId > 0;
        this.setCurrentLoginInformations();
        this.getProfilePicture();
        this.getRecentlyLinkedUsers();
        this.registerToEvents();
        
        this.minFormDateDateFilter = moment().startOf('day');
        // setInterval(()=>{
        //     this.getWorkingHours(); // Total Working Hours (Abhishek)
        // },1000)
        this.maxToDateDateFilter = moment().startOf('day');
        if (this.appSession.user.userName == 'admin') {
            this.adminuser = true;
        }
        if(this.isHost == false){            
            this.reload();
            this.getCountPunch();
            // if(this.permission.isGranted('Pages.EmployeeSelfReview.Create'))
            //     {
            //         // this.getButtonText();
            //         this.showReview();
            //     }
            
           
        }
        //this.getCountPunch();
       
    }

    getCountPunch() {
        
        this._attendanceServiceProxy.viewPunches(this.minFormDateDateFilter, this.maxToDateDateFilter).subscribe(result => {
            this.punchdata = result;
            this.len = this.punchdata.length;
            if (this.len == 0) {
                this.noPunch = true;
                this.evenPunch = false;
                this.oddPunch = false;
            } else if (this.len % 2 == 0) {
                this.noPunch = false;
                this.evenPunch = true;
                this.oddPunch = false;
            } else if (this.len % 2 != 0) {
                this.noPunch = false;
                this.evenPunch = false;
                this.oddPunch = true;
            }
        });
    }

    reload() {
        this.getCountPunch();
        if (this.appSession.user.userName != 'admin') {
            this._dashboardService
                .advanceRequests()
                .subscribe(result => {
                    this.advanceRequests = result;
                    //console.log"New hreing", this.newHire)

                });
        }
        else {
            this._dashboardService
                .adminAdvanceRequest()
                .subscribe(result => {
                    this.AdminconformationPending = result;
                    //console.log"New hreing", this.newHire)

                });
        }
    }
    AdvanceEmployee(): void {

        if (this.appSession.user.userName == 'admin') {
            this.notify.info("Admin Not Allowed To Take Any action")
            return;
        }

        this.router.navigateByUrl('app/main/requestForApproval/requestForApprovals');
    }

    registerToEvents() {
        abp.event.on('profilePictureChanged', () => {
            this.getProfilePicture();
        });

        abp.event.on('app.chat.unreadMessageCountChanged', messageCount => {
            this.unreadChatMessageCount = messageCount;
        });

        abp.event.on('app.chat.connected', () => {
            this.chatConnected = true;
        });

        abp.event.on('app.getRecentlyLinkedUsers', () => {
            this.getRecentlyLinkedUsers();
        });

        abp.event.on('app.onMySettingsModalSaved', () => {
            this.onMySettingsModalSaved();
        });
    }

    changeLanguage(languageName: string): void {
        const input = new ChangeUserLanguageDto();
        input.languageName = languageName;

        this._profileServiceProxy.changeLanguage(input).subscribe(() => {
            abp.utils.setCookieValue(
                'Abp.Localization.CultureName',
                languageName,
                new Date(new Date().getTime() + 5 * 365 * 86400000), //5 year
                abp.appPath
            );

            window.location.reload();
        });
    } 

    setCurrentLoginInformations(): void {
        
        this.shownLoginName = this.appSession.getShownLoginName();
        this.tenancyName = this.appSession.tenancyName;
        this.tenant.name= this.appSession.tenant.name;
        this.userName = this.appSession.user.userName;
        this.Name = this.appSession.user.name;
    }

    getShownUserName(linkedUser: LinkedUserDto): string {
        if (!this._abpMultiTenancyService.isEnabled) {
            return linkedUser.username;
        }

        return (linkedUser.tenantId ? linkedUser.tenancyName : '.') + '\\' + linkedUser.username;
    }

    getProfilePicture(): void {
        this._employee.getEmployeeProfilePicture().subscribe(result => {
            this.profilePicture = result;

        });
    }

    getRecentlyLinkedUsers(): void {
        this._userLinkServiceProxy.getRecentlyUsedLinkedUsers().subscribe(result => {
            this.recentlyLinkedUsers = result.items;
        });
    }


    showLoginAttempts(): void {
        abp.event.trigger('app.show.loginAttemptsModal');
    }

    showLinkedAccounts(): void {
        abp.event.trigger('app.show.linkedAccountsModal');
    }


    // tenancynotification = null;
    // showInactiveTenancyAlert() {
    //     this._dashboardService.showInActiveTenancyNotification().subscribe(message => {
    //         if (message !== null)
    //             this.tenancynotification = message
    //     })
    // }


    changeSttandance(): void {
        abp.event.trigger('app.show.pupUpMarkattendance'); // Triggering event
      
       
      }
      
    //  getButtonText(){
    //     this._reviewStatusServiceProxy.checkPendingReviewForEmployeeForPopUP().subscribe(result=>{
    //         if(result != null || result.length != 0)
    //         {
    //           this.buttonText = result[0].buttonText;
    //           this.reviewID = result[0].reviewId;
    //         }
            
    //        });
    //  }
showReview():void{
    abp.event.trigger('app.show.employeeReviewPopUp',this.reviewID);
}

    changePassword(): void {
        abp.event.trigger('app.show.changePasswordModal');
    }
    changeProfilePicture(): void {
        abp.event.trigger('app.show.changeProfilePictureModal');
    }

    changeMySettings(): void {
        abp.event.trigger('app.show.mySettingsModal');
    }

    logout(): void {
    
        this._authService.logout();
    }

    onMySettingsModalSaved(): void {
        this.shownLoginName = this.appSession.getShownLoginName();
    }

    backToMyAccount(): void {
        this._impersonationService.backToImpersonator();
    }

    switchToLinkedUser(linkedUser: LinkedUserDto): void {
        this._linkedAccountService.switchToAccount(linkedUser.id, linkedUser.tenantId);
    }

    downloadCollectedData(): void {
        this._profileServiceProxy.prepareCollectedData().subscribe(() => {
            this.message.success(this.l('GdprDataPrepareStartedNotification'));
        });
    }

    // createMarkAttendance(): void {

    //     this.markattendance.show();        
    // }
    MyProfilePicture() {
        //
        this.router.navigateByUrl('app/main/myprofileemployee/myprofileemployee/' + 0);
    }
    // getWorkingHours(): void {
    //     this._attendancesServiceProxy.getWorkingHours().subscribe((result) => {
    //         let modifiedHours = result.hours.slice(0, 8); 
    
    //         // Inserting 'h' at position 3
    //         modifiedHours = modifiedHours.slice(0, 2) + 'h' + modifiedHours.slice(2);
    
    //         // Inserting 'm' at position 6
    //         modifiedHours = modifiedHours.slice(0, 6) + 'm' + modifiedHours.slice(6);
    
    //         // Inserting 's' at position 10
    //         modifiedHours = modifiedHours.slice(0, 10) + 's' + modifiedHours.slice(10);
    
       
            
    
    //         this.working_hours = modifiedHours;
    //     });
    // }   
}
