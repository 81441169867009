<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
    aria-labelledby="createOrEditModal" aria-hidden="true" [config]="{backdrop: 'static', keyboard: false}">
    <div class="modal-dialog modal-lg employee-modal2">
        <div class="modal-content">
            <form *ngIf="active" #reviewedForm="ngForm" novalidate autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span *ngIf="reviewStatus.id">{{l("Edit Self Review")}}</span>
                        <span *ngIf="!reviewStatus.id">{{l("Create Self Review")}}</span>
                    </h4>
                    <button type="button" class="close" (click)="close()" aria-label="Close" [disabled]="saving">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-5 col-md-2 mb-3">
                            <h6>{{l("Employee Name")}} :</h6>
                        </div>
                        <div class="col-6 col-md-6 mb-3">
                            {{ employeeName }}
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-5 col-md-2 mb-3">
                            <h6>{{l("Cycle")}} :</h6>
                        </div>
                        <!-- <div class="col-6 col-md-6 mb-3" *ngIf="timeperiod == 1 ? false : true">
                            {{cyclename}} <span> ({{ fromDate | date: 'MMMM y'}} - {{toDate | date: 'MMMM y'}})</span>
                        </div>
                        <div class="col-6 col-md-6 mb-3" *ngIf="timeperiod != 1 ? false : true">
                            {{cyclename}} <span> ({{ fromDate | date: 'MMMM y'}})</span>
                        </div> -->
                        <div class="col-md-3 mb-3">
                            <p-dropdown (onChange)="getDate()" [options]="employeeCycleData" [(ngModel)]="cyclevalue"
                                filter="true" name="CycleId" #CycleId="ngModel" styleClass="width-percent-100">
                            </p-dropdown>
                        </div>

                        <div class="col-md-6 mb-3">
                            <div class="row d-flex justify-content-end" *ngIf="showDate">
                                <div class="col-md-6">
                                    <div class="row mt-1 align-items-center">
                                        <div class="col-md-5">
                                            <label for="fromDate" style="font-weight:500;white-space:nowrap">From Date :</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control kt-input" id="fromdate" name="fromdate"
                                                type="datetime" bsDatepicker [disabled]="true" [(ngModel)]="fromDate">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row mt-1 align-items-center">
                                        <div class="col-md-5">
                                            <label for="fromDate" style="font-weight:500;">To Date :</label>
                                        </div>
                                        <div class="col-md-7">
                                            <input class="form-control kt-input" id="todate" name="todate"
                                                type="datetime" bsDatepicker [disabled]="true" [(ngModel)]="toDate">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div class="row mt-3">
                        <div class="col-5 col-md-2 mb-3">
                            <h6>{{l("Policy")}} :</h6>
                        </div>
                        <div class="col-6 col-md-6 mb-3">
                            {{ policyName }}

                        </div>

                    </div>
                    <div class="kt-portlet__body mt-4" *ngIf="datatable">
                        <div class="table-responsive new-table">
                            <table class="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th style="max-width:220px">KRA</th>
                                        <th style="width:11%">Weightage</th>
                                        <th style="width:125px">Actual</th>
                                        <th style="width:11%;white-space:normal!important">System Suggested</th>
                                        <th style="width:11%;white-space:normal!important">Self Assessment</th>
                                        <th style="max-width:220px">Remark</th>
                                        <th>Criteria</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let rowData of reviewedData; let i = index;">
                                        <td style="min-width:220px;max-width:220px;white-space:normal;">{{rowData.getCreateOrEditReviewedData.kraName}}</td>
                                        <td style="text-align: end;">{{rowData.getCreateOrEditReviewedData.weightage}}
                                        </td>
                                        <td style="min-width:7rem;max-width:8rem;">
                                            <input *ngIf="rowData.getCreateOrEditReviewedData.kraMeasurable=='C'"
                                                (input)="getCountScoreValue(rowData.getCreateOrEditReviewedData.kpI_Policy_kra_id,rowData.getCreateOrEditReviewedData.employeeFeedback,i)"
                                                type="number" name="ActualDataValue{{i}}" #NoticePeriod="ngModel"
                                                class="form-control" style="width:100%;text-align: end;padding-right:0"
                                                [(ngModel)]="rowData.getCreateOrEditReviewedData.employeeFeedback"
                                                min="0" max="{{rowData.maxValue}}" />

                                            <p-dropdown *ngIf="rowData.getCreateOrEditReviewedData.kraMeasurable=='F'"
                                                (ngModelChange)="onFeedbackChange($event, rowData.getCreateOrEditReviewedData.kpI_Policy_kra_id, i)"
                                                [(ngModel)]="tempFeedbackid[i]" [options]="FeedbackData"
                                                name="feebackstatus{{i}}" styleClass="width-percent-100"
                                                #feebackstatus="ngModel" inputStyleClass="form-control"></p-dropdown>
                                            <!-- <p-dropdown *ngIf="rowData.kraMeasurable=='F'" (ngModelChange)="getFeedbackScoreValue(rowData.kpI_Policy_kra_id,feedbackid[i],i)" [(ngModel)]="feedbackid[i]" [options]="FeedbackData"
                                    name="feebackstatus{{i}}" styleClass="width-percent-80"
                                    #feebackstatus="ngModel" inputStyleClass="form-control"></p-dropdown> -->
                                        </td>
                                        <td>
                                            <span
                                                *ngIf="rowData.getCreateOrEditReviewedData.kraMeasurable === 'F'">{{rowData.captured_Feedback_level}}</span>

                                            <span style="text-align: end; display: block;"
                                                *ngIf="rowData.getCreateOrEditReviewedData.kraMeasurable === 'C'">
                                                {{rowData.getCreateOrEditReviewedData.capturedData}}</span>
                                        </td>

                                        <td style="text-align: end;">
                                            {{rowData.getCreateOrEditReviewedData.selfAssessment}}</td>
                                        <td>
                                            <textarea name="Remark{{i}}" #Remark{{i}}="ngModel"
                                                class="form-control" style="width:100%; resize: vertical;height:35px;"
                                                [(ngModel)]="rowData.getCreateOrEditReviewedData.employeeRemark"></textarea>
                                        </td>
                                        <td class="text-center">
                                            <div>
                                                <a href="javascript:;"
                                                    (click)="showCriteria(rowData.getCreateOrEditReviewedData.kpI_Policy_kra_id)">View
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default"
                        (click)="close()">{{l("Cancel")}}</button>
                    <button type="submit" class="btn btn-primary blue" (click)="save(1)" [buttonBusy]="saving"
                        [disabled]="reachedMaxvalue" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i>
                        <span>{{l("Save")}}</span></button>
                    <button type="submit" class="btn btn-primary blue" (click)="save(2)" [buttonBusy]="submitting"
                        [disabled]="reachedMaxvalue" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-upload"></i>
                        <span>{{l("Submit")}}</span></button>
                </div>
            </form>
        </div>
    </div>
    <!--popup modal-->
    <div bsModal #viewCriteria="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="viewCriteria"
        aria-hidden="true" [config]="{backdrop: 'static'}">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="d-flex justify-content-between align-items-center w-100">
                        <div class="h3 mb-0">Criteria</div>
                        <button type="button" class="close" (click)="closedCriteria()" aria-label="Close"
                            [disabled]="saving">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <div class="row align-items-center mt-3" *ngIf="!enableDisable">
                    <!--<Primeng-Datatable-Start>-->
                    <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <ul>
                            <ng-container *ngFor="let record of criteriaData">
                                <li>
                                    <span> {{record.minValue}} -
                                        {{record.maxValue}} =
                                        {{record.criteriaScore}}</span>
                                </li>
                            </ng-container>
                        </ul>

                        <div class="primeng-no-data" *ngIf="criteriaDataLength == 0">
                            {{l('No Criteria has been defined.')}}
                        </div>
                    </div>
                    <!--<Primeng-Datatable-End>-->
                </div>
                <div class="row align-items-center mt-3" *ngIf="enableDisable">
                    <!--<Primeng-Datatable-Start>-->
                    <div class="primeng-datatable-container" [busyIf]="primengTableHelper.isLoading">
                        <ul>
                            <ng-container *ngFor="let record of criteriaData">
                                <li>
                                    <span> {{record.countValue}} =
                                        {{record.criteriaScore}}</span>
                                </li>
                            </ng-container>
                        </ul>

                        <div class="primeng-no-data" *ngIf="criteriaDataLength == 0">
                            {{l('No Criteria has been defined.')}}
                        </div>
                    </div>
                    <!--<Primeng-Datatable-End>-->
                </div>
            </div>
        </div>
    </div>
</div>