import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { HRMSReportsServiceServiceProxy } from "@shared/service-proxies/service-proxies";
import * as moment from 'moment';
import { Observable } from "rxjs";
@Injectable()
export class newJoiningReportDataResolver implements Resolve<any>
{
    sorting:string;
    skipCount:number;
    maxResultCount:number;
    minFormDateDateFilter:moment.Moment;
    maxToDateDateFilter:moment.Moment;
    naveen:string;
    constructor(private _hrmsReportServices:HRMSReportsServiceServiceProxy)
    {
        (moment as any).fn.toString = function() { return this.format('DD/MM/YYYY'); };
    }
    resolve(route: ActivatedRouteSnapshot): 
    Observable<any> | Promise<any> | any
    {
        
       this.naveen=route.routeConfig.path;
      //console.log"Naveen",this.naveen);
    
}
    
}