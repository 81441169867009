import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CreateOrEditDocumentTypeDto, DocumentTypeServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-create-or-edit-documenttype-modal',
  templateUrl: './create-or-edit-documenttype-modal.component.html'
})
export class CreateOrEditDocumenttypeModalComponent extends AppComponentBase {
  @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;

  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  active = false;
  saving = false;

  documentType: CreateOrEditDocumentTypeDto = new CreateOrEditDocumentTypeDto();
  constructor(
      injector: Injector,
      private _documentTypeServiceProxy: DocumentTypeServiceProxy,
  ) {
      super(injector);
  }
  
  ngOnInit(): void {  
   } 
  
  show(documentTypeId?: number): void {
      if (!documentTypeId) {
          this.documentType = new CreateOrEditDocumentTypeDto();
          this.documentType.id = documentTypeId;
          this.active = true;
          this.modal.show();
      } else {
          this._documentTypeServiceProxy.getDocumentForEdit(documentTypeId).subscribe(result => {
              this.documentType = result.documentType;
              //console.log"documnettype",this.documentType);
              this.active = true;
              this.modal.show();
          });
      }
 
      
  }
  onShown(): void {

      document.getElementById('documentName').focus();
  }
  save(): void {
          this.saving = true;
          this._documentTypeServiceProxy.createOrEdit(this.documentType)
           .pipe(finalize(() => { this.saving = false;}))
           .subscribe(() => {
              this.notify.info(this.l('SavedSuccessfully'));
              this.close();
              this.modalSave.emit(null);
           });
  }
  close(): void {
      this.active = false;
      this.modal.hide();
  }

}
