<div [ngClass]="{'subscription-bar-visible': subscriptionStatusBarVisible()}">
    <div *ngIf="tenancynotification" class="kt-header__topbar-item dropdown d-lg-block subscription-warning">
        <span   class="kt-nav__link-text glowing subscription-warning-text">
                <i class="fa fa-exclamation-triangle"></i>
                <!-- <span class="kt-menu__link-text ml-1">{{getExpireNotification("SubscriptionExpireNotification")}}</span> -->
                <!-- <span class="kt-menu__link-text ml-1">{{tenancynotification}}</span> -->
                <span class="kt-menu__link-text ml-1">{{tenancynotification}}</span>
            </span>
    </div>
    <default-layout *ngIf="theme=='default'"></default-layout>
    <theme2-layout *ngIf="theme=='theme2'"></theme2-layout>
    <theme3-layout *ngIf="theme=='theme3'"></theme3-layout>
    <theme4-layout *ngIf="theme=='theme4'"></theme4-layout>
    <theme5-layout *ngIf="theme=='theme5'"></theme5-layout>
    <theme6-layout *ngIf="theme=='theme6'"></theme6-layout>
    <theme7-layout *ngIf="theme=='theme7'"></theme7-layout>
    <theme8-layout *ngIf="theme=='theme8'"></theme8-layout>
    <theme9-layout *ngIf="theme=='theme9'"></theme9-layout>
    <theme10-layout *ngIf="theme=='theme10'"></theme10-layout>
    <theme11-layout *ngIf="theme=='theme11'"></theme11-layout>
    <theme12-layout *ngIf="theme=='theme12'"></theme12-layout>
</div>

<loginAttemptsModal #loginAttemptsModal></loginAttemptsModal>
<linkedAccountsModal *ngIf="!installationMode" #linkedAccountsModal (modalClose)="getRecentlyLinkedUsers()"></linkedAccountsModal>
<changePasswordModal #changePasswordModal></changePasswordModal>
<changeProfilePictureModal #changeProfilePictureModal></changeProfilePictureModal>
<mySettingsModal #mySettingsModal (modalSave)="onMySettingsModalSaved()"></mySettingsModal>
<notificationSettingsModal #notificationSettingsModal></notificationSettingsModal>

<commonLookupModal #userLookupModal (itemSelected)="chatBarComponent.addFriendSelected($event)"></commonLookupModal>
<chat-bar #chatBarComponent *ngIf="!installationMode" [userLookupModal]="userLookupModal"></chat-bar>
<theme-selection-panel #themeSelectionPanelComponent *ngIf="!installationMode && isQuickThemeSelectEnabled"></theme-selection-panel>
<app-pup-up-markattendance #pupUpMarkattendance></app-pup-up-markattendance>
<app-crate-or-edit-employee-review-pop-up #employeeReviewPopUp (modalSave)="getButtonText()"></app-crate-or-edit-employee-review-pop-up>