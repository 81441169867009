import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CreateOrEditExpensePolicy, DropDown, DropdownServicesServiceProxy, ExpensePolicyServiceProxy } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';
import {MultiSelectModule} from 'primeng/multiselect';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'createOrEditExpensePolicyModal',
  templateUrl: './createexpensepolicy.component.html',
  styleUrls: ['./createexpensepolicy.component.css']
})
export class CreateexpensepolicyComponent extends AppComponentBase {
  @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  active = false;
  saving = false;
  Expensep:boolean;
  expensePolicy: CreateOrEditExpensePolicy = new CreateOrEditExpensePolicy();
  expensesp:DropDown[];
  selectedExpenseNames: number[] = [];
  constructor(
    injector: Injector,
    private _expenseServiceProxy: ExpensePolicyServiceProxy,
    private _dropdownServiceProxy:DropdownServicesServiceProxy
  ) { 
    super(injector);
  }
  ngOnInit():void{
    this.filterexpenseName();
  }

  filterexpenseName()
  {

      this._dropdownServiceProxy.getExpenseName().subscribe(Customer=>{
        this.expensesp=Customer;
  })
}
  onShown(): void {

    document.getElementById('ExpensePolicy_Name').focus();
}

show(expensePolicyId?: number): void {
   this.filterexpenseName();
  
  if(this.expensesp.length==0)
  {
    this.notify.error("Please Create expense type first");
    this.active = false;
    this.saving  = false;
    return;
  }
 if (!expensePolicyId) {
     this.expensePolicy = new CreateOrEditExpensePolicy();
     this.expensePolicy.id = expensePolicyId;
     this.expensePolicy.expenseName = this.selectedExpenseNames;
   this.Expensep=false;
     this.active = true;
     this.modal.show();
 } else {
     this.Expensep=true;
     this._expenseServiceProxy.getPolicyForEdit(expensePolicyId).subscribe(result => {
         this.expensePolicy = result.expensePolicies;
         this.active = true;
         this.modal.show();
         this.modalSave.emit(null);
     });
 }
 
}

save(): void {

     this.saving = true;
     this.expensePolicy.expenseName = this.selectedExpenseNames;
     this._expenseServiceProxy.createOrEdit(this.expensePolicy)
      .pipe(finalize(() => { this.saving = false;}))
      .subscribe(() => {
         this.notify.info(this.l('SavedSuccessfully'));
         this.close();
         this.modalSave.emit(null);
      });
}



close(): void {
 this.active = false;
 this.modal.hide();
 this.selectedExpenseNames = [];
}

}
