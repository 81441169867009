import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ExpensePolicyServiceProxy, NewExpenseModule } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';
import { Paginator } from 'primeng/primeng';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-mappingexpense',
  templateUrl: './mappingexpense.component.html',
  styleUrls: ['./mappingexpense.component.css']
})
export class MappingexpenseComponent extends AppComponentBase {

  @ViewChild('dataTable', { static: true }) dataTable: Table;
  @ViewChild('paginator', { static: true }) paginator: Paginator;
  @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
exp:NewExpenseModule[];
  Expense = '';
  type='';
  designation='';
  policyid:number;
  name='';
  active = false;
  modalSave: any;
  expensePolicy: any;
  PolicyName:string;
  designationshow:boolean;
  employeeshow:boolean;
  saving:boolean;
  constructor
  (
    injector: Injector,
    private _expenseServiceProxy: ExpensePolicyServiceProxy
  )
   { 
    super(injector);
   }

  ngOnInit() {
  }
  show(id: number,designation?:number,employee?:number): void {
     
    
  
   this.active = true;
   this.policyid=id;
   
   this._expenseServiceProxy.expenseModule(this.policyid).subscribe(result=>{
    this.exp=result;
    if(designation!=null){
      this.exp=result.filter(e=>e.designation);
      this.designationshow=true;
      this.employeeshow=false;
    }
    if(employee!=undefined){
      this.exp=result.filter(e=>e.name);
      this.employeeshow=true;
      this.designationshow=false;
    
    }
    ////console.log)
    this.active = true;
    this.modal.show();
    this.modalSave.emit(null);
   });
   
  
  }

  close(): void {
    this.active = false;
    this.modal.hide();
  }

}
