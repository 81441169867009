import { Component, ViewChild, Injector, Output, EventEmitter} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { LocationsesServiceProxy, CreateOrEditLocationsDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
//import * as moment from 'moment';

@Component({
    selector: 'createOrEditLocationsModal',
    templateUrl: './create-or-edit-locations-modal.component.html'
})
export class CreateOrEditLocationsModalComponent extends AppComponentBase {
   
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;

    locations: CreateOrEditLocationsDto = new CreateOrEditLocationsDto();



    constructor(
        injector: Injector,
        private _locationsesServiceProxy: LocationsesServiceProxy
    ) {
        super(injector);
    }
    onShown(): void {

        document.getElementById('Locations_Name').focus();
    }
    show(locationsId?: number): void {
    

        if (!locationsId) {
            this.locations = new CreateOrEditLocationsDto();
            this.locations.id = locationsId;

            this.active = true;
            this.modal.show();
        } else {
            this._locationsesServiceProxy.getLocationsForEdit(locationsId).subscribe(result => {
                this.locations = result.locations;


                this.active = true;
                this.modal.show();
            });
        }
        
    }

    save(): void {
            this.saving = true;

			
			
            this._locationsesServiceProxy.createOrEdit(this.locations)
             .pipe(finalize(() => { this.saving = false;}))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
             });
    }







    close(): void {
        this.active = false;
        this.modal.hide();
    }
}
