import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { LeaveCreditedAtMonthDay, CreditRuleOnEmployeeStatus, LeaveEligibilityRule, PaidDayDeductionRule, CreateOrEditLeaveRuleDto, DropdownServicesServiceProxy, LeaveRulesServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-leaverulewizards',
  templateUrl: './leaverulewizards.component.html',
  styleUrls: ['./leaverulewizards.component.css']
})

export class LeaverulewizardsComponent extends AppComponentBase  {
   
  @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;

  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  active = false;
  saving = false;
  leaveCreditMonthArray: Array<LeaveCreditedAtMonthDay> = [];
  newLeaveCreditMonth: any = {};

  employeeStatusArray: Array<CreditRuleOnEmployeeStatus> = [];
  newEmployeeStatus: any = {};

  eligibilityRuleArray: Array<LeaveEligibilityRule> = [];
  newEligibilityRule: any = {};

  paidDeductionRuleArray: Array<PaidDayDeductionRule> = [];
  newPaidDeductionRule: any = {};

  leaveRule: CreateOrEditLeaveRuleDto = new CreateOrEditLeaveRuleDto();
  enableEligibilityMultiple:boolean=true;
  enableEligibilityDropdown:boolean=false;
  enableEligibilityBool:boolean=false;
  enableEligibilityText:boolean=false;
  enableEligibilityDate:boolean=false;
  enableEligibilityReligion:boolean=false;
  enableEligibilityCondition:boolean=false;
  enableNoOfCreditLeave:boolean=false;
  disableNoOfCreditLeave:boolean;
  enableLeaveWillBeCreditedAt:boolean=false;
  enableBasedOn:boolean=false;
  enableBasedOnCondition:boolean=false;
  enableLeaveWillBe:boolean=false;
  enableLeaveWillBeAvail:boolean=false;
  enableLeaveOpeningBalalnce:boolean=false;
  enableLeaveCreditAtTimeOfJoining:boolean=false;
  enableCreditRuleBasedonEmployee:boolean=false;
  enableLeaveCreditMonth:boolean=false;
  enableEmployeeStatus:boolean=false;
  enableEligibilityGender:boolean=false;
  enableLeaveWillBeCreditedAtQuaterly:boolean=false;
  enableLeaveWillBeCreditedAtHalfYearly:boolean=false;
  enableEligibilityBoolBenefit:boolean=false;
  leaveTypes:any=[];
  enableLeaveHoliday:boolean=false;
  enableLeaveWeeklyoff:boolean=false;
  hideLeaveWithoutPayDrop:boolean=true;
  // enableLeaveWithoutPayDrop:boolean=false;
  enableLeaveWithoutPayText:boolean=false;
  enablecarryForward:boolean=false;
  enableleaveInCashMent:boolean=false;
  enableunpaidLeave:boolean=false;
  enableleaveCloudingAllow:boolean=true;
  splitLeaveType=[];
  selecteLeaveTypeData:any=[];
  alreadyExists= [];
 paramleaveTypeId:number;
 enableNoOfDays:boolean;
 basedOnlabel:string;
 
  constructor(
      injector: Injector,
      private router:Router,
      private route:ActivatedRoute,
      private _dropdownServiceProxy:DropdownServicesServiceProxy,
      private _leaveRulesServiceProxy: LeaveRulesServiceProxy
  ) {
      super(injector);
  }
    public EligibilityValue: any = [
      { label: 'Apprentice', value: "1" },
      { label: 'Confirmed', value: "2"},
      { label: 'Probation', value: "3"},
      { label: 'Resigned', value: "4"},
    ];
    public EligibilityReligion: any = [
      { label: 'Hindu', value: "1" },
      { label: 'Muslim', value: "2"},
      { label: 'Sikh', value: "3"},
      { label: 'Christian', value: "4"},
    ];
   
    backDateNoofDays()
    {
      if(this.leaveRule.backDatedLeaveAllowed.toString()=="false")
      {
        this.enableNoOfDays=true;
        this.leaveRule.backDatedLeaveAllowedDays=null;
      }
      else
      {
        this.enableNoOfDays=false;
      }
    }
    includeWeeklyOff()
    {
      if(this.leaveRule.includeWeeklyOffasLeave.toString()=="false")
      {
        this.enableLeaveWeeklyoff=true;
      }
      else
      {
        this.enableLeaveWeeklyoff=false;
      }
    }
    includeHoliday()
    {
      if(this.leaveRule.includeHolidayasLeave.toString()=="false")
      {
        this.enableLeaveHoliday=true;
      }
      else
      {
        this.enableLeaveHoliday=false;
      }
    }
    carryForwardToNext()
    {
      if(this.leaveRule.carryForwardToNextYear.toString()=="false")
      {
        this.enablecarryForward=true;
        this.leaveRule.carryForwardLimit=0;
      }
      else
      {
        this.enablecarryForward=false;
        this.leaveRule.carryForwardLimit=0;
      }
    }
    leaveInCashMent()
    {
      if(this.leaveRule.leaveEncashable.toString()=="false")
      {
        this.enableleaveInCashMent=true;
        this.leaveRule.minimumBalanceLimitAfterEncashment=0;
        this.leaveRule.miximumEncashmentLimit=0;
      }
      else
      {
        this.enableleaveInCashMent=false;
      }
    }
    unpaidLeave()
    {
      if(this.leaveRule.unpaidLeave.toString()=="false")
      {
        this.enableunpaidLeave=false;
        // this.leaveRule.leaveEncashable=true;
        this.leaveRule.leaveEncashable=false;
        this.leaveInCashMent();
      }
      else
      {
        this.enableunpaidLeave=true;
       this.leaveRule.leaveEncashable=false;
       this.leaveRule.doYouWantToCreditLeave=-1;
       this. noOfCreditLeaveEnable();
       this.leaveInCashMent();
      }
    }
    leaveCloudingAllow()
    {
      if(this.leaveRule.leaveClubbingNotAllowedWith.toString()=="false")
      {
        this.enableleaveCloudingAllow=false;
        this.selecteLeaveTypeData=[];
      }
      else
      {
        this.enableleaveCloudingAllow=true;
      }
    }
    negativeBalalnceAllow()
    {
      if(this.leaveRule.negativeBalanceAllowed.toString()=="false")
      {
        //this.enableLeaveWithoutPayDrop=true;
        this.enableLeaveWithoutPayText=false;
        this.hideLeaveWithoutPayDrop=true;
        this.leaveRule.canApplyasLeaveWithoutPay="false";
      }
      else
      {
        this.leaveRule.canApplyasLeaveWithoutPay="0.00";
        this.enableLeaveWithoutPayText=true;
        this.hideLeaveWithoutPayDrop=false;
      }
    }
    noOfCreditLeaveEnable()
    {
        if(this.leaveRule.doYouWantToCreditLeave==1)
        {
          this.enableNoOfCreditLeave=true;
          this.disableNoOfCreditLeave=true;
          this.enableLeaveWillBeCreditedAt=true;
          this.enableLeaveOpeningBalalnce=false;
          this.enableLeaveCreditAtTimeOfJoining=false;
          this.enableBasedOn=false;
          this.enableBasedOnCondition=false;
          this.enableLeaveWillBe=false;
          this.enableLeaveWillBeAvail=false;
          this.enableCreditRuleBasedonEmployee=false;
          this.enableEmployeeStatus=false;
          this.enableLeaveCreditMonth=false;
          this.enableLeaveWillBeCreditedAtQuaterly=false;
          this.enableLeaveWillBeCreditedAtHalfYearly=false;
          // this.leaveRule.creditRuleBasedonEmployee=false;
          // this.leaveRule.leaveWillBeCreditedAt=-1;
        }
        else if(this.leaveRule.doYouWantToCreditLeave==2)
        {
          this.enableLeaveOpeningBalalnce=true;
          this.enableLeaveCreditAtTimeOfJoining=true;
          this.enableNoOfCreditLeave=false;
          this.disableNoOfCreditLeave=false;
          this.enableLeaveWillBeCreditedAt=false;
          this.enableBasedOn=false;
          this.enableBasedOnCondition=false;
          this.enableLeaveWillBe=false;
          this.enableLeaveWillBeAvail=false;
          this.enableCreditRuleBasedonEmployee=false;
          this.enableEmployeeStatus=false;
          this.enableLeaveCreditMonth=false;
          this.enableLeaveWillBeCreditedAtQuaterly=false;
          this.enableLeaveWillBeCreditedAtHalfYearly=false;
          this.leaveRule.creditRuleBasedonEmployee=false;
          this.leaveRule.leaveWillBeCreditedAt=undefined;
          this.leaveRule.basedOnCondition=undefined;
          this.leaveRule.basedOnDay=null;
          this.leaveRule.leaveWillBe=undefined;
          this.leaveRule.basedOn=undefined;
        }
        else if(this.leaveRule.doYouWantToCreditLeave==3)
        {
          this.enableNoOfCreditLeave=true;
          this.disableNoOfCreditLeave=false;
          this.enableLeaveWillBeCreditedAt=false;
          this.enableLeaveOpeningBalalnce=false;
          this.enableLeaveCreditAtTimeOfJoining=false;
          this.enableBasedOn=false;
          this.enableBasedOnCondition=false;
          this.enableLeaveWillBe=false;
          this.enableLeaveWillBeAvail=false;
          this.enableCreditRuleBasedonEmployee=true;
          if(this.leaveRule.creditRuleBasedonEmployee.toString()=="true")
          {
           this.enableEmployeeStatus=true;
          }
          else{
            this.enableEmployeeStatus=false;
          }
          this.enableLeaveCreditMonth=false;
          this.enableLeaveWillBeCreditedAtQuaterly=true;
          this.enableLeaveWillBeCreditedAtHalfYearly=false;
          //this.leaveRule.creditRuleBasedonEmployee=false;
          this.leaveRule.leaveWillBeCreditedAt=1;
          this.leaveRule.basedOnCondition=undefined;
          this.leaveRule.basedOnDay=null;
          this.leaveRule.leaveWillBe=undefined;
          this.leaveRule.basedOn=undefined;
        }
        else if(this.leaveRule.doYouWantToCreditLeave==4)
        {
          this.enableNoOfCreditLeave=true;
          this.disableNoOfCreditLeave=false;
          this.enableLeaveWillBeCreditedAt=false;
          this.enableLeaveOpeningBalalnce=false;
          this.enableLeaveCreditAtTimeOfJoining=false;
          this.enableBasedOn=false;
          this.enableBasedOnCondition=false;
          this.enableLeaveWillBe=false;
          this.enableLeaveWillBeAvail=false;
          this.enableCreditRuleBasedonEmployee=true;
          if(this.leaveRule.creditRuleBasedonEmployee.toString()=="true")
          {
           this.enableEmployeeStatus=true;
          }
          else{
            this.enableEmployeeStatus=false;
          }
          this.enableLeaveCreditMonth=false;
          this.enableLeaveWillBeCreditedAtQuaterly=false;
          this.enableLeaveWillBeCreditedAtHalfYearly=true;
          //this.leaveRule.creditRuleBasedonEmployee=false;
          this.leaveRule.leaveWillBeCreditedAt=1;
          this.leaveRule.basedOnCondition=undefined;
          this.leaveRule.basedOnDay=null;
          this.leaveRule.leaveWillBe=undefined;
          this.leaveRule.basedOn=undefined;
        }
        else  if(this.leaveRule.doYouWantToCreditLeave==-1)
        {
          this.enableLeaveOpeningBalalnce=false;
          this.enableLeaveCreditAtTimeOfJoining=false;
          this.enableNoOfCreditLeave=false;
          this.disableNoOfCreditLeave=false;
          this.enableLeaveWillBeCreditedAt=false;
          this.enableBasedOn=false;
          this.enableBasedOnCondition=false;
          this.enableLeaveWillBe=false;
          this.enableLeaveWillBeAvail=false;
          this.enableCreditRuleBasedonEmployee=false;
          this.enableEmployeeStatus=false;
          this.enableLeaveCreditMonth=false;
          this.enableLeaveWillBeCreditedAtQuaterly=false;
          this.enableLeaveWillBeCreditedAtHalfYearly=false;
          this.leaveRule.creditRuleBasedonEmployee=false;
          this.leaveRule.leaveWillBeCreditedAt=undefined;
          this.leaveRule.basedOnCondition=undefined;
          this.leaveRule.basedOnDay=null;
          this.leaveRule.leaveWillBe=undefined;
          this.leaveRule.basedOn=undefined;
        }
    }
    employeeStatusEnable()
    {
      if(this.leaveRule.creditRuleBasedonEmployee.toString()=="true")
      {
        this.enableEmployeeStatus=true;
        this.enableNoOfCreditLeave=false;
      }
      else
      {
        this.enableEmployeeStatus=false;
        this.enableNoOfCreditLeave=true;
      }
    }
    leaveWilBeCreditedEnable()
    {
        if(this.leaveRule.leaveWillBeCreditedAt==1)
        {
          this.enableBasedOn=true;
          this.enableNoOfCreditLeave=true;
          this.disableNoOfCreditLeave=false;
          this.enableLeaveCreditMonth=false;
          this.enableBasedOnCondition=false;
          this.enableLeaveWillBe=false;
          this.enableLeaveWillBeAvail=false;
          // this.leaveRule.basedOn=-1;
        }
        else if(this.leaveRule.leaveWillBeCreditedAt==4)
        {
          this.enableBasedOn=false;
          this.enableNoOfCreditLeave=false;
          this.disableNoOfCreditLeave=false;
          this.enableLeaveCreditMonth=true;
          this.enableBasedOnCondition=false;
          this.enableLeaveWillBe=false;
          this.enableLeaveWillBeAvail=false;
          this.leaveRule.basedOn=undefined;
          this.leaveRule.basedOnCondition=undefined;
          this.leaveRule.basedOnDay=null;
          this.leaveRule.leaveWillBe=undefined;
        }
        else if(this.leaveRule.leaveWillBeCreditedAt==undefined){
          this.enableBasedOn=false;
          this.enableNoOfCreditLeave=false;
          this.disableNoOfCreditLeave=false;
          this.enableLeaveCreditMonth=false;
          this.enableBasedOnCondition=false;
          this.enableLeaveWillBe=false;
          this.enableLeaveWillBeAvail=false;
          this.leaveRule.basedOn=undefined;
          this.leaveRule.basedOnCondition=undefined;
          this.leaveRule.basedOnDay=null;
          this.leaveRule.leaveWillBe=undefined;
        }
        else{
          this.enableBasedOn=false;
          this.enableNoOfCreditLeave=true;
          this.disableNoOfCreditLeave=false;
          this.enableLeaveCreditMonth=false;
          this.enableBasedOnCondition=false;
          this.enableLeaveWillBe=false;
          this.enableLeaveWillBeAvail=false;
          this.leaveRule.basedOn=undefined;
          this.leaveRule.basedOnCondition=undefined;
          this.leaveRule.basedOnDay=null;
          this.leaveRule.leaveWillBe=undefined;
        }
    }
    basedOnEnable()
    {
        if(this.leaveRule.basedOn==1 ||this.leaveRule.basedOn==2)
        {
          this.enableBasedOnCondition=true;
          this.enableLeaveWillBe=true;
          this.enableLeaveWillBeAvail=false;
          // this.leaveRule.basedOnCondition=-1;
          // this.leaveRule.basedOnDay=null;
          // this.leaveRule.leaveWillBe=-1;
        }
        else if(this.leaveRule.basedOn==3)
        {
          this.enableBasedOnCondition=true;
          this.enableLeaveWillBe=false;
          this.enableLeaveWillBeAvail=false;
          this.leaveRule.basedOnCondition=undefined;
          this.leaveRule.basedOnDay=null;
          this.leaveRule.leaveWillBe=undefined;
        }
        else if(this.leaveRule.basedOn==4)
        {
          this.enableBasedOnCondition=false;
          this.enableLeaveWillBe=false;
          this.enableLeaveWillBeAvail=false;
          this.leaveRule.basedOnCondition=undefined;
          this.leaveRule.basedOnDay=null;
          this.leaveRule.leaveWillBe=undefined;
        }
        this.onChangeofBasedOn();
    }

    leaveWillBeEnable()
    {
        if(this.leaveRule.leaveWillBe==2 ||this.leaveRule.leaveWillBe==3)
        {
          this.enableLeaveWillBeAvail=true;
          this.leaveRule.leaveWillBeAvail=1;
        }
        else 
        {
          this.enableLeaveWillBeAvail=false;
          this.leaveRule.leaveWillBeAvail=1;
        }
       
    }
   leaveEligibityRuleChange(index)
   {
      if(this.eligibilityRuleArray.length==1)
      {

      }
      else
      {
        //console.logthis.alreadyExists);
        for(let k=0;k<this.alreadyExists.length;k++)
        {
            if(this.alreadyExists[k]==this.eligibilityRuleArray[index].eligibilityRule)
            {
              this.eligibilityRuleArray[index].eligibilityRule=undefined
              this.notify.error("Already exists", 'Warning');  
              return false; 
            }
        }
      }
     if(this.alreadyExists[index]!=null)
     {
      this.alreadyExists[index]=this.eligibilityRuleArray[index].eligibilityRule;
     }
     else
     {
      this.alreadyExists.push(this.eligibilityRuleArray[index].eligibilityRule);
     }
   
   }
  restrictNegative(e: any): void {
      var inputKeyCode = e.keyCode ? e.keyCode : e.which;
      if (inputKeyCode != null) {
        if (inputKeyCode!=9) {
          if ((e.which != 46) && (e.which < 48 || e.which > 57))  e.preventDefault();
        }
        
      }
    }
  ngOnInit():void{
      this.route.params.subscribe(param=>{
          if(param.id!=undefined && param.id!='' && param.id!=0)
          {
              this.show(param.id,param.type);
          }
          if(param.id==0)
          { 
              this.show(null,param.type);
          }
      });
  
  }
  onChangeofBasedOn()
  {
    if(this.leaveRule.basedOn==1)
    {
      this.basedOnlabel="If Paid Days";
    }
    else if(this.leaveRule.basedOn==2)
    {
      this.basedOnlabel="If Present Days";
    }
    else if(this.leaveRule.basedOn==3)
    {
      this.basedOnlabel="If LWP";
    }
    else if(this.leaveRule.basedOn==4)
    {
      this.basedOnlabel="If Per Day";
    }
  }
  leaveType()
  {
      this._dropdownServiceProxy.getOnlyLeaveTypes().subscribe(Customer=>{
          this.leaveTypes=Customer
      });
  }
  
  show(leaveRuleId?: number,type?:number): void {
  
      if (!leaveRuleId) {
          this.leaveType();
          this.leaveRule = new CreateOrEditLeaveRuleDto();
          this.leaveRule.id = leaveRuleId;
          this.leaveRule.leaveTypeId=type;
          this.paramleaveTypeId=type;
          this.leaveRule = new CreateOrEditLeaveRuleDto();
         

          this.newLeaveCreditMonth = new LeaveCreditedAtMonthDay(); 
          //this.newLeaveCreditMonth={day: 0, noOfCreditLeave: 0};  
          this.leaveCreditMonthArray.push(this.newLeaveCreditMonth); 

          this.newEmployeeStatus = new CreditRuleOnEmployeeStatus(); 
          // this.newEmployeeStatus={employeeStatus: "0", noOfLeave: 0,frequency:"0"}; 
          this.employeeStatusArray.push(this.newEmployeeStatus); 

          this.newEligibilityRule = new LeaveEligibilityRule();  
          this.eligibilityRuleArray.push(this.newEligibilityRule);
          
          this.newPaidDeductionRule = new PaidDayDeductionRule(); 
          //this.newPaidDeductionRule={leaverangeFrom: 0, leaverangeTo: 0,salaryDeduction:0};  
          this.paidDeductionRuleArray.push(this.newPaidDeductionRule);
          this.modal.show();
          this.active = true;
          //this.modal.show();
      } else {
          this._leaveRulesServiceProxy.getLeaveRuleForEdit(leaveRuleId).subscribe(result => {
              this.leaveRule = result.leaveRule;
              

              this.alreadyExists=[];
                   this.leaveRule.id = leaveRuleId;
                   this.paramleaveTypeId=type;
              
              this.leaveType();
              for(var item in result.leaveRule.leaveCreditedAtMonthDayList)
              {
                  let leaveCreditedAtMonthDayDetails=new LeaveCreditedAtMonthDay();
                  leaveCreditedAtMonthDayDetails.day=result.leaveRule.leaveCreditedAtMonthDayList[item].day;
                  leaveCreditedAtMonthDayDetails.noOfCreditLeave=result.leaveRule.leaveCreditedAtMonthDayList[item].noOfCreditLeave;
                  this.leaveCreditMonthArray.push(leaveCreditedAtMonthDayDetails);
              }

              for(var item in result.leaveRule.creditRuleEmployeeStatusList)
              {
                  let creditRuleEmployeeStatusDetails=new CreditRuleOnEmployeeStatus();
                  creditRuleEmployeeStatusDetails.employeeStatus=result.leaveRule.creditRuleEmployeeStatusList[item].employeeStatus;
                  creditRuleEmployeeStatusDetails.noOfLeave=result.leaveRule.creditRuleEmployeeStatusList[item].noOfLeave;
                  creditRuleEmployeeStatusDetails.frequency=result.leaveRule.creditRuleEmployeeStatusList[item].frequency;
                  this.employeeStatusArray.push(creditRuleEmployeeStatusDetails);
              }

              for(var item in result.leaveRule.leaveEligibilityRuleList)
              {
                  let leaveEligibilityRuleDetails=new LeaveEligibilityRule();
                  leaveEligibilityRuleDetails.eligibilityRule=result.leaveRule.leaveEligibilityRuleList[item].eligibilityRule;
                  leaveEligibilityRuleDetails.eligibility=result.leaveRule.leaveEligibilityRuleList[item].eligibility;
                  leaveEligibilityRuleDetails.eligibilityMulti=result.leaveRule.leaveEligibilityRuleList[item].eligibilityMulti;
                  
                  leaveEligibilityRuleDetails.eligibilityCondition=result.leaveRule.leaveEligibilityRuleList[item].eligibilityCondition;
                  leaveEligibilityRuleDetails.conditionDay=result.leaveRule.leaveEligibilityRuleList[item].conditionDay;
                  leaveEligibilityRuleDetails.includeAbsentDays=result.leaveRule.leaveEligibilityRuleList[item].includeAbsentDays;
                  this.eligibilityRuleArray.push(leaveEligibilityRuleDetails);
                  this.alreadyExists.push(leaveEligibilityRuleDetails.eligibilityRule);
              }

              for(var item in result.leaveRule.paidDayDeductionRuleList)
              {
                  let paidDayDeductionRuleDetails=new PaidDayDeductionRule();
                  paidDayDeductionRuleDetails.leaverangeFrom=result.leaveRule.paidDayDeductionRuleList[item].leaverangeFrom;
                  paidDayDeductionRuleDetails.leaverangeTo=result.leaveRule.paidDayDeductionRuleList[item].leaverangeTo;
                  paidDayDeductionRuleDetails.salaryDeduction=result.leaveRule.paidDayDeductionRuleList[item].salaryDeduction;
                  this.paidDeductionRuleArray.push(paidDayDeductionRuleDetails);
              }
              this.splitLeaveType =result.leaveRule.leaveClubbingNotAllowedWithLeaveType.split(',');
             
              this.selecteLeaveTypeData=this.splitLeaveType;
              this.noOfCreditLeaveEnable();
              this.employeeStatusEnable();
              this.leaveWilBeCreditedEnable();
              this.basedOnEnable();
              this.leaveWillBeEnable();
              this.includeWeeklyOff();
              this.includeHoliday();
              this.carryForwardToNext();
              this.leaveInCashMent();
              this.unpaidLeave();
              this.leaveCloudingAllow();
              this.negativeBalalnceAllow();
              this. backDateNoofDays();
              this.active = true;
              this.modal.show();
          });
      }
      
  }
  addRowLeaveCreditMonth() {    
      this.newLeaveCreditMonth = new LeaveCreditedAtMonthDay(); 
      //this.newLeaveCreditMonth={day: 0, noOfCreditLeave: 0}; 
        this.leaveCreditMonthArray.push(this.newLeaveCreditMonth);  
        return true;  
    }  

    deleteRowLeaveCreditMonth(index) { 
      if(this.leaveCreditMonthArray.length ==1) {  
          this.notify.error("Can't delete last row");  
            return false;  
        } else { 
            this.leaveCreditMonthArray.splice(index, 1);  
            this.notify.info('Row deleted successfully');  
            return true;  
        }
    }
    addRowEmployeeStatus() {    
      this.newEmployeeStatus = new CreditRuleOnEmployeeStatus();  
    // this.newEmployeeStatus={employeeStatus: "0", noOfLeave: 0,frequency:"0"}; 
        this.employeeStatusArray.push(this.newEmployeeStatus);  
        return true;  
    }  

    deleteRowEmployeeStatus(index) { 
      if(this.employeeStatusArray.length ==1) {  
          this.notify.error("Can't delete last row");  
            return false;  
        } else { 
            this.employeeStatusArray.splice(index, 1);  
            this.notify.info('Row deleted successfully');  
            return true;  
        }
    }

    addRowEligibilityRule() {    
      this.newEligibilityRule = new LeaveEligibilityRule();  
      this.eligibilityRuleArray.push(this.newEligibilityRule);  
      return true;  
    }  

    deleteRowEligibilityRule(index) { 
      if(this.eligibilityRuleArray.length ==1) {  
          this.notify.error("Can't delete last row");  
            return false;  
        } else { 
            this.eligibilityRuleArray.splice(index, 1); 
            this.alreadyExists.splice(index, 1);
            this.notify.info('Row deleted successfully');  
            return true;  
        }
    }

    addRowPaidDeductionRule() {    
      this.newPaidDeductionRule = new PaidDayDeductionRule(); 
      //this.newPaidDeductionRule={leaverangeFrom: 0, leaverangeTo: 0,salaryDeduction:0};  
      this.paidDeductionRuleArray.push(this.newPaidDeductionRule); 
      return true;  
    }  

    deleteRowPaidDeductionRule(index) { 
      if(this.paidDeductionRuleArray.length ==1) {  
          this.notify.error("Can't delete last row");  
            return false;  
        } else { 
            this.paidDeductionRuleArray.splice(index, 1);  
            this.notify.info('Row deleted successfully');  
            return true;  
        }
    }
    validation()
    {
      
    }
  save(): void {
          this.saving = true;
         //console.logthis.leaveRule.leaveTypeId);
         this.leaveRule.leaveTypeId=this.paramleaveTypeId;
          var LeaveType="";
          var leaveEligiRule="";
          var leaveEligireligRule="";
          if(this.leaveRule.leaveCycle.toString()=="undefined")
          {
              this.notify.warn("Please select leave cycle");
              this.saving=false;
              return;
          }
          if(this.leaveRule.halfDayLeaveAllowed.toString()=="undefined")
          {
              this.notify.warn("Please select half day leave allowed");
              this.saving=false;
              return;
          }
          if(this.leaveRule.includeWeeklyOffasLeave.toString()=="undefined")
          {
              this.notify.warn("Please select include weekly off as leave");
              this.saving=false;
              return;
          }
          if(this.leaveRule.includeHolidayasLeave.toString()=="undefined")
          {
              this.notify.warn("Please select include holiday as leave");
              this.saving=false;
              return;
          }
          if(this.leaveRule.negativeBalanceAllowed.toString()=="undefined")
          {
              this.notify.warn("Please select negative balance allowed");
              this.saving=false;
              return;
          }
          if(this.leaveRule.canApplyasLeaveWithoutPay.toString()=="undefined")
          {
              this.notify.warn("Please select can apply as leave without pay");
              this.saving=false;
              return;
          }
          if(this.leaveRule.carryForwardToNextYear.toString()=="undefined")
          {
              this.notify.warn("Please select carry forward to next year");
              this.saving=false;
              return;
          }
          if(this.leaveRule.leaveEncashable.toString()=="undefined")
          {
              this.notify.warn("Please select leave encashable");
              this.saving=false;
              return;
          }
          if(this.leaveRule.applicableForLTA.toString()=="undefined")
          {
              this.notify.warn("Please select application for LTA");
              this.saving=false;
              return;
          }
          if(this.leaveRule.unpaidLeave.toString()=="undefined")
          {
              this.notify.warn("Please select unpaid leave");
              this.saving=false;
              return;
          }
          if(this.leaveRule.backDatedLeaveAllowed.toString()=="undefined")
          {
              this.notify.warn("Please select back date leave allowed");
              this.saving=false;
              return;
          }
          if(this.leaveRule.allowPartialLeaveCancellation.toString()=="undefined")
          {
              this.notify.warn("Please select allow partial leave cancellation");
              this.saving=false;
              return;
          }
          if(this.leaveRule.leaveClubbingNotAllowedWith.toString()=="undefined")
          {
              this.notify.warn("Please select leave clubbing not allowed with");
              this.saving=false;
              return;
          }
          if(this.leaveRule.unpaidLeave.toString()=="false")
          {
            if(this.leaveRule.doYouWantToCreditLeave==undefined)
            {
                this.notify.warn("Please select do you want to credit leave");
                this.saving=false;
                return;
            }
            if(this.leaveRule.doYouWantToCreditLeave==-1)
            {
                this.notify.warn("Please select do you want to credit leave");
                this.saving=false;
                return;
            }
          }
          if(this.leaveRule.doYouWantToCreditLeave!=undefined)
            {
              if(this.leaveRule.doYouWantToCreditLeave!=-1)
              {
              if(this.leaveRule.doYouWantToCreditLeave==2)
              {
                if(this.leaveRule.leaveCreditAtTimeOfJoining.toString()=="undefined")
                {
                    this.notify.warn("Please select leave credit at time of joining");
                    this.saving=false;
                    return;
                }
              }
              if(this.leaveRule.doYouWantToCreditLeave!=2)
              {
                if(this.leaveRule.leaveWillBeCreditedAt.toString()=="undefined")
                {
                    this.notify.warn("Please select leave will be credit at");
                    this.saving=false;
                    return;
                }
              }
              if(this.leaveRule.leaveWillBeCreditedAt==1 && this.leaveRule.doYouWantToCreditLeave==1)
              {
                if(this.leaveRule.basedOn.toString()=="undefined")
                {
                    this.notify.warn("Please select based on");
                    this.saving=false;
                    return;
                }
              }
              if(this.leaveRule.doYouWantToCreditLeave==3||this.leaveRule.doYouWantToCreditLeave==4)
              {
                if(this.leaveRule.creditRuleBasedonEmployee.toString()=="undefined")
                {
                    this.notify.warn("Please select do you want to apply credit rule based on employee status");
                    this.saving=false;
                    return;
                }
              }
            }
          }
          for(var i=0;i<this.eligibilityRuleArray.length;i++)
          {
            if(this.eligibilityRuleArray[i].eligibilityRule==undefined)
            {
              this.notify.warn("Please select eligibility rule");
              this.saving=false;
              return;
            }
           
            if(this.eligibilityRuleArray[i].eligibilityRule==1 ||this.eligibilityRuleArray[i].eligibilityRule==8)
            {
              if(this.eligibilityRuleArray[i].eligibilityMulti==undefined)
              {
                this.notify.warn("Please select eligibility rule");
                this.saving=false;
                return;
              }
            }
            else
            {
              if(this.eligibilityRuleArray[i].eligibility==undefined)
              {
                this.notify.warn("Please select eligibility rule");
                this.saving=false;
                return;
              }
            }
          }
          // if(this.leaveRule.creditRuleBasedonEmployee!=undefined)
          // {
          //   if(this.leaveRule.creditRuleBasedonEmployee.toString()=="true")
          //   {
          //       for(var i=0;i<this.employeeStatusArray.length;i++)
          //       {
          //         if(this.employeeStatusArray[i].employeeStatus.toString()=="0")
          //         {
          //           this.notify.warn("Please select employee status");
          //           this.saving=false;
          //           return;
          //         }
          //         if(this.employeeStatusArray[i].frequency.toString()=="0")
          //         {
          //           this.notify.warn("Please select frequency");
          //           this.saving=false;
          //           return;
          //         }
          //       }
          //   }
          //}
    for(var i=0;i<this.selecteLeaveTypeData.length;i++)
    {
      LeaveType=LeaveType==""?this.selecteLeaveTypeData[i]:LeaveType+","+this.selecteLeaveTypeData[i];
    }
    this.leaveRule.leaveClubbingNotAllowedWithLeaveType=LeaveType;
    this.leaveRule.leaveCreditedAtMonthDayList=this.leaveCreditMonthArray;
    this.leaveRule.creditRuleEmployeeStatusList=this.employeeStatusArray;
    this.leaveRule.leaveEligibilityRuleList=this.eligibilityRuleArray;
    this.leaveRule.paidDayDeductionRuleList=this.paidDeductionRuleArray;
    //console.logthis.leaveRule);
          this._leaveRulesServiceProxy.createOrEdit(this.leaveRule)
           .pipe(finalize(() => { this.saving = false;}))
           .subscribe(() => {
              this.notify.info(this.l('SavedSuccessfully'));
              this.close();
              this.modalSave.emit(null);
           });
  }







  close(): void {
 this.modal.hide();
  }
}