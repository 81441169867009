import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { BranchDropDown,  CreateOrEditHolidayMapPolicyDto, CreateOrEditHolidayMapPolicyDtoNew, DropDown, DropDownEmployee, DropdownServicesServiceProxy, EmployeesServiceProxy, HoldiayPoliciesServiceProxy, HolidaypolicyDetailsesServiceProxy, UserServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';
import { Paginator } from 'primeng/primeng';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-attendance-policy-map',
  templateUrl: './attendance-policy-map.component.html',
  styleUrls: ['./attendance-policy-map.component.css']
})
export class AttendancePolicyMApComponent  extends AppComponentBase implements OnInit {
  @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
  constructor(
    injector: Injector,
    private _holidaypolicyDetailsesServiceProxy: HolidaypolicyDetailsesServiceProxy,
    private _holdiayPoliciesServiceProxy: HoldiayPoliciesServiceProxy,
    private _employeesServiceProxy: EmployeesServiceProxy,
    private _dropdownServiceProxy:DropdownServicesServiceProxy,
    private _userServiceProxy: UserServiceProxy,
    private router:Router,
    private route:ActivatedRoute,
  ) {
    super(injector);
  }
  policies:DropDown[];
  location:BranchDropDown[];
  enableDrop: boolean=false;
  yes:any=0;
  checked:boolean=false;
  enableEmployee:boolean=false;
  Holiday:boolean;
  PolicyName:string;
  active = false;
  PolicyNameDisable:boolean;
  filterText = '';
  policiesname:any;
  value:any;
  selectedPermission = '';
  role = '';
  onlyLockedUsers = false;
  Applicable:number=2;
  Employee:number;
  pay:any;
  selectedEmp: string[] = [];
  selectedbranches:any[]=[];
  public optionsApplicableTo: any = [
    { label: 'Employee', value: 1 },
    { label: 'Branch', value: 2 },
  ];
  public DropdownDo:any=[
    {label: 'Select', value: undefined},
        { label: 'Yes', value: 1 },
        { label: 'No', value: 0},
  ];
  public BranchOptions: any = [
    { label: 'Branch', code: 1 },
    { label: 'SubBranch', code: 2 },
  ];

  @ViewChild('dataTable', { static: true }) dataTable: Table;
  @ViewChild('paginator', { static: true }) paginator: Paginator;
 employeeData:DropDownEmployee[];
 empvalue : any;
 items: DropDown[];
 item: string;
 policyid:number;
 saving = false;
 HolidayMapPolicyDto :CreateOrEditHolidayMapPolicyDtoNew[];
 getPolicyData: CreateOrEditHolidayMapPolicyDto[];

  ngOnInit() {
    this.filterpolicies()
    this.filterlocation()
    this.getUser()
    this.Applicable=2;
    this.isGranted
  
    for(var j=0;j<this.location.length;j++)
          {

              this.location[j].selected=false;
          }
          this.pay = null;
          this.enableDrop=false;
  
}


  show(id: number,Ploicy?: string): void {

    
   this.PolicyName=Ploicy;
   this.active = true;
   this.policyid=id;
            this.PolicyNameDisable=true;

            this._holdiayPoliciesServiceProxy.getAllPolicyData(this.policyid).subscribe(result =>{
              this.getPolicyData=result;
              if(this.getPolicyData.length!=0)
              {
                for(var i=0;i<this.getPolicyData.length;i++)
                {
                  for(var j=0;j<this.location.length;j++)
                  {
                    if(this.getPolicyData[i].branchId==this.location[j].value)
                    {
                      this.location[j].selected=true;
                      this.selectedbranches.push({value:this.getPolicyData[i].branchId})
                    }
                  }
                }
                this.pay=1;
                this.enableDrop=true;
              }
              else{
                for(var j=0;j<this.location.length;j++)
                {

                    this.location[j].selected=false;
                }
                this.pay = null;
                this.enableDrop=false;
              }
            });
            this.modal.show();
  }

  filterpolicies()
  {
      this._dropdownServiceProxy.getPolicies().subscribe(Customer=>{
        this.policies=Customer;
  })
}

filterlocation()
{
    this._holdiayPoliciesServiceProxy.getLocation().subscribe(Customer=>{
      this.location=Customer;
})
}
DropbranchValidation(){
  if(this.pay==1)
  {
    this.enableDrop=true;
  }
  else this.enableDrop=false;

}
// branchValidation(event){
//   
//   if(this.Applicable==1){
//     this.enableDrop=false;
//     this.yes=true;
//     this.enableEmployee=true;
//   }
//   else if(this.Applicable==2){
//     this.enableDrop=true;
//     this.yes=true;
//     this.enableEmployee=false;
//   }
// }
getUser() {
  
   this._dropdownServiceProxy.getEmployees().subscribe(result => {
     this.employeeData=result;
      // this.items = [];
      //   for (let i = 0; i < 10000; i++) {
      //       this.items.push({label: 'Item ' + i value: 'Item ' + i});
      //   }
  });
}
onCheckboxChange(event: any) {
  const selectedEmployeeId = parseInt(event.target.value, 10);
  const isChecked = event.target.checked;

  if (isChecked) {
    this.selectedbranches.push({value:selectedEmployeeId});
  } else {
    const index = this.selectedbranches.indexOf(selectedEmployeeId);
    this.selectedbranches.splice(index, 1);
  }
}
 Save(): void{
  

   //this.delete();
  this.saving = true;
  let mapdto = new Array<CreateOrEditHolidayMapPolicyDtoNew>();
  // mapdto.policyId=this.policyid;
  // mapdto.policyType="Attendance";
  //console.log"length",this.selectedbranches.length);
  //console.log"length",this.selectedbranches);
  this.saving=true;
  //var branchlist = new Array<BranchlistIDs>
  for(var i=0;i<this.selectedbranches.length;i++)
  {
    var mpdtop = new CreateOrEditHolidayMapPolicyDtoNew()
    mpdtop.branchID=this.selectedbranches[i].value;
    mpdtop.policyId=this.policyid;
    mpdtop.policyType="Attendance";
    mpdtop.departmentId=0;
    mpdtop.divisionId=0;
    mapdto.push(mpdtop);
  }
    this._holdiayPoliciesServiceProxy.insertMapPolicy(this.policyid,mapdto).pipe(finalize(() => { this.saving = false;})).subscribe(() => {
      this.notify.info(this.l('SavedSuccessfully'));
      this.close();
      this.saving=false;
      //this.modalSave.emit(null);
   });

}

close(): void {
  this.selectedbranches.length=0;
  this.active = false;
  this.modal.hide();
}

}
