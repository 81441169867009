import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DocumentTypeDto, GetDocumentTypeForViewDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'app-view-document-type-modal',
  templateUrl: './view-document-type-modal.component.html'
})
export class ViewDocumentTypeModalComponent extends AppComponentBase {
  @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  active = false;
  saving = false;

  item: GetDocumentTypeForViewDto;


  constructor(
      injector: Injector
  ) {
      super(injector);
      this.item = new GetDocumentTypeForViewDto();
      this.item.documentType = new DocumentTypeDto();
  }

  show(item: GetDocumentTypeForViewDto): void {
      this.item = item;
      this.active = true;
      this.modal.show();
  }

  close(): void {
      this.active = false;
      this.modal.hide();
  }

}
