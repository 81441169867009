<!-- BEGIN: Topbar -->
<!-- <style>
    @media screen and (max-width: 1385px) {
        .kt-header__topbar-item {
        width: 51%;
    }
        .kt-menu__link-text {
        width: 42%;
    }
    }

    @media screen and (max-width: 1550px){
        .kt-menu__link-text h5{
            margin-right: 730px;
        }
    }

    @media screen and (max-width: 1025px) {
        .kt-header {
            width: 100%;
            justify-content: flex-start;
        }
        .kt-header__topbar-item
        {
             margin-right: 48%;
             min-width: 44%;
        }
        .kt-menu__link-text
        {
            width: 100%;
        }
        .kt-menu__link-text h5 {
            width: 100%;
            margin-right: unset;
        }
    }
     @media screen and (max-width: 1025px) {
        .kt-header{
            justify-content: start;
        }
        .kt-header__topbar-item{
            margin-right: 76%;
        }
        .kt-menu__link-text h5{
            width: 271px;
        }
    }
</style> -->


<style>
    topbar.ng-tns-c1-0 {
        justify-content: space-between !important;
        width: 99.4% !important;
        align-items: center !important;

    }

    .ng-tns-c1-0 .kt-header__topbar-item span.kt-menu__link-text.ml-1.mt-2 h5 {
        font-size: 20px;
        color: #39305fe7;
        font-family: 'Poppins';
        font-weight: bold;
        margin-bottom: 0px;
    }

    .ng-tns-c1-0 .kt-header__topbar-item span.kt-menu__link-text.ml-1.mt-2 {
        margin-left: 0px !important;
        margin-top: 0px !important;
    }

    .kt-header__topbar button.btn.top-btn.ng-star-inserted {
        background: #f8c146;
        color: #fff;
        transition: all 0.5s;
    }

    .kt-header__topbar button.btn.top-btn.ng-star-inserted:hover {
        background: #dfa82d;
    }

    .kt-header__topbar button.btn.top-btn.ng-star-inserted i {
        color: #fff;
    }

    .kt-header__topbar button.btn.top-btn.btn2 {
        background: rgb(2, 202, 2);
        color: #fff;
        transition: all 0.5s ease-in-out;
    }

    .kt-header__topbar button.btn.top-btn.btn2:hover {
        background: rgb(2, 139, 2);
    }

    .kt-header__topbar button.btn.top-btn.btn3 {
        background: #ff3535;
        color: #fff;
        transition: all 0.5s ease-in-out;
    }

    .kt-header__topbar button.btn.top-btn.btn3:hover {
        background: #d91d1d;
    }

    @media(max-width: 900px) {
        topbar.ng-tns-c1-0 {
            flex-direction: column;
        }

        /* .kt-header.kt-grid__item.kt-header--static.kt-head--skin-light{
        margin-top: 60px!important;
    } */

        .kt-header__topbar {
            margin-top: unset !important;
            justify-content: center !important;
        }

        topbar.ng-tns-c1-0 {
            width: 100% !important;
            display: block !important;
        }

        .ng-tns-c1-0 .kt-header__topbar-item span.kt-menu__link-text.ml-1.mt-2 h5 {
            text-align: center !important;
            margin-bottom: 4px !important;
        }

        .btn .btn-dark {
            /* color: #fff; */
            background-color: #594d99;
            border-color: #594d99;
            /* background-color: #594d99; */
            color: #ffffff;
        }      
    }
</style>


<div class="kt-header__topbar-item">
    <span class="kt-menu__link-text ml-1 mt-2">
        <h5>{{tenant.name}}</h5>
    </span>
</div>


<div class="kt-header__topbar">
    <search-bar></search-bar>
    <!-- <div class="s-block">
                <div class="icon-block" style="background: #f8c146">
                    <img src="assets/common/images/Pending-Request.jpg" alt="" />
                </div>
                <div class="content-block">
                    <h4
                        style="color: #f8c146"
                        href="javascript:void(0);"
                        (click)="AdvanceEmployee()"
                    >
                        {{ advanceRequests }}
                    </h4>
                    <h5>Pending Request for user</h5>
                </div>
            </div> -->
<!-- 
            <button *ngIf="adminuser != true && permission.isGranted('Pages.EmployeeSelfReview.Create')" 
            (click)="showReview()" 
            style="background-color: #594d99; border-color: #594d99;" 
            class="btn btn-dark">
        <span>{{ buttonText }}</span>
    </button> -->
    <button *ngIf="adminuser!=true" (click)="AdvanceEmployee()" style="  background-color: #594d99;
            border-color:  #594d99;" class="btn btn-dark"><span *ngIf="permission.isGranted('Pages.Advance')">{{
            advanceRequests }}</span> {{l("Pending Actions")}}</button>

            <button *ngIf="adminuser!=true && noPunch" 
            (click)="changeSttandance()" 
            class="btn top-btn"
            style="width: 150px;">
        {{l("Mark Attendance")}}
    </button>
    
   <button *ngIf="!adminuser && oddPunch" 
        (click)="changeSttandance()" 
        class="btn top-btn btn2"
        style="width: 150px;"
        (mouseenter)="showClockOut = true"
        (mouseleave)="showClockOut = false">
    <span [style.font-weight]="showClockOut ? 'normal' : (working_hours ? '500' : 'normal')">
        {{ showClockOut ? 'Clock-Out' : 'Clocked-In'  }}
    </span>
</button>

<button *ngIf="!adminuser && evenPunch" 
    (click)="changeSttandance()" 
    class="btn top-btn btn3"
    style="width: 150px;"
    (mouseenter)="showClockOut = true"
    (mouseleave)="showClockOut = false">
<span [style.font-weight]="showClockOut ? 'normal' : (working_hours ? '500' : 'normal')">
    {{ showClockOut ? 'Clocked-In' : working_hours || 'Clock-Out'}}
</span>
</button>

   
    <!-- <app-pup-up-markattendance  #markattendance></app-pup-up-markattendance> -->


    <!-- <div class="kt-header__topbar-item dropdown d-lg-block subscription-warning"
        *ngIf="subscriptionStatusBarVisible()">
        <a href="javascript:;" *ngIf="appSession.tenant.isInTrialPeriod && !subscriptionIsExpiringSoon()"
            class="kt-nav__link m-dropdown__toggle">
            <span class="kt-nav__link-text glowing">
                <i class="fa fa-exclamation-triangle"></i>
                <span class="kt-menu__link-text ml-1" [innerHTML]="getTrialSubscriptionNotification()"></span>
            </span>
        </a>

          
        <a *ngIf="subscriptionIsExpiringSoon()" routerLink="/app/admin/subscription-management" class="kt-nav__link">
            <span *ngIf="subscriptionIsExpiringSoon() && appSession.tenant.isInTrialPeriod"
                class="kt-nav__link-text glowing">
                <i class="fa fa-exclamation-triangle"></i>
                <span class="kt-menu__link-text ml-1">{{getExpireNotification("TrialExpireNotification")}}</span>
            </span>
              <span *ngIf="subscriptionIsExpiringSoon() && !appSession.tenant.isInTrialPeriod"
                class="kt-nav__link-text glowing">
                <i class="fa fa-exclamation-triangle"></i>
                <span class="kt-menu__link-text ml-1">{{getExpireNotification("SubscriptionExpireNotification")}}</span>
            </span>
            <span *ngIf="tenancynotification"
                class="kt-nav__link-text glowing">
                <i class="fa fa-exclamation-triangle"></i>
                 <span class="kt-menu__link-text ml-1">{{getExpireNotification("SubscriptionExpireNotification")}}</span>
                <span class="kt-menu__link-text ml-1">{{tenancynotification}}</span>
            </span>
        </a>
    </div> -->
    <div *ngIf="isQuickThemeSelectEnabled" id="kt_theme_selection_panel_toggle" class="kt-header__topbar-item dropdown">
        <div class="kt-header__topbar-wrapper">
            <span class="kt-header__topbar-icon kt-header__topbar-icon--brand">
                <i class="flaticon-interface-7"></i>
            </span>
        </div>
    </div>
    <!-- <div dropdown class="kt-header__topbar-item dropdown dropdown-language" aria-haspopup="true" *ngIf="languages.length > 1">
        <div dropdownToggle class="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="30px,0px" aria-expanded="true">
            <span class="kt-header__topbar-icon kt-header__topbar-icon--brand">
                <i [class]="currentLanguage.icon"></i>
            </span>
        </div>
        <div *dropdownMenu class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg" [attr.aria-label]="l('Languages')">
            <ul class="kt-nav kt-margin-t-10 kt-margin-b-10">
                <li class="kt-nav__item" *ngFor="let language of languages">
                    <a class="kt-nav__link" (click)="changeLanguage(language.name)">
                        <span class="kt-nav__link-icon">
                            <i [class]="language.icon"></i>
                        </span>
                        <span class="kt-nav__link-text">
                            {{language.displayName}}
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div> -->

    <div headerNotifications class="kt-header__topbar-item dropdown" dropdown id="header_notification_bar"></div>
    <div class="kt-header__topbar-item kt-header__topbar-item--user" dropdown>
        <div class="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px" aria-expanded="false"
            dropdownToggle>
            <div class="kt-header__topbar-user">
                <i *ngIf="isImpersonatedLogin" class="fa fa-reply kt-font-danger mr-2"></i>
                <span class="kt-header__topbar-username kt-hidden-mobile">
                    <!-- <span *ngIf="isMultiTenancyEnabled" class="tenancy-name">{{tenancyName}}\</span>{{userName}} -->
                    <span *ngIf="isMultiTenancyEnabled" class="tenancy-name">{{Name}}</span>
                </span>
                <img *ngIf="profilePicture" class="header-profile-picture m--img-rounded m--marginless m--img-centered"
                    alt="Pic" [src]="profilePicture" onerror="this.src='../../../../assets/images/default-profile-picture.png'">
            </div>
        </div>
        <div *dropdownMenu
            class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
            x-placement="bottom-end"
            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(1253px, 64px, 0px);">
            <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x user-icon"
                [ngStyle]="{ 'background': 'url(' + appRootUrl() + 'assets/metronic/themes/' + currentTheme.baseSettings.theme + '/images/bg/bg-1.jpg)', 'background-size': 'cover'}">
                <div class="kt-user-card__avatar">
                    <img class="" alt="Pic" [src]="profilePicture" onerror="this.src='../../../../assets/images/default-profile-picture.png'">
                </div>
                <div class="kt-user-card__name">
                    <!-- <span *ngIf="isMultiTenancyEnabled" class="tenancy-name">{{tenancyName}}\</span>{{userName}} -->
                    <span *ngIf="isMultiTenancyEnabled" class="tenancy-name">{{Name}}</span>
                </div>
            </div>
            <div class="kt-notification">
                <a *ngIf="isImpersonatedLogin" href="javascript:;" class="kt-notification__item"
                    (click)="backToMyAccount()">
                    <div class="kt-notification__item-icon">
                        <i class="fa fa-reply kt-font-danger"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span>
                                {{"BackToMyAccount" | localize}}
                            </span>
                        </div>
                    </div>
                </a>
                <!-- <a href="javascript:;" class="kt-notification__item linked-accounts" (click)="showLinkedAccounts()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-user-settings"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span>
                                {{"ManageLinkedAccounts" | localize}}
                            </span>
                        </div>
                    </div>
                </a> -->
                <!-- <div class="kt-notification__custom py-0 linked-accounts">
                    <ul class="kt-nav linked-accounts" *ngIf="recentlyLinkedUsers"
                        [attr.aria-label]="l('LinkedAccounts')">
                        <li *ngFor="let linkedUser of recentlyLinkedUsers" class="kt-nav__item py-2">
                            <a href="javascript:;" (click)="switchToLinkedUser(linkedUser)" class="recently-linked-user">
                                <span class="kt-nav__link-bullet">
                                    <span></span>
                                </span>
                                <span class="kt-nav__link-text">
                                    {{getShownUserName(linkedUser)}}
                                </span>
                            </a>
                        </li>
                    </ul>
                </div> -->
                <a href="javascript:;" class="kt-notification__item" (click)="changePassword()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-more-v6"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span>
                                {{"ChangePassword" | localize}}
                            </span>
                        </div>
                    </div>
                </a>
                <a href="javascript:;" class="kt-notification__item" (click)="MyProfilePicture()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-profile-1"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span>
                                {{"My Profile" | localize}}
                            </span>
                        </div>
                    </div>
                </a>
                <!-- <div>
                        <div class="kt-notification__item-icon">
                                <i class="flaticon-more-v6"></i>
                            </div>
                <div><button *ngIf="adminuser!=true"  (click)="MyProfilePicture()"
                    class="btn top-btn"> {{l("My Profile")}}</button>
                </div>
            </div> -->

                <!-- <a href="javascript:;" class="kt-notification__item" (click)="showLoginAttempts()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-list"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span id="ShowLoginAttemptsLink">
                                {{"LoginAttempts" | localize}}
                            </span>
                        </div>
                    </div>
                </a> -->
                <!-- <a href="javascript:;" class="kt-notification__item" (click)="changeProfilePicture()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-profile-1"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span id="UserProfileChangePictureLink">
                                {{"ChangeProfilePicture" | localize}}
                            </span>
                        </div>
                    </div>
                </a> -->
                <!-- <a href="javascript:;" class="kt-notification__item" (click)="changeMySettings()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-cogwheel"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span id="UserProfileMySettingsLink">
                                {{"MySettings" | localize}}
                            </span>
                        </div>
                    </div>
                </a> -->
                <!-- <a *ngIf="!('Pages.Administration.UiCustomization' | permission)" routerLink="admin/ui-customization" class="kt-notification__item">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-medical"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span>
                                {{"VisualSettings" | localize}}
                            </span>
                        </div>
                    </div>
                </a>
                <a href="javascript:;" class="kt-notification__item" (click)="downloadCollectedData()">
                    <div class="kt-notification__item-icon">
                        <i class="flaticon-download"></i>
                    </div>
                    <div class="kt-notification__item-details">
                        <div class="kt-notification__item-title kt-font-bold">
                            <span id="UserDownloadCollectedDataLink">
                                {{"DownloadCollectedData" | localize}}
                            </span>
                        </div>
                    </div>
                </a> -->
                <div class="kt-notification__custom">
                    <a (click)="logout()" class="btn btn-label-brand btn-sm btn-bold">{{"Logout" | localize}}</a>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="(isHost || ('App.ChatFeature' | checkFeature)) && !chatConnected"
        class="kt-header__topbar-item dropdown" style="display: none;">
        <div class="kt-header__topbar-wrapper">
            <span class="kt-header__topbar-icon kt-header__topbar-icon--brand">
                <img *ngIf="!chatConnected" src="./assets/common/images/loading.gif" style="width: 23px;"
                    tooltip="{{'ChatIsConnecting' | localize}}" placement="left" />
            </span>
        </div>
    </div>
    <div id="kt_quick_sidebar_toggle" [hidden]="!(isHost || ('App.ChatFeature' | checkFeature)) || !chatConnected"
        class="kt-header__topbar-item dropdown">
        <div class="kt-header__topbar-wrapper">
            <span class="kt-header__topbar-icon kt-header__topbar-icon--brand">
                <i class="flaticon-chat-2"></i>
            </span>
        </div>
    </div>
</div>
<!-- END: Topbar -->
