import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponentBase } from '@shared/common/app-component-base';
import { BranchDropDown, CreateOrEditExpenseMapPolicyDtoNew, CreateOrEditHolidayMapPolicyDto, CreateOrEditHolidayMapPolicyDtoNew, CreateOrEditHolidaypolicyDetailsDto, DropDown, DropDownEmployee, DropdownServicesServiceProxy, EmployeesServiceProxy,ExpensePolicyMapAppServicesServiceProxy, GetExpenseMapPolicyDtoNew, HoldiayPoliciesServiceProxy, HolidaypolicyDetailsesServiceProxy, UserServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';
import { Dropdown, Paginator } from 'primeng/primeng';
import { Table } from 'primeng/table';
import { finalize } from 'rxjs/operators';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-expense-policy-map',
  templateUrl: './expense-policy-map.component.html',
  styleUrls: ['./expense-policy-map.component.css']
})
export class ExpensePolicyMapComponent extends AppComponentBase implements OnInit {

  @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  holidaypolicyDetails: CreateOrEditHolidaypolicyDetailsDto = new CreateOrEditHolidaypolicyDetailsDto();

  constructor(
    injector: Injector,
    private _holidaypolicyDetailsesServiceProxy: HolidaypolicyDetailsesServiceProxy,
    private _holdiayPoliciesServiceProxy: HoldiayPoliciesServiceProxy,
    private _ExpensePolicyAppServicesServiceProxy:ExpensePolicyMapAppServicesServiceProxy,
    private _employeesServiceProxy: EmployeesServiceProxy,
    private _dropdownServiceProxy:DropdownServicesServiceProxy,
    private _userServiceProxy: UserServiceProxy,
    private router:Router,
    private route:ActivatedRoute,
    private fb: FormBuilder
  ) { 
    super(injector);  }

    policies:DropDown[];
    location:BranchDropDown[];
    designation:DropDown[];
    enableDrop: boolean=false;
    enableDesignationDrop: boolean ;
    yes:any=0;
    checked:boolean=false;
    enableEmployee:boolean=false;
    Holiday:boolean;
    PolicyName:string;
    active = false;
    PolicyNameDisable:boolean;
    filterText = '';
    policiesname:any;
    value:any;
    selectedPermission = '';
    role = '';
    onlyLockedUsers = false;
    Applicable:number=1;
    Employees:any[]=[];
    pay:any;
    designationId:number;
    DesignationShow:number;
    selectedEmp: string[] = [];
    selectedbranches:any[]=[];     
    public optionsApplicableTo: any = [
      { label: 'Employee', value: 1 },       
    ];
    public DropdownDo:any=[      
          { label: 'Yes', value: 1 },
          { label: 'No', value: 0},
    ];
    public BranchOptions: any = [
      { label: 'Branch', code: 1 },
      { label: 'SubBranch', code: 2 },  
    ];
  
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;
   employeeData:DropDownEmployee[];
   empvalue : any;
   items: DropDown[];
   item: string;
   policyid:number;
   saving = false;
   HolidayMapPolicyDto :CreateOrEditHolidayMapPolicyDtoNew[];
   getPolicyData: GetExpenseMapPolicyDtoNew[];
   EmployeesSelected:DropDownEmployee[]=[];
   selecteddemployee:any;
  
    ngOnInit() {
      this.filterpolicies();
      this.filterlocation();
      this.filterdesignation();
      this.getUser()   ;
      this.Applicable=1;
      
                          
                this.selecteddemployee=null;                
           this.designationId=null;
            this.Applicable = null;  
            this.DesignationShow=null;
            this.enableDesignationDrop=false;
    }
  
   
    show(id: number,Ploicy?: string): void {
     
      
     this.PolicyName=Ploicy;
     this.active = true;
     this.policyid=id;
              this.PolicyNameDisable=true;           
              this._ExpensePolicyAppServicesServiceProxy.getAllPolicyData(this.policyid).subscribe(result =>{
                this.getPolicyData=result;        
                if(this.getPolicyData.length!=0)
                {                 
                  
                  for(var i=0;i<this.getPolicyData.length;i++)
                  {                     
                    this.designationId=this.getPolicyData[i].designationId; 
                    if(this.getPolicyData[i].employeeiD==0) {
                      this.selectedEmp.length=0;
                    }
                    else{
                      this.selectedEmp[i]=this.getPolicyData[i].employeeiD.toString();
                    }                   
                    this.Employees[i]=this.getPolicyData[i].employeeiD;
                  }
                  this.selecteddemployee = this.selectedEmp;
                  //console.log"selectemployeedata", this.EmployeesSelected);
                  this.Applicable=1;
                  if(this.designationId==null || this.designationId==0){
                    this.DesignationShow=undefined;
                    this.enableDesignationDrop=false;
                  }
                  else{
                    this.DesignationShow=1;
                    this.enableDesignationDrop=true;
                    // this.selecteddemployee=null;
                  }
                  // if(this.selectedEmp==null || this.selectedEmp.length==0){
                  //   this.selecteddemployee=null;
                  // }
                  
                }
                else{
                      this.selecteddemployee=null; 
                      this.Applicable = null;  
                      this.DesignationShow=null;
                      this.enableDesignationDrop=false;
                }
              });              
              this.modal.show();
    }
   
  
    filterpolicies()
    {     
        this._dropdownServiceProxy.getPolicies().subscribe(Customer=>{
          this.policies=Customer;
    })
  }
  
  filterlocation()
  {
      this._holdiayPoliciesServiceProxy.getLocation().subscribe(Customer=>{
        this.location=Customer;
  })
  }
  filterdesignation()
    {

        this._dropdownServiceProxy.getDegination().subscribe(Customer=>{
          this.designation=Customer;
    })
}
  DropbranchValidation(){
    if(this.pay==1)
    {
      this.enableDrop=true;
    }
    else this.enableDrop=false;
  
  }
  DropDesignationValidation(){
    if(this.DesignationShow==1)
    {
      this.enableDesignationDrop=true;
    }
    else this.enableDesignationDrop=false;
  }

  getUser() {  
    
     this._dropdownServiceProxy.getEmployeesForExpense().subscribe(result => {
       this.employeeData=result;
       //console.log"employee dropdown data",this.employeeData);
        
    });
  }
  onCheckboxChange(event: any) {
    const selectedEmployeeId = parseInt(event.target.value, 10);
    const isChecked = event.target.checked;
  
    if (isChecked) {
      this.selectedbranches.push({value:selectedEmployeeId});
    } else {
      const index = this.selectedbranches.indexOf(selectedEmployeeId);
      this.selectedbranches.splice(index, 1);
    }
  }
  SelectEmployee(event?: any){
    
     this.Employees =event.value;
    
  }
   Save(): void{
    
    this.saving = true;
    let mapdto = new Array<CreateOrEditExpenseMapPolicyDtoNew>();      
    if(this.selecteddemployee!=null){
    for(var i=0;i<this.Employees.length;i++)
  {
      var mpdtop = new CreateOrEditExpenseMapPolicyDtoNew()
      mpdtop.branchID=0;
      mpdtop.policyId=this.policyid;
      mpdtop.policyType="Expense";
      if(this.DesignationShow==0){
        mpdtop.designationId=0;
      }
      else{
        mpdtop.designationId=this.designationId;
      }
      mpdtop.departmentId=0;
      mpdtop.divisionId=0; 
      
      mpdtop.employeeId=this.Employees[i];  
      // //console.logthis.mpdtop.policyId);
      mapdto.push(mpdtop);
      
  }
}
else{
  var mpdtop = new CreateOrEditExpenseMapPolicyDtoNew()
      mpdtop.branchID=0;
      mpdtop.policyId=this.policyid;
      mpdtop.policyType="Expense";
      mpdtop.departmentId=0;
      mpdtop.divisionId=0; 
      if(this.DesignationShow==0){
        mpdtop.designationId=0;
      }
      else{
        mpdtop.designationId=this.designationId;
      }
      mpdtop.employeeId=0;    
      mapdto.push(mpdtop);
}

  //console.log"dto",mapdto);
      
      this._ExpensePolicyAppServicesServiceProxy.insertMapPolicy(mapdto).pipe(finalize(() => { this.saving = false;})).subscribe(() => {

        this.close();
        this.notify.info(this.l('SavedSuccessfully'));
      
        this.saving=false;
        this.ngOnInit;
        
        this.modalSave.emit(null);
     });
  
  }
  
  close(): void {
    // this.selectedbranches.length=0;
    this.selectedEmp.length=0;
    this.ngOnInit;
    this.active = false;
    this.modal.hide();
    this.selecteddemployee=null;
    // this.selecteddemployee.length=0;
  }
}
