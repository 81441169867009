import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateOrEditRoleModalComponent } from '@app/admin/roles/create-or-edit-role-modal.component';
import { CreateOrEditCountryModalComponent } from '@app/main/country/countries/create-or-edit-country-modal.component';
import { ViewCountryModalComponent } from '@app/main/country/countries/view-country-modal.component';
import { CreateOrEditDepartmentsModalComponent } from '@app/main/depatrment/departmentses/create-or-edit-departments-modal.component';
import { ViewDepartmentsModalComponent } from '@app/main/depatrment/departmentses/view-departments-modal.component';
import { CreateOrEditDesignationModalComponent } from '@app/main/designation/designations/create-or-edit-designation-modal.component';
import { ViewDesignationModalComponent } from '@app/main/designation/designations/view-designation-modal.component';
import { CreateOrEditDivisionModalComponent } from '@app/main/division/divisions/create-or-edit-division-modal.component';
import { ViewDivisionModalComponent } from '@app/main/division/divisions/view-division-modal.component';
import { CreateOrEditDocumenttypeModalComponent } from '@app/main/documentType/create-or-edit-documenttype-modal/create-or-edit-documenttype-modal.component';
import { ViewDocumentTypeModalComponent } from '@app/main/documentType/create-or-edit-documenttype-modal/view-document-type-modal/view-document-type-modal.component';
import { CreateOrEditEmplolyeeCodeSettingModalComponent } from '@app/main/emplolyeeCodeSetting/emplolyeeCodeSettings/create-or-edit-emplolyeeCodeSetting-modal.component';
import { ViewEmplolyeeCodeSettingModalComponent } from '@app/main/emplolyeeCodeSetting/emplolyeeCodeSettings/view-emplolyeeCodeSetting-modal.component';
import { CreateOrEditHolidayModalComponent } from '@app/main/holiday/holidaies/create-or-edit-holiday-modal.component';
import { ViewHolidayModalComponent } from '@app/main/holiday/holidaies/view-holiday-modal.component';
import { CreateOrEditHoldiayPolicyModalComponent } from '@app/main/holidayPolicy/holdiayPolicies/create-or-edit-holdiayPolicy-modal.component';
import { ViewHoldiayPolicyModalComponent } from '@app/main/holidayPolicy/holdiayPolicies/view-holdiayPolicy-modal.component';
import { CreateOrEditHolidaypolicyDetailsModalComponent } from '@app/main/holidaypolicyDetails/holidaypolicyDetailses/create-or-edit-holidaypolicyDetails-modal.component';
import { CreateOrEditLeaveTypesModalComponent } from '@app/main/leaveTypes/leaveTypes/create-or-edit-leaveTypes-modal.component';
import { ViewLeaveTypesModalComponent } from '@app/main/leaveTypes/leaveTypes/view-leaveTypes-modal.component';
import { CreateOrEditLocationsModalComponent } from '@app/main/location/locationses/create-or-edit-locations-modal.component';
import { ViewLocationsModalComponent } from '@app/main/location/locationses/view-locations-modal.component';
import { CreateOrEditPayComponetsModalComponent } from '@app/main/payComponets/payComponetses/create-or-edit-payComponets-modal.component';
import { ViewPayComponetsModalComponent } from '@app/main/payComponets/payComponetses/view-payComponets-modal.component';
import { CreateOrEditPayGradeModalComponent } from '@app/main/payGrade/payGrades/create-or-edit-payGrade-modal.component';
import { ViewPayGradeModalComponent } from '@app/main/payGrade/payGrades/view-payGrade-modal.component';
import { CreateOrEditPayGradeDetailsModalComponent } from '@app/main/payGradeDetails/payGradeDetailses/create-or-edit-payGradeDetails-modal.component';
import { CreateOrEditStateModalComponent } from '@app/main/state/states/create-or-edit-state-modal.component';
import { ViewStateModalComponent } from '@app/main/state/states/view-state-modal.component';
import { CreateOrEditRequesrFlowModalComponent } from '@app/main/workFlow/requesrFlows/create-or-edit-requesrFlow-modal.component';
import { ViewRequesrFlowModalComponent } from '@app/main/workFlow/requesrFlows/view-requesrFlow-modal.component';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CountriesServiceProxy, TokenAuthServiceProxy, CountryDto, StatesServiceProxy, StateDto, HolidaiesServiceProxy, HolidayDto, DepartmentsDto, DepartmentsesServiceProxy, DesignationsServiceProxy, DesignationDto, DivisionDto, DivisionsServiceProxy, LocationsesServiceProxy, LocationsDto, LeaveTypesServiceProxy, LeaveTypesDto, TenancyConfiguratonWizardsServiceProxy, DocumentTypeServiceProxy, DocumentTypeDto, PayGradesServiceProxy, PayComponetsesServiceProxy, RoleServiceProxy, PayComponetsDto, PayGradeDto, RoleListDto, CreateOrEditEmployeeStateInsuranceDto, WorkflowListDto, EmplolyeeCodeSettingDto, EmplolyeeCodeSettingsServiceProxy, EmployeeStateInsuranceServiceProxy, WorkflowServiceProxy, HoldiayPolicyDto, HoldiayPoliciesServiceProxy, ShiftDetailsesServiceProxy, AttendanceRulesServiceProxy, AttendanceRuleDto, ExpensePolicyServiceProxy, ExpensePoliciesDto, ExpenseServiceProxy } from '@shared/service-proxies/service-proxies';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { AttendancePolicyMApComponent } from '@app/main/attendance-policy-map/attendance-policy-map.component';

import { ProvidentFundSettingsServiceProxy, CreateOrEditProvidentFundSettingDto } from '@shared/service-proxies/service-proxies';
import { NotifyService } from 'abp-ng2-module/dist/src/notify/notify.service';
import { result } from 'lodash';
import { ModalDirective } from 'ngx-bootstrap';
import { LazyLoadEvent, MenuItem, MessageService } from 'primeng/api';
import { Paginator } from 'primeng/primeng';
import { Table } from 'primeng/table';
//import { LeaverulewizardsComponent } from './installationwizards/wizards/leaverulewizards/leaverulewizards.component'
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CountryListComponent } from '../country-list/country-list.component';
import { LeaveRulesServiceProxy, LeaveRuleDto } from '@shared/service-proxies/service-proxies';
import { LeaverulewizardsComponent } from './leaverulewizards/leaverulewizards.component';
import { ViewexpensepolicyComponent } from '@app/main/expensepolicy/viewexpensepolicy/viewexpensepolicy.component';
import { CreateexpensepolicyComponent } from '@app/main/expensepolicy/createexpensepolicy/createexpensepolicy.component';
import { ExpensePolicyMapComponent } from '@app/main/expense-policy-map/expense-policy-map.component';
import { MappingexpenseComponent } from '@app/main/mappingexpense/mappingexpense.component';

@Component({
  selector: 'app-wizards',
  templateUrl: './wizards.component.html',
  styleUrls: ['./wizards.component.css']
})
export class WizardsComponent extends AppComponentBase {
  items: MenuItem[];

  subscription: Subscription;

  //constructor(public messageService: MessageService) {}
  constructor(injector: Injector,
    private _countriesServiceProxy: CountriesServiceProxy,
    private _statesServiceProxy: StatesServiceProxy,
    private _attendanceRulesServiceProxy: AttendanceRulesServiceProxy,
    private _notifyService: NotifyService,
    private _tokenAuth: TokenAuthServiceProxy,
    private _activatedRoute: ActivatedRoute,
    private _holidaiesServiceProxy: HolidaiesServiceProxy,
    private _departmentsesServiceProxy: DepartmentsesServiceProxy,
    private _designationsServiceProxy: DesignationsServiceProxy,
    private _divisionsServiceProxy: DivisionsServiceProxy,
    private _locationsesServiceProxy: LocationsesServiceProxy,
    private _leaveTypesServiceProxy: LeaveTypesServiceProxy,
    private _installtionwizards: TenancyConfiguratonWizardsServiceProxy,
    private _documentTypeServiceProxy: DocumentTypeServiceProxy,
    private _payGradesServiceProxy: PayGradesServiceProxy,
    private _payComponetsesServiceProxy: PayComponetsesServiceProxy,
    private router: Router,
    private _shiftDetailsServiceProxy: ShiftDetailsesServiceProxy,
    private _roleService: RoleServiceProxy,
    private _requesrFlowsServiceProxy: WorkflowServiceProxy,
    private _emplolyeeCodeSettingsServiceProxy: EmplolyeeCodeSettingsServiceProxy,
    private _employeeStateInsuranceServiceProxy: EmployeeStateInsuranceServiceProxy,
    private _holdiayPoliciesServiceProxy: HoldiayPoliciesServiceProxy,
    private _providentFundSettingsServiceProxy: ProvidentFundSettingsServiceProxy,
    private _leaveRulesServiceProxy: LeaveRulesServiceProxy,
    private _expenseServiceProxy: ExpensePolicyServiceProxy,
    private _fileDownloadService: FileDownloadService) { super(injector); }
  active = false;
  active1 = false;
  saving = false;
  enableSaveButton: boolean = true;
  enableUpdateButton: boolean = false;
  enableApplicable: boolean = false;
  employeeStateInsurance: CreateOrEditEmployeeStateInsuranceDto = new CreateOrEditEmployeeStateInsuranceDto();
  public BoolDropdownValue: any = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  public RoundOffValue: any = [
    { label: 'None', value: 1 },
    { label: 'Nearest', value: 2 },
    { label: 'Higher', value: 3 },
  ];
  @ViewChild('createOrEditRoleModal', { static: true }) createOrEditRoleModal: CreateOrEditRoleModalComponent;
  @ViewChild('createOrEditPayComponetsModal', { static: true }) createOrEditPayComponetsModal: CreateOrEditPayComponetsModalComponent;
  @ViewChild('viewPayComponetsModalComponent', { static: true }) viewPayComponetsModal: ViewPayComponetsModalComponent;

  @ViewChild('createOrEditPayGradeModal', { static: true }) createOrEditPayGradeModal: CreateOrEditPayGradeModalComponent;
  @ViewChild('viewPayGradeModalComponent', { static: true }) viewPayGradeModal: ViewPayGradeModalComponent;
  @ViewChild('createOrEditPayGradeDetailsModal', { static: true }) createOrEditPayGradeDetailsModal: CreateOrEditPayGradeDetailsModalComponent;
  @ViewChild('createOrEditExpensePolicyModal', { static: true }) createOrEditExpensePolicyModal: CreateexpensepolicyComponent;
  @ViewChild('viewexpensepolicymodal', { static: true }) viewexpensepolicymodal: ViewexpensepolicyComponent;
  @ViewChild('ExpensePolicy', { static: true }) ExpensePolicy: ExpensePolicyMapComponent;
  @ViewChild('Expensesummary', { static: true }) Expensesummary: MappingexpenseComponent;

  @ViewChild('createOrEditDocumentType', { static: true }) createOrEditDocumentType: CreateOrEditDocumenttypeModalComponent;
  @ViewChild('viewDocumentTypeModal', { static: true }) viewDocumentTypeModal: ViewDocumentTypeModalComponent;

  @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
  @ViewChild('countryListComponent', { static: true }) countryListComponent: CountryListComponent;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('createOrEditCountryModal', { static: true }) createOrEditCountryModal: CreateOrEditCountryModalComponent;
  @ViewChild('viewCountryModalComponent', { static: true }) viewCountryModal: ViewCountryModalComponent;
  @ViewChild('createOrEditStateModal', { static: true }) createOrEditStateModal: CreateOrEditStateModalComponent;
  @ViewChild('viewStateModalComponent', { static: true }) viewStateModal: ViewStateModalComponent;

  @ViewChild('createOrEditHolidayModal', { static: true }) createOrEditHolidayModal: CreateOrEditHolidayModalComponent;
  @ViewChild('viewHolidayModalComponent', { static: true }) viewHolidayModal: ViewHolidayModalComponent;
  @ViewChild('createOrEditDesignationModal', { static: true }) createOrEditDesignationModal: CreateOrEditDesignationModalComponent;
  @ViewChild('viewDesignationModalComponent', { static: true }) viewDesignationModal: ViewDesignationModalComponent;

  @ViewChild('dataTable', { static: true }) dataTable: Table;
  @ViewChild('paginator', { static: true }) paginator: Paginator;
  @ViewChild('createOrEditDepartmentsModal', { static: true }) createOrEditDepartmentsModal: CreateOrEditDepartmentsModalComponent;
  @ViewChild('viewDepartmentsModalComponent', { static: true }) viewDepartmentsModal: ViewDepartmentsModalComponent;
  @ViewChild('createOrEditDivisionModal', { static: true }) createOrEditDivisionModal: CreateOrEditDivisionModalComponent;
  @ViewChild('viewDivisionModalComponent', { static: true }) viewDivisionModal: ViewDivisionModalComponent;

  @ViewChild('createOrEditLocationsModal', { static: true }) createOrEditLocationsModal: CreateOrEditLocationsModalComponent;
  @ViewChild('viewLocationsModalComponent', { static: true }) viewLocationsModal: ViewLocationsModalComponent;

  @ViewChild('createOrEditLeaveTypesModal', { static: true }) createOrEditLeaveTypesModal: CreateOrEditLeaveTypesModalComponent;
  @ViewChild('viewLeaveTypesModalComponent', { static: true }) viewLeaveTypesModal: ViewLeaveTypesModalComponent;

  @ViewChild('createOrEditRequesrFlowModal', { static: true }) createOrEditRequesrFlowModal: CreateOrEditRequesrFlowModalComponent;
  @ViewChild('viewRequesrFlowModalComponent', { static: true }) viewRequesrFlowModal: ViewRequesrFlowModalComponent;
  @ViewChild('createOrEditEmplolyeeCodeSettingModal', { static: true }) createOrEditEmplolyeeCodeSettingModal: CreateOrEditEmplolyeeCodeSettingModalComponent;
  @ViewChild('viewEmplolyeeCodeSettingModalComponent', { static: true }) viewEmplolyeeCodeSettingModal: ViewEmplolyeeCodeSettingModalComponent;

  @ViewChild('createOrEditHoldiayPolicyModal', { static: true }) createOrEditHoldiayPolicyModal: CreateOrEditHoldiayPolicyModalComponent;
  @ViewChild('viewHoldiayPolicyModalComponent', { static: true }) viewHoldiayPolicyModal: ViewHoldiayPolicyModalComponent;
  @ViewChild('createOrEditHolidaypolicyDetailsModal', { static: true }) createOrEditHolidaypolicyDetailsModal: CreateOrEditHolidaypolicyDetailsModalComponent;
  @ViewChild('leaverulewizards', { static: true }) leaverulewizards: LeaverulewizardsComponent;

  @ViewChild('attendancepolicy', { static: true }) attendancepolicy: AttendancePolicyMApComponent;
  selectedPermission = '';

  enableCalculateVPF: boolean = false;
  enableVPFRoundOff: boolean = false;

  providentFundSetting: CreateOrEditProvidentFundSettingDto = new CreateOrEditProvidentFundSettingDto();
  enableProperatePf: boolean = false;

  public EstablishmentValue: any = [
    { label: 'Non-Exempted', value: 'Non-Exempted' },
    { label: 'Exempted', value: 'Exempted' },
  ];

  public DeductPensionValue: any = [
    { label: 'All', value: 'All' },
    { label: 'New', value: 'New' },
    { label: 'Joinees', value: 'Joinees' },
    { label: 'No', value: 'No' },
  ];
  public AdminChargeValue: any = [
    { label: 'Basic Wages', value: 'Basic Wages' },
    { label: 'Pension Wages', value: 'Pension Wages' },
    { label: 'EDLI Wages', value: 'EDLI Wages' },
  ];
  public VPFRoundOffValue: any = [
    { label: 'None', value: 'None' },
    { label: '50 Paise', value: '50 Paise' },
  ];

  activeIndex: number = 0;
  Modalhide: number;
  countryNameFilter = '';
  itemlistcount: number;
  formname: string = '';

  show(id?: number) {
    debugger
    if (id == 1) {
      this._installtionwizards.getInstallwizardsMenu(
      ).pipe(finalize(() => { this.itemlistcount != 0 ? this.modal.show() : this.modal.hide() }))
        .subscribe(result => {
          this.items = result;

          this.itemlistcount = this.items == null ? 0 : this.items.length;
          this._installtionwizards.getActiveIndex().pipe(finalize(() => this.formname = this.items[this.activeIndex].label)).subscribe(result => {
            this.Modalhide = result;
            this.activeIndex = this.itemlistcount - result;
          });

        });
    }
    else {
      this._installtionwizards.getInstallwizardsMenuWizars(
      ).pipe(finalize(() => { this.modal.show() }))
        .subscribe(result => {
          this.items = result;
          this.itemlistcount = this.items == null ? 0 : this.items.length;
          this.formname = this.items[this.activeIndex].label;
        });
    }
  }


  deleteRequesrFlow(requesrFlow: WorkflowListDto): void {
    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._requesrFlowsServiceProxy.delete(requesrFlow.id)
            .subscribe(() => {
              this.reloadPage();
              this.notify.success(this.l('SuccessfullyDeleted'));
            });
        }
      }
    );
  }

  getExpensePolicies(event?: LazyLoadEvent) {
    if (this.primengTableHelper.shouldResetPaging(event)) {
      this.paginator.changePage(0);
      return;
    }
    this.primengTableHelper.showLoadingIndicator();

    this._expenseServiceProxy.getAllExpensesForWizard().subscribe(result => {
      this.primengTableHelper.totalRecordsCount = result.totalCount;
      this.primengTableHelper.records = result.items;
      //console.log"dashboard", this.primengTableHelper.records)
      this.primengTableHelper.hideLoadingIndicator();
    });
  }

  deleteExpensePolicy(expensepolicy: ExpensePoliciesDto): void {
    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._expenseServiceProxy.delete(expensepolicy.id)
            .subscribe(() => {
              this.reloadPage();
              this.notify.success(this.l('SuccessfullyDeleted'));
            });
        }
      }
    );
  }
  create(): void {
    this.createOrEditHolidayModal.show();
  }
  getShiftRule() {

    this.primengTableHelper.showLoadingIndicator();

    this._shiftDetailsServiceProxy.getAllShiftWizards(

    ).subscribe(result => {

      this.primengTableHelper.records = result;
      this.primengTableHelper.hideLoadingIndicator();
    });
  }
  getAttendanceRules() {
    this.primengTableHelper.showLoadingIndicator();

    this._attendanceRulesServiceProxy.getAllAttendanceRuleforWizard(

    ).subscribe(result => {

      this.primengTableHelper.records = result.items || [];;

      this.primengTableHelper.hideLoadingIndicator();
    });
  }
  editShift(id: number): void {
    this.router.navigateByUrl('app/main/shift/createShift/' + id);
  }
  createShift(): void {
    this.router.navigateByUrl('app/main/shift/createShift/0');
  }
  createAttendanceRule(): void {
    this.router.navigateByUrl('app/main/attendanceRule/createAttendanceRule/0');
  }
  createOrEditAttendanceRule(id): void {
    this.router.navigateByUrl('app/main/attendanceRule/createAttendanceRule/' + id);
  }
  viewAttendanceRule(id): void {
    this.router.navigateByUrl('app/main/attendanceRule/viewAttendanceRule/' + id);
  }

  deleteAttendanceRule(attendanceRule: AttendanceRuleDto): void {
    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._attendanceRulesServiceProxy.delete(attendanceRule.id)
            .subscribe(() => {
              this.reloadPage();
              this.notify.success(this.l('SuccessfullyDeleted'));
            });
        }
      }
    );
  }

  deleteHoliday(holiday: HolidayDto): void {
    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._holidaiesServiceProxy.delete(holiday.id)
            .subscribe(() => {

              this.notify.success(this.l('SuccessfullyDeleted'));
              this.getHoldiayPolicies();
            });
        }
      }
    );
  }

  getHolidaies() {


    this.primengTableHelper.showLoadingIndicator();

    this._holidaiesServiceProxy.getAllHoliday(
    ).subscribe(result => {
      this.primengTableHelper.records = result;
      //console.log"Result is",result)
      this.primengTableHelper.hideLoadingIndicator();
    });
  }
  close() {
    this.modal.hide();
  }



  advancedFiltersAreShown = false;
  filterText = '';
  nameFilter = '';
  nationalityFilter = '';




  createHoldiayPolicy(): void {
    this.createOrEditHoldiayPolicyModal.show();
  }

  getCountries() {


    //console.logevent);
    // event.first= 0;
    // event.globalFilter= null;
    // event.multiSortMeta= undefined;
    // event.rows= 15;
    // event.sortField= "name";
    // event.sortOrder= 1



    this.primengTableHelper.showLoadingIndicator();

    this._countriesServiceProxy.getAllWizards(

    ).subscribe(result => {
      this.primengTableHelper.records = result;
      this.primengTableHelper.hideLoadingIndicator();
    });

  }


  getStates() {
    this.primengTableHelper.showLoadingIndicator();
    this._statesServiceProxy.getAllStageWizards(
    ).subscribe(result => {
      this.primengTableHelper.records = result;
    });
  }
  reloadPage(): void {
    this.paginator.changePage(this.paginator.getPage());
  }

  createCountry(): void {
    this.createOrEditCountryModal.show();
  }


  deleteCountry(country: CountryDto): void {
    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._countriesServiceProxy.delete(country.id)
            .subscribe(() => {

              this.notify.success(this.l('SuccessfullyDeleted'));

            });
        }
      }
    );
  }

  exportToExcel(): void {
    this._countriesServiceProxy.getCountriesToExcel(
      this.filterText,
      this.nameFilter,
      this.nationalityFilter,
    )
      .subscribe(result => {
        this._fileDownloadService.downloadTempFile(result);
      });
  }
  nextPage() {
    this._installtionwizards.updateSteps(this.items[this.activeIndex].label).subscribe(result => {
      this.notify.success("Update step successfully");
    });
    this.activeIndex = this.activeIndex + 1;
    this.formname = this.items[this.activeIndex].label;



  }
  backPage() {
    this.activeIndex = this.activeIndex - 1;
    this.formname = this.items[this.activeIndex].label;
  }
  fishInstallation() {
    this.modal.hide();
    window.location.reload();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  createState(): void {
    this.createOrEditStateModal.show();
  }


  deleteState(state: StateDto): void {
    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._statesServiceProxy.delete(state.id)
            .subscribe(() => {

              this.notify.success(this.l('SuccessfullyDeleted'));
            });
        }
      }
    );
  }
  getDepartmentses() {


    this.primengTableHelper.showLoadingIndicator();

    this._departmentsesServiceProxy.getAllDepartemntys(

    ).subscribe(result => {

      this.primengTableHelper.records = result;
      this.primengTableHelper.hideLoadingIndicator();
    });
  }

  deleteDepartments(departments: DepartmentsDto): void {
    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._departmentsesServiceProxy.delete(departments.id)
            .subscribe(() => {

              this.notify.success(this.l('SuccessfullyDeleted'));
              this.getDepartmentses();
            });
        }
      }
    );
  }
  getDesignations() {


    this.primengTableHelper.showLoadingIndicator();

    this._designationsServiceProxy.getAllDesgin(


    ).subscribe(result => {

      this.primengTableHelper.records = result;
      this.primengTableHelper.hideLoadingIndicator();
    });
  }
  deleteDesignation(designation: DesignationDto): void {
    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._designationsServiceProxy.delete(designation.id)
            .subscribe(() => {

              this.notify.success(this.l('SuccessfullyDeleted'));
              this.getDesignations();
            });
        }
      }
    );
  }

  getDivisions() {

    this.primengTableHelper.showLoadingIndicator();

    this._divisionsServiceProxy.getAllDivis(

    ).subscribe(result => {

      this.primengTableHelper.records = result;
      this.primengTableHelper.hideLoadingIndicator();
    });
  }
  deleteDivision(division: DivisionDto): void {
    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._divisionsServiceProxy.delete(division.id)
            .subscribe(() => {

              this.notify.success(this.l('SuccessfullyDeleted'));
              this.getDivisions();
            });
        }
      }
    );
  }

  getLocationses() {

    this.primengTableHelper.showLoadingIndicator();

    this._locationsesServiceProxy.getAllLocation(
    ).subscribe(result => {

      this.primengTableHelper.records = result
      this.primengTableHelper.hideLoadingIndicator();
    });
  }

  deleteLocations(locations: LocationsDto): void {
    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._locationsesServiceProxy.delete(locations.id)
            .subscribe(() => {

              this.notify.success(this.l('SuccessfullyDeleted'));
              this.getLocationses();
            });
        }
      }
    );
  }
  getLeaveTypes() {


    this.primengTableHelper.showLoadingIndicator();

    this._leaveTypesServiceProxy.getAllLeavetype(

    ).subscribe(result => {
      //console.log"leave rules:- " , result)
      this.primengTableHelper.records = result;
      this.primengTableHelper.hideLoadingIndicator();
    });
  }


  getLeaveTypesMethods() {


    this.primengTableHelper.showLoadingIndicator();

    this._leaveTypesServiceProxy.getAllLeavetypeMethod(

    ).subscribe(result => {
      //console.log"leave rules:- " , result)
      debugger
      this.primengTableHelper.records = result;
      this.primengTableHelper.hideLoadingIndicator();
    });
  }

  deleteLeaveTypes(leaveTypes: LeaveTypesDto): void {
    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._leaveTypesServiceProxy.delete(leaveTypes.id)
            .subscribe(() => {

              this.notify.success(this.l('SuccessfullyDeleted'));
              this.getLeaveTypes();
            });
        }
      }
    );
  }
  getDocumentType() {



    this.primengTableHelper.showLoadingIndicator();

    this._documentTypeServiceProxy.getAllDocumnetwizards(

    ).subscribe(result => {

      this.primengTableHelper.records = result;
      this.primengTableHelper.hideLoadingIndicator();
    });
  }
  deleteDocumentType(documentType: DocumentTypeDto): void {

    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._documentTypeServiceProxy.delete(documentType.id)
            .subscribe(() => {
              //
              this.notify.success(this.l('SuccessfullyDeleted'));
              this.getDocumentType();
            });
        }
      }
    );
  }
  getPayGrades() {


    this.primengTableHelper.showLoadingIndicator();

    this._payGradesServiceProxy.getAllPaygradedetails(

    ).subscribe(result => {

      this.primengTableHelper.records = result;
      this.primengTableHelper.hideLoadingIndicator();
    });
  }
  deletePayGrade(payGrade: PayGradeDto): void {
    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._payGradesServiceProxy.delete(payGrade.id)
            .subscribe(() => {

              this.notify.success(this.l('SuccessfullyDeleted'));
              this.getPayGrades();
            });
        }
      }
    );
  }
  getPayComponetses(event?: LazyLoadEvent) {


    this._payComponetsesServiceProxy.getAllPaycomponetWizards(

    ).subscribe(result => {

      this.primengTableHelper.records = result;
      this.primengTableHelper.hideLoadingIndicator();
    });
  }
  createPayComponets(): void {
    this.router.navigateByUrl('app/main/payComponets/createpaycomponets/0');
  }

  createOrEditPay(id): void {
    this.router.navigateByUrl('app/main/payComponets/createpaycomponets/' + id);
  }

  deletePayComponets(payComponets: PayComponetsDto): void {
    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._payComponetsesServiceProxy.delete(payComponets.id)
            .subscribe(() => {

              this.notify.success(this.l('SuccessfullyDeleted'));
              this.getPayComponetses();
            });
        }
      }
    );
  }

  getRoles(): void {
    this.primengTableHelper.showLoadingIndicator();
    let permission = this.permission ? this.selectedPermission : undefined;

    this._roleService.getRoles(permission)
      .pipe(finalize(() => this.primengTableHelper.hideLoadingIndicator()))
      .subscribe(result => {
        this.primengTableHelper.records = result.items;
        this.primengTableHelper.totalRecordsCount = result.items.length;
        this.primengTableHelper.hideLoadingIndicator();
      });
  }
  deleteRole(role: RoleListDto): void {
    let self = this;
    self.message.confirm(
      self.l('RoleDeleteWarningMessage', role.displayName),
      this.l('AreYouSure'),
      isConfirmed => {
        if (isConfirmed) {
          this._roleService.deleteRole(role.id).subscribe(() => {
            this.getRoles();
            abp.notify.success(this.l('SuccessfullyDeleted'));
          });
        }
      }
    );
  }



  getRequesrFlows() {


    this.primengTableHelper.showLoadingIndicator();

    this._requesrFlowsServiceProxy.getAllWorkFlowStreps(

    ).subscribe(result => {

      this.primengTableHelper.records = result;
      //console.log this.primengTableHelper.records);
      this.primengTableHelper.hideLoadingIndicator();
    });
  }

  getEmplolyeeCodeSettings() {

    this.primengTableHelper.showLoadingIndicator();

    this._emplolyeeCodeSettingsServiceProxy.getAllEmployeeTenant(


    ).subscribe(result => {

      this.primengTableHelper.records = result;
      this.primengTableHelper.hideLoadingIndicator();
    });
  }
  deleteEmplolyeeCodeSetting(emplolyeeCodeSetting: EmplolyeeCodeSettingDto): void {
    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._emplolyeeCodeSettingsServiceProxy.delete(emplolyeeCodeSetting.id)
            .subscribe(() => {

              this.notify.success(this.l('SuccessfullyDeleted'));
              this.getEmplolyeeCodeSettings();
            });
        }
      }
    );
  }
  getLeaveRules() {

    this.primengTableHelper.showLoadingIndicator();

    this._leaveRulesServiceProxy.getAllLeaveWizards(

    ).subscribe(result => {

      this.primengTableHelper.records = result;
      this.primengTableHelper.hideLoadingIndicator();
    });
  }
  deleteLeaveRule(leaveRule: LeaveRuleDto): void {
    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._leaveRulesServiceProxy.delete(leaveRule.id)
            .subscribe(() => {
              this.getLeaveRules();

              this.notify.success(this.l('SuccessfullyDeleted'));
            });
        }
      }
    );
  }
  ngOnInit() {

    this.active = true;
    this._employeeStateInsuranceServiceProxy.getEmployeeStateInsuranceForEdit().subscribe(result => {
      this.employeeStateInsurance = result.employeeStateInsurance;

      if (this.employeeStateInsurance == undefined) {
        this.enableSaveButton = true;
        this.enableUpdateButton = false;
        this.employeeStateInsurance = new CreateOrEditEmployeeStateInsuranceDto();
        this.employeeStateInsurance.applicable = true;
      }
      else {
        this.enableSaveButton = false;
        this.enableUpdateButton = true;
      }
      if (this.employeeStateInsurance.applicable != undefined) {
        if (this.employeeStateInsurance.applicable) {
          this.enableApplicable = false;
        }
        else {
          this.enableApplicable = true;
        }
      }
      this.active1 = true;
      this._providentFundSettingsServiceProxy.getProvidentFundSettingForEdit().subscribe(result => {
        this.providentFundSetting = result.providentFundSetting;

        if (this.providentFundSetting == undefined) {
          this.enableSaveButton = true;
          this.enableUpdateButton = false;
          this.providentFundSetting = new CreateOrEditProvidentFundSettingDto();
        }
        else {
          this.enableSaveButton = false;
          this.enableUpdateButton = true;
        }
        if (this.providentFundSetting.applicable) {
          this.enableApplicable = false;
          this.enableProperatePf = false;
          this.enableCalculateVPF = false;
          this.enableVPFRoundOff = false;
        }
        else {
          this.enableApplicable = true;
          this.enableProperatePf = true;
          this.enableCalculateVPF = true;
          this.enableVPFRoundOff = true;
        }
        if (this.providentFundSetting.proratepfAsperemployee) {
          this.enableProperatePf = false;
        }
        else {
          this.enableProperatePf = true;
        }
        if (this.providentFundSetting.voluntryprovidentfund) {
          this.enableCalculateVPF = false;
          this.enableVPFRoundOff = false;
        }
        else {
          this.enableCalculateVPF = true;
          this.enableVPFRoundOff = true;
        }

      });
      this.active = true;
    });
  }
  showESI(employeeStateInsuranceId?: number): void {
    if (!employeeStateInsuranceId) {
      this.employeeStateInsurance = new CreateOrEditEmployeeStateInsuranceDto();
      this.employeeStateInsurance.id = employeeStateInsuranceId;

      this.active = true;
      //this.modal.show();
    } else {
      this._employeeStateInsuranceServiceProxy.getEmployeeStateInsuranceForEdit().subscribe(result => {
        this.employeeStateInsurance = result.employeeStateInsurance;
        this.employeeStateInsurance.id = employeeStateInsuranceId;
        this.active = true;
        // this.modal.show();
      });
    }

  }
  save(): void {

    this.saving = true;
    this._employeeStateInsuranceServiceProxy.createOrEdit(this.employeeStateInsurance)
      .pipe(finalize(() => { this.saving = false; }))
      .subscribe(() => {
        this.notify.info(this.l('SavedSuccessfully'));
        this.enableSaveButton = false;
        this.enableUpdateButton = true;
        //this.close();
        this.modalSave.emit(null);
      });
  }
  restrictNegative(e: any): void {

    var inputKeyCode = e.keyCode ? e.keyCode : e.which;
    if (inputKeyCode != null) {
      if (inputKeyCode != 9) {
        if ((e.which != 46) && (e.which < 48 || e.which > 57)) e.preventDefault();
      }

    }
  }

  enableApplicables() {
    if (this.employeeStateInsurance.applicable) {
      this.enableApplicable = false;
    }
    else {
      this.enableApplicable = true;
    }
    if (this.providentFundSetting.applicable) {
      this.enableApplicable = false;
      this.enableProperatePf = false;
      this.enableCalculateVPF = false;
      this.enableVPFRoundOff = false;
      if (this.providentFundSetting.proratepfAsperemployee) {
        this.enableProperatePf = false;
      }
      else {
        this.enableProperatePf = true;
      }
      if (this.providentFundSetting.voluntryprovidentfund) {
        this.enableCalculateVPF = false;
        this.enableVPFRoundOff = false;
      }
      else {
        this.enableCalculateVPF = true;
        this.enableVPFRoundOff = true;
      }
    }
    else {
      this.enableApplicable = true;
      this.enableProperatePf = true;
      this.enableCalculateVPF = true;
      this.enableVPFRoundOff = true;
    }
  }
  getHoldiayPolicies() {

    this.primengTableHelper.showLoadingIndicator();

    this._holdiayPoliciesServiceProxy.getAllInstallPolice(

    ).subscribe(result => {

      this.primengTableHelper.records = result;
      //console.log"dashboard", this.primengTableHelper.records)
      this.primengTableHelper.hideLoadingIndicator();
    });
  }
  deleteHoldiayPolicy(holdiayPolicy: HoldiayPolicyDto): void {
    this.message.confirm(
      '',
      this.l('AreYouSure'),
      (isConfirmed) => {
        if (isConfirmed) {
          this._holdiayPoliciesServiceProxy.delete(holdiayPolicy.id)
            .subscribe(() => {

              this.notify.success(this.l('SuccessfullyDeleted'));
              this.getHoldiayPolicies();
            });
        }
      }
    );
  }

  showPF(providentFundSettingId?: number): void {
    if (!providentFundSettingId) {
      this.providentFundSetting = new CreateOrEditProvidentFundSettingDto();
      this.providentFundSetting.id = providentFundSettingId;

      this.active = true;
      //this.modal.show();
    } else {
      this._providentFundSettingsServiceProxy.getProvidentFundSettingForEdit().subscribe(result => {
        this.providentFundSetting = result.providentFundSetting;
        this.active = true;
        // this.modal.show();
      });
    }

  }

  savePF(): void {

    this.saving = true;
    this._providentFundSettingsServiceProxy.createOrEdit(this.providentFundSetting)
      .pipe(finalize(() => { this.saving = false; }))
      .subscribe(() => {
        this.notify.info(this.l('SavedSuccessfully'));
        //this.close();
        this.modalSave.emit(null);
      });
  }


  // enableApplicables()
  // {
  //   if(this.providentFundSetting.applicable)
  //   {
  //     this.enableApplicable=false;
  //     this.enableProperatePf=false;
  //     this.enableCalculateVPF=false;
  //     this.enableVPFRoundOff=false;
  //     if(this.providentFundSetting.proratepfAsperemployee)
  //     {
  //       this.enableProperatePf=false;
  //     }
  //     else{
  //       this.enableProperatePf=true;
  //     }
  //     if(this.providentFundSetting.voluntryprovidentfund)
  //     {
  //       this.enableCalculateVPF=false;
  //       this.enableVPFRoundOff=false;
  //     }
  //     else{
  //       this.enableCalculateVPF=true;
  //       this.enableVPFRoundOff=true;
  //     }
  //   }
  //   else
  //   {
  //     this.enableApplicable=true;
  //     this.enableProperatePf=true;
  //     this.enableCalculateVPF=true;
  //     this.enableVPFRoundOff=true;
  //   }

  // }
}

function create() {
  throw new Error('Function not implemented.');
}
