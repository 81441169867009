<div class="kt-footer kt-grid__item" id="kt_footer">
    <div class="kt-container" *ngIf="useBottomDiv">
        <div class="kt-footer__bottom">
            <div class="kt-footer__copyright">
                 <!-- {{appSession.tenant.edition.displayName}}-->
                PerfettoHr <span
                    *ngIf="appSession.tenant && appSession.tenant.edition && appSession.tenant.edition.displayName">
                   </span> | v{{appSession.application.version}} 
                [{{releaseDate}}] <b></b><span *ngIf="isMultiTenancyEnabled" class="tenancy-name">{{tenancyName}}</span>
            </div>
            <div class="kt-footer__menu">
            </div>
        </div>
    </div>
    <div class="kt-container" *ngIf="!useBottomDiv">
        <div class="kt-footer__wrapper">
            <div class="kt-footer__copyright">
                PerfettoHr <span
                    *ngIf="appSession.tenant && appSession.tenant.edition && appSession.tenant.edition.displayName">
                    </span> | v{{appSession.application.version}}
                [{{releaseDate}}]  <b>Tenancy Name:- </b><span *ngIf="isMultiTenancyEnabled" class="tenancy-name">{{tenancyName}}</span>
            </div>
            <div class="kt-footer__menu">
            </div>
        </div>
    </div>
</div>