import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CreateOrEditCountryModalComponent } from '@app/main/country/countries/create-or-edit-country-modal.component';
import { ViewCountryModalComponent } from '@app/main/country/countries/view-country-modal.component';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CountriesServiceProxy, TokenAuthServiceProxy, CountryDto } from '@shared/service-proxies/service-proxies';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { NotifyService } from 'abp-ng2-module/dist/src/notify/notify.service';
import { ModalDirective } from 'ngx-bootstrap';
import { LazyLoadEvent } from 'primeng/api';
import { Paginator } from 'primeng/primeng';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.component.html',
  styleUrls: ['./country-list.component.css']
})

export class CountryListComponent extends AppComponentBase {
    
  @ViewChild('createOrEditModal', {static:true }) modal: ModalDirective;

  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('createOrEditCountryModal', { static: true }) createOrEditCountryModal: CreateOrEditCountryModalComponent;
  @ViewChild('viewCountryModalComponent', { static: true }) viewCountryModal: ViewCountryModalComponent;   
  
  @ViewChild('dataTable', { static: true }) dataTable: Table;
  @ViewChild('paginator', { static: true }) paginator: Paginator;

  advancedFiltersAreShown = false;
  filterText = '';
  nameFilter = '';
  nationalityFilter = '';






  constructor(
      injector: Injector,
      private _countriesServiceProxy: CountriesServiceProxy,
      private _notifyService: NotifyService,
      private _tokenAuth: TokenAuthServiceProxy,
      private _activatedRoute: ActivatedRoute,
      private _fileDownloadService: FileDownloadService
  ) {
      super(injector);
  }
  ngOnInit() {
      
   // this.getCountries();
    this.modal.show();
  }
  show()
  {
      
      this.modal.show();
  }
  getCountries(event?: LazyLoadEvent) {
      if (this.primengTableHelper.shouldResetPaging(event)) {
          this.paginator.changePage(0);
          return;
      }

      this.primengTableHelper.showLoadingIndicator();

      this._countriesServiceProxy.getAll(
          this.filterText,
          this.nameFilter,
          this.nationalityFilter,
          this.primengTableHelper.getSorting(this.dataTable),
          this.primengTableHelper.getSkipCount(this.paginator, event),
          this.primengTableHelper.getMaxResultCount(this.paginator, event)
      ).subscribe(result => {
          this.primengTableHelper.totalRecordsCount = result.totalCount;
          this.primengTableHelper.records = result.items;
          this.primengTableHelper.hideLoadingIndicator();
      });
      this.modal.show();
  }

  reloadPage(): void {
      this.paginator.changePage(this.paginator.getPage());
  }

  createCountry(): void {
      this.createOrEditCountryModal.show();        
  }


  deleteCountry(country: CountryDto): void {
      this.message.confirm(
          '',
          this.l('AreYouSure'),
          (isConfirmed) => {
              if (isConfirmed) {
                  this._countriesServiceProxy.delete(country.id)
                      .subscribe(() => {
                          this.reloadPage();
                          this.notify.success(this.l('SuccessfullyDeleted'));
                      });
              }
          }
      );
  }

  exportToExcel(): void {
      this._countriesServiceProxy.getCountriesToExcel(
      this.filterText,
          this.nameFilter,
          this.nationalityFilter,
      )
      .subscribe(result => {
          this._fileDownloadService.downloadTempFile(result);
       });
  }
  
  
  
}
