import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { GetDepartmentsForViewDto, DepartmentsDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'viewDepartmentsModal',
    templateUrl: './view-departments-modal.component.html'
})
export class ViewDepartmentsModalComponent extends AppComponentBase {

    @ViewChild('createOrEditModal',{static:true}) modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;

    item: GetDepartmentsForViewDto;


    constructor(
        injector: Injector
    ) {
        super(injector);
        this.item = new GetDepartmentsForViewDto();
        this.item.departments = new DepartmentsDto();
    }

    show(item: GetDepartmentsForViewDto): void {
        this.item = item;
        this.active = true;
        this.modal.show();
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }
}
