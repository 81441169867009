<div bsModal #createOrEditModal="bs-modal" class="modal fade"  tabindex="-1" role="dialog" aria-labelledby="createOrEditModal" aria-hidden="true" >
    <div class="modal-dialog modal-lg" style="width: 390px;">
        <div class="modal-content">
            <!--" -->
            <form  #leaveRequestsForm="ngForm" novalidate (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header">
                    <h4 class="modal-title">
                        <span>{{title}}</span>
                    </h4>
                    <button type="button" class="close" (click)="close()" aria-label="Close" [disabled]="saving">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                 <div class="row">
<div class="col-md-12">

								<div class="form-group" style="display: none;">
                                    <!-- <label for="LeaveRequests_LeaveTypeId">{{("testvalue")}}</label> -->
                                    Punch Time: <label>{{today}}</label>
								</div>
							</div>
                            </div>
                            <div class="row">
                                                            <div class="col-md-12">		
                                                                <div class="form-group">
                                                                    <label for="LeaveRequests_LeaveFrom">{{l("First punch of the day will be considered as check-in and last as check-out")}}</label>
                                                                </div>
                                                            </div>
                                                            </div>
                </div>
                <div class="modal-footer">
                    <button [disabled]="saving" type="button" class="btn btn-default" (click)="close()">{{l("Cancel")}}</button>
                    <button type="submit" class="btn btn-primary blue" [disabled]="!leaveRequestsForm.form.valid" [buttonBusy]="saving" [busyText]="l('SavingWithThreeDot')"><i class="fa fa-save"></i> <span>{{l("Ok")}}</span></button>
                </div>
            </form>
        </div>
    </div>

</div>
