import {
    Component,
    EventEmitter,
    Injector,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { TopBarComponent } from "@app/shared/layout/topbar.component";
import { AppComponentBase } from "@shared/common/app-component-base";
import {
    CreateOrEditReviewStatus,
    CreateOrEditReviewStatusDetails,
    DropDown,
    DropdownServicesServiceProxy,
    GetCreateOrEditReviewedData,
    GetCreateOrEditReviewedEmployeeData,
    GetCriteriaData,
    GetReviewDataForViewPopUp,
    ReviewCycleDropDown,
    ReviewStatusServiceProxy,
} from "@shared/service-proxies/service-proxies";
import * as moment from "moment";
import { ModalDirective } from "ngx-bootstrap";
import { finalize } from "rxjs/operators";

@Component({
    selector: "app-crate-or-edit-employee-review-pop-up",
    templateUrl: "./crate-or-edit-employee-review-pop-up.component.html",
    styleUrls: ["./crate-or-edit-employee-review-pop-up.component.css"],
})
export class CrateOrEditEmployeeReviewPopUpComponent extends AppComponentBase {
    @ViewChild("createOrEditModal", { static: true }) modal: ModalDirective;
    @ViewChild("topbarModal", { static: true }) topbarModal: TopBarComponent;
    @ViewChild("viewCriteria", { static: true }) modalnew: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;
    submitting = false;
    reviewStatus: CreateOrEditReviewStatus = new CreateOrEditReviewStatus();
    employeeReviewDetails: GetCreateOrEditReviewedEmployeeData[];
    reviewedData: GetReviewDataForViewPopUp[];
    //FeedbackData : DropDown[];
    feedbackid: number[] = [];
    reviewID: number;
    flag: number = 0;
    employeeName: string;
    fromDate: any;
    toDate: any;
    cyclename: string;
    tempFeedbackid: number[] = [];
    public FeedbackData: any = [
        { label: "Select", value: 0 },
        { label: "Poor", value: 1 },
        { label: "Average", value: 2 },
        { label: "Good", value: 3 },
        { label: "Very Good", value: 4 },
        { label: "Excellent", value: 5 },
    ];
    criteriaData: GetCriteriaData[];
    viewCriteria: boolean = false;
    criteriaDataLength: number;
    policyName: string;
    reachedMaxvalue = true;
    enableDisable: boolean;
    timeperiod: number;
    datatable: boolean = false;
    employeeReviewCycle: any;
    employeeCycleData: ReviewCycleDropDown[];
    cyclevalue: string;
    showDate: boolean;
    ReviewStatusId: number;
    constructor(
        injector: Injector,
        private _reviewStatusServiceProxy: ReviewStatusServiceProxy,
        private _dropdownServiceProxy: DropdownServicesServiceProxy
    ) {
        super(injector);
    }

    // ngOnInit() {
    //   this.getEmployeeReviewCycleData();
    // }

    getEmployeeReviewCycleData() {
        this._dropdownServiceProxy.getCycle(0).subscribe((data) => {
            this.employeeCycleData = data;
            let res = new ReviewCycleDropDown();
            res.label = "Select";
            res.value = "000000";
            this.employeeCycleData.unshift(res);
        });
    }

    getDate() {
        if (this.cyclevalue == "000000") {
            this.showDate = false;
            this.reachedMaxvalue = true;
            this.datatable = false;
            return;
        } else {
            this.showDate = true;
            this.reachedMaxvalue = false;
        }
        var data = this.employeeCycleData.find(
            (data) => data.value === this.cyclevalue
        );
        var cycle = data.label.substring(0, 2);
        if (cycle.toLowerCase() == "q1") {
            let monthYear = this.getMonthyear(data);
            this.fromDate = this.getFromDate(data.value);
            this.toDate = this.getToDate(monthYear);
            //console.log
            //     "From Date" + this.fromDate + " and To date " + this.toDate
            // );
        } else if (cycle.toLowerCase() == "q2") {
            let monthYear = this.getMonthyear(data);
            this.fromDate = this.getFromDate(data.value);
            this.toDate = this.getToDate(monthYear);
        } else if (cycle.toLowerCase() == "q3") {
            let monthYear = this.getMonthyear(data);
            this.fromDate = this.getFromDate(data.value);
            this.toDate = this.getToDate(monthYear);
        } else if (cycle.toLowerCase() == "q4") {
            let monthYear = this.getMonthyear(data);
            this.fromDate = this.getFromDate(data.value);
            this.toDate = this.getToDate(monthYear);
        } else if (cycle.toLowerCase() == "h1") {
            let monthYear = this.getMonthyear(data);
            this.fromDate = this.getFromDate(data.value);
            this.toDate = this.getToDate(monthYear);
        } else if (cycle.toLowerCase() == "h2") {
            let monthYear = this.getMonthyear(data);
            this.fromDate = this.getFromDate(data.value);
            this.toDate = this.getToDate(monthYear);
        } else if (cycle.toLowerCase() == "ye") {
            let monthYear = this.getMonthyear(data);
            this.fromDate = this.getFromDate(data.value);
            this.toDate = this.getToDate(monthYear);
        } else {
            let monthYear = this.getMonthyear(data);
            this.fromDate = this.getFromDate(data.value);
            this.toDate = this.getToDate(monthYear);
        }

        if (this.cyclevalue != "000000") {
            this.getReviewedDetails(this.ReviewStatusId);
            this.datatable = true;
        }
    }

    getFromDate(data: string): string {
        let month = parseInt(data.substring(0, 2));
        let year = parseInt(data.substring(2, 6));
        return moment(`${year}-${month}-01`).format("DD/MM/YYYY");
    }

    getToDate(data: string): string {
        let month = parseInt(data.substring(0, 2)) - 1;
        let year = parseInt(data.substring(2, 6));
        var date = moment([year, month - 1])
            .endOf("month")
            .format("DD/MM/YYYY");
        return date;
    }

    getMonthyear(data: ReviewCycleDropDown): string {
        let month = data.value.substring(0, 2);
        let year = data.value.substring(2, 6);
        let temp = 0;
        if (data.label.substring(0, 1).toLowerCase() == "q") {
            temp = parseInt(month) + 3;
        } else if (data.label.substring(0, 1).toLowerCase() == "h") {
            temp = parseInt(month) + 6;
        } else if (data.label.substring(0, 1).toLowerCase() == "y") {
            temp = parseInt(month) + 12;
        } else {
            temp = parseInt(month) + 1;
        }

        let updatedMonth = temp < 10 ? "0" + temp.toString() : temp.toString();
        let monthYear = updatedMonth + year;
        return monthYear;
    }

  show(reviewStatusId?: number): void {
    //this.filterFeedback();
    this.tempFeedbackid = [...this.feedbackid];
    this.reviewStatus = new CreateOrEditReviewStatus();
    this.getEmployeeReviewCycleData();
    if (reviewStatusId == undefined || reviewStatusId == null || reviewStatusId == 0) {
      reviewStatusId = 0;
      this.flag = 1;
      this.getReviewedDataofEmployeeDetails(reviewStatusId, this.flag);
      if(this.employeeReviewDetails[0].message != null){
        return this.notify.error(this.employeeReviewDetails[0].message);
      }
      this.ReviewStatusId = reviewStatusId;
      // this.getReviewedDetails(reviewStatusId);
      this.active = true;
      this.modal.show();
    }
    if (reviewStatusId != 0) {
      this._reviewStatusServiceProxy.checkEditable(reviewStatusId).subscribe(async result => {
        if (result != '') {
          this.notify.error(result);
          return;
        }
        else {
          this.flag = 0;
          await this.getReviewedDataofEmployeeDetails(reviewStatusId, this.flag);
          setTimeout(() => {
            this.getReviewedDetails(reviewStatusId);
          },2000);
          this.active = true;
          this.reachedMaxvalue = false;
          this.modal.show();
        }
      });
    }
  }

  getCountScoreValue(KpikraId: number, value: number, index: number): void {
    this._reviewStatusServiceProxy.getCountScore(KpikraId, value).subscribe(result => {
      this.reviewedData[index].maxValue = this.reviewedData[index].maxValue == null ? 0 : this.reviewedData[index].maxValue;
      if (value > this.reviewedData[index].maxValue) {
        this.notify.error('Can not go beyond Maximum Range');
        this.reachedMaxvalue = true;
        return;
      }
      else {
        this.reachedMaxvalue = false;
        this.reviewedData[index].getCreateOrEditReviewedData.selfAssessment = result;
        this.calculateNetValue(index);
      }

    });
  }

    getFeedbackScoreValue(
        KpikraId: number,
        value: number,
        index: number
    ): void {
        this._reviewStatusServiceProxy
            .getFeedbackScore(KpikraId, value)
            .subscribe((result) => {
                this.reviewedData[
                    index
                ].getCreateOrEditReviewedData.selfAssessment = result;
                //console.log"deefback", this.feedbackid);
                this.calculateNetValue(index);
            });
    }

  calculateNetValue(index: number) {
    const empfeedback = this.reviewedData[index].getCreateOrEditReviewedData.selfAssessment;
    const managerfeedback = 0;
    let totalValue = empfeedback + managerfeedback;
    this.reviewedData[index].getCreateOrEditReviewedData.netValue = totalValue / 2;
    // if (this.isemployeeReviewSubmitted == true) {
    //   this.reviewedData[index].getCreateOrEditReviewedData.netValue = totalValue / 2;
    // } else {
    // }
    this.calculateOverallScore(index);
  }

  calculateOverallScore(index: number) {
    const netValue = this.reviewedData[index].getCreateOrEditReviewedData.netValue;
    const weightagepercent = this.reviewedData[index].getCreateOrEditReviewedData.weightage / 100;
    this.reviewedData[index].getCreateOrEditReviewedData.scoreValue = netValue * weightagepercent;
  }

    onFeedbackChange(newValue: number, KpikraId: number, index: number): void {
        this._reviewStatusServiceProxy
            .getFeedbackScore(KpikraId, newValue)
            .subscribe((result) => {
                this.reviewedData[
                    index
                ].getCreateOrEditReviewedData.selfAssessment = result;
                this.feedbackid[index] = newValue; // Update the actual array after the result is received
                //console.log"deefback", this.feedbackid);
                this.reviewedData[
                    index
                ].getCreateOrEditReviewedData.employeeFeedback = newValue;
                this.calculateNetValue(index);
            });
    }
    getReviewedDetails(reviewStatusId?: number): void {
        this.reviewID = reviewStatusId;
        const fromDate: moment.Moment = moment(this.fromDate, "DD/MM/YYYY");
        const toDate: moment.Moment = moment(this.toDate, "DD/MM/YYYY");
        this._reviewStatusServiceProxy
            .getReviewedData(reviewStatusId, fromDate, toDate, 0, 0)
            .subscribe((result) => {
                this.reviewedData = result;
                if (result[0].cycleName != "000000") {
                    this.cyclevalue = result[0].cycleName;
                }
                if (
                    result[0].message ==
                    "Review is submitted so you cannot edit it."
                ) {
                    this.close();
                    return this.notify.error(result[0].message);
                }
                if (result.length != 0) {
                    if (
                        result[0].message !=
                        "Kindly contact to administrator to update the policy criteria"
                    ) {
                        this.datatable = true;
                        for (var i = 0; i < result.length; i++) {
                            if (
                                result[i].getCreateOrEditReviewedData
                                    .kraMeasurable == "F"
                            ) {
                                this.feedbackid[i] =
                                    result[
                                        i
                                    ].getCreateOrEditReviewedData.employeeFeedback;
                                this.tempFeedbackid[i] = this.feedbackid[i];
                            }
                        }
                    } else {
                        this.datatable = false;
                        this.reachedMaxvalue = true;
                        this.notify.error(result[0].message);
                        this.modal.hide();
                    }
                } else {
                    this.notify.error("Policy is not assigned");
                    this.modal.hide();
                }
            });
    }

    getReviewedDataofEmployeeDetails(
        reviewStatusId?: number,
        flag?: number
    ): void {
        this._reviewStatusServiceProxy
            .getReviewedEmployeeData(reviewStatusId, flag, 0)
            .subscribe((result) => {
                this.employeeReviewDetails = result;
                if (result.length != 0) {
                    this.employeeName = result[0].employeeName;
                    this.policyName = result[0].policyName;
                    this.timeperiod = result[0].timeperiod;
                    if (result[0].cycleName != "000000") {
                        this.cyclevalue = result[0].cycleName;
                        this.fromDate = moment(result[0].fromDate).format(
                            "DD-MM-YYYY"
                        );
                        this.toDate = moment(result[0].toDate).format(
                            "DD-MM-YYYY"
                        );
                        this.datatable = true;
                        this.showDate = true;
                        this.enableDisable = true;
                    } else {
                        this.datatable = false;
                        this.showDate = false;
                        this.enableDisable = false;
                    }
                }
            });
    }

    save(dat: number): void {
        if (dat == 1) {
            this.saving = true;
            this.reviewStatus.employeeStatus = "Save";
            // Assuming toDate and fromDate are strings in 'YYYY-MM-DD' format
            const toDateStr = this.toDate;
            const fromDateStr = this.fromDate;

            // Parse the strings into Moment objects
            const toDate: moment.Moment = moment(
                toDateStr,
                "DD-MM-YYYY"
            ).minutes(330);
            const fromDate: moment.Moment = moment(
                fromDateStr,
                "DD-MM-YYYY"
            ).minutes(330);

            // Now, you can assign them to your reviewStatus properties
            this.reviewStatus.period_From = fromDate;
            this.reviewStatus.period_To = toDate;
            this.reviewStatus.employeeId =
                this.reviewedData[0].getCreateOrEditReviewedData.employeeId;
            this.reviewStatus.fK_Policy_Id =
                this.reviewedData[0].getCreateOrEditReviewedData.policyId;
            this.reviewStatus.id = this.reviewID;
            var exitlist = new Array<CreateOrEditReviewStatusDetails>();

            for (var i = 0; i < this.reviewedData.length; i++) {
                let data = new CreateOrEditReviewStatusDetails();

        data.weightage = this.reviewedData[i].getCreateOrEditReviewedData.weightage;
        data.captured_Count = this.reviewedData[i].getCreateOrEditReviewedData.capturedData;
        data.employee_feedback = this.reviewedData[i].getCreateOrEditReviewedData.kraMeasurable !== 'F' ? this.reviewedData[i].getCreateOrEditReviewedData.employeeFeedback : this.tempFeedbackid[i];
        // data.employee_feedback = this.reviewedData[i].getCreateOrEditReviewedData.employeeFeedback;
        data.krA_Id = this.reviewedData[i].getCreateOrEditReviewedData.kraId;
        data.self_assessment = this.reviewedData[i].getCreateOrEditReviewedData.selfAssessment;
        data.manager_feedback = this.reviewedData[i].getCreateOrEditReviewedData.managerFeedback;
        data.l1_Assessment = this.reviewedData[i].getCreateOrEditReviewedData.l1Assessment;
        data.net = this.reviewedData[i].getCreateOrEditReviewedData.netValue;
        data.score = this.reviewedData[i].getCreateOrEditReviewedData.scoreValue;
        data.employeeRemark = this.reviewedData[i].getCreateOrEditReviewedData.employeeRemark;
        exitlist.push(data);
      }
      this.reviewStatus.reviewStatusDetails = exitlist;
      this.reviewStatus.cycleName = this.cyclevalue;
      this._reviewStatusServiceProxy.createOrEditForSelfReview(this.reviewStatus)
        .pipe(finalize(() => { this.saving = false; }))
        .subscribe(() => {
          // this.topbarModal.getButtonText();
          this.notify.info(this.l('SavedSuccessfully'));
        //   window.location.reload();
          this.close();
          this.modalSave.emit(null);
        });
    }
    else {
      this.submitting = true;
      this.message.confirm(
        '',
        this.l('Once you submit the review then you can not edit it.Are you sure?'),
        (isConfirmed) => {
          if (isConfirmed) {
            this.reviewStatus.employeeStatus = 'Submit';

                        // Assuming toDate and fromDate are strings in 'YYYY-MM-DD' format
                        const toDateStr = this.toDate;
                        const fromDateStr = this.fromDate;

                        // Parse the strings into Moment objects
                        const toDate: moment.Moment = moment(
                            toDateStr,
                            "DD-MM-YYYY"
                        ).minutes(330);
                        const fromDate: moment.Moment = moment(
                            fromDateStr,
                            "DD-MM-YYYY"
                        ).minutes(330);

                        // Now, you can assign them to your reviewStatus properties
                        this.reviewStatus.period_From = fromDate;
                        this.reviewStatus.period_To = toDate;
                        this.reviewStatus.employeeId =
                            this.reviewedData[0].getCreateOrEditReviewedData.employeeId;
                        this.reviewStatus.fK_Policy_Id =
                            this.reviewedData[0].getCreateOrEditReviewedData.policyId;
                        this.reviewStatus.id = this.reviewID;
                        var exitlist =
                            new Array<CreateOrEditReviewStatusDetails>();

                        for (var i = 0; i < this.reviewedData.length; i++) {
                            let data = new CreateOrEditReviewStatusDetails();

              data.weightage = this.reviewedData[i].getCreateOrEditReviewedData.weightage;
              data.captured_Count = this.reviewedData[i].getCreateOrEditReviewedData.capturedData;
              data.employee_feedback = this.reviewedData[i].getCreateOrEditReviewedData.kraMeasurable !== 'F' ? this.reviewedData[i].getCreateOrEditReviewedData.employeeFeedback : this.tempFeedbackid[i];
              // data.employee_feedback = this.reviewedData[i].getCreateOrEditReviewedData.employeeFeedback;
              data.krA_Id = this.reviewedData[i].getCreateOrEditReviewedData.kraId;
              data.self_assessment = this.reviewedData[i].getCreateOrEditReviewedData.selfAssessment;
              data.employeeRemark = this.reviewedData[i].getCreateOrEditReviewedData.employeeRemark;
              exitlist.push(data);
            }
            this.reviewStatus.reviewStatusDetails = exitlist;
            this.reviewStatus.cycleName = this.cyclevalue;
            this._reviewStatusServiceProxy.createOrEditForSelfReview(this.reviewStatus)
              .pipe(finalize(() => { this.saving = false; }))
              .subscribe(() => {
                //this.topbarModal.getButtonText();
                this.notify.info(this.l('SavedSuccessfully'));
                // window.location.reload();
                this.close();
                this.modalSave.emit(null);
              });
          } else {
            // this.notify.info('Review submission canceled');
            // this.close();
            // this.modalSave.emit(null);
            this.submitting = false;
          }
        }
      );
    }
  }
  // filterFeedback() {
  //   this._dropdownServiceProxy.getFeedbackData().subscribe(Customer => {
  //     this.FeedbackData = Customer;
  //   })
  // }

    showCriteria(data: any) {
        this._reviewStatusServiceProxy
            .getCriteriaDataForFrontend(data)
            .subscribe((result) => {
                this.criteriaData = result;
                if (
                    this.criteriaData[0].countValue != null &&
                    this.criteriaData[0].countValue != undefined
                ) {
                    this.enableDisable = true;
                } else {
                    this.enableDisable = false;
                }
                //console.log"Criteria " + this.criteriaData);
                this.criteriaDataLength = this.criteriaData.length;
                this.viewCriteria = true;
                this.modalnew.show();
            });
    }

    closedCriteria() {
        this.modalnew.hide();
        this.criteriaData = [];
    }

    close(): void {
        this.reachedMaxvalue = true;
        this.datatable = false;
        this.cyclevalue = "000000";
        this.active = false;
        this.modal.hide();
        this.reviewedData = [];
        this.employeeName = "";
        this.employeeReviewDetails = [];
        this.cyclename = "";
        this.policyName = "";
        this.showDate = false;
    }
}
