import { Component, Injector, OnInit, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AbpMultiTenancyService } from 'abp-ng2-module/dist/src/multi-tenancy/abp-multi-tenancy.service';

@Component({
    templateUrl: './footer.component.html',
    selector: 'footer-bar'
})
export class FooterComponent extends AppComponentBase implements OnInit {

    releaseDate: string;
    @Input() useBottomDiv = true;
    tenancyName = '';
    isMultiTenancyEnabled = false;

    constructor(
        injector: Injector,
        private _abpMultiTenancyService: AbpMultiTenancyService,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        
        this.releaseDate = this.appSession.application.releaseDate.format('YYYY-MM-DD');
        this.isMultiTenancyEnabled = this._abpMultiTenancyService.isEnabled;
        this.tenancyName = this.appSession.tenancyName;
        
    }
}
