import { Component, ViewChild, Injector, Output, EventEmitter} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { HolidaiesServiceProxy, CreateOrEditHolidayDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';
import { CreateOrEditHolidaypolicyDetailsModalComponent } from '@app/main/holidaypolicyDetails/holidaypolicyDetailses/create-or-edit-holidaypolicyDetails-modal.component';

@Component({
    selector: 'createOrEditHolidayModal',
    templateUrl: './create-or-edit-holiday-modal.component.html'
})
export class CreateOrEditHolidayModalComponent extends AppComponentBase {
   
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() reload: EventEmitter<any> = new EventEmitter<any>();
    active = false;
    saving = false;

    holiday: CreateOrEditHolidayDto = new CreateOrEditHolidayDto();



    constructor(
        injector: Injector,
        private _holidaiesServiceProxy: HolidaiesServiceProxy
    ) {
        super(injector);
    }
    
    onShown(): void {

        document.getElementById('Holiday_HolidayName').focus();
    }
    show(holidayId?: number): void {
       

        if (!holidayId) {
            this.holiday = new CreateOrEditHolidayDto();
            this.holiday.id = holidayId;

            this.active = true;
            this.modal.show();
        } else {
            this._holidaiesServiceProxy.getHolidayForEdit(holidayId).subscribe(result => {
                this.holiday = result.holiday;


                this.active = true;
                this.modal.show();
            });
        }
        
    }

    save(): void {
       
            this.saving = true;

			
			
            this._holidaiesServiceProxy.createOrEdit(this.holiday)
             .pipe(finalize(() => { this.saving = false;}))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.reload.emit(null);
                this.modalSave.emit(null);
             });
    }







    close(): void {
        this.active = false;
        this.modal.hide();
    }
}
