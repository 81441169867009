import { Component, ViewChild, Injector, Output, EventEmitter} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { HoldiayPoliciesServiceProxy, CreateOrEditHoldiayPolicyDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';

@Component({
    selector: 'createOrEditHoldiayPolicyModal',
    templateUrl: './create-or-edit-holdiayPolicy-modal.component.html'
})
export class CreateOrEditHoldiayPolicyModalComponent extends AppComponentBase {
   
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;
    Holiday:boolean;
    holdiayPolicy: CreateOrEditHoldiayPolicyDto = new CreateOrEditHoldiayPolicyDto();



    constructor(
        injector: Injector,
        private _holdiayPoliciesServiceProxy: HoldiayPoliciesServiceProxy
    ) {
        super(injector);
    }
    onShown(): void {

        document.getElementById('HoldiayPolicy_Name').focus();
    }
    show(holdiayPolicyId?: number): void {
   
         
        if (!holdiayPolicyId) {
            this.holdiayPolicy = new CreateOrEditHoldiayPolicyDto();
            this.holdiayPolicy.id = holdiayPolicyId;
          this.Holiday=false;
            this.active = true;
            this.modal.show();
        } else {
            this.Holiday=true;
            this._holdiayPoliciesServiceProxy.getHoldiayPolicyForEdit(holdiayPolicyId).subscribe(result => {
                this.holdiayPolicy = result.holdiayPolicy;


                this.active = true;
                this.modal.show();
            });
        }
        
    }

    save(): void {
       
            this.saving = true;

			
			
            this._holdiayPoliciesServiceProxy.createOrEdit(this.holdiayPolicy)
             .pipe(finalize(() => { this.saving = false;}))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
             });
    }







    close(): void {
        this.active = false;
        this.modal.hide();
    }
}
