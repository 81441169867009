import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ExpensePoliciesDto, ExpensePolicyServiceProxy, GetExpensePolicyForViewDto } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';


@Component({
  selector: 'viewexpensepolicymodal',
  templateUrl: './viewexpensepolicy.component.html',
  styleUrls: ['./viewexpensepolicy.component.css']
})
export class ViewexpensepolicyComponent extends AppComponentBase {
  @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  active = false;
  saving = false;

  item: GetExpensePolicyForViewDto;
  constructor(
    injector: Injector,
    private _expenseServiceProxy: ExpensePolicyServiceProxy
) {
    super(injector);
    this.item = new GetExpensePolicyForViewDto;
    this.item.expensePolicyes = new ExpensePoliciesDto();
}
show(data : number): void {
  this._expenseServiceProxy.getExpensePolicyForView(data).subscribe(result =>{
    this.item.expenseNames = result.expenseNames;
    this.item.expensePolicyes= result.expensePolicyes;
  });
  this.active = true;
  this.modal.show();
}

close(): void {
  this.active = false;
  this.modal.hide();
}

}
