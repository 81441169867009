import { Component, ViewChild, Injector, Output, EventEmitter} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { PayComponetsesServiceProxy, CreateOrEditPayComponetsDto, PaymentTypeList } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
//import * as moment from 'moment';

@Component({
    selector: 'createOrEditPayComponetsModal',
    templateUrl: './create-or-edit-payComponets-modal.component.html'
})
export class CreateOrEditPayComponetsModalComponent extends AppComponentBase {
   
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;
  textablehide=false;
    payComponets: CreateOrEditPayComponetsDto = new CreateOrEditPayComponetsDto();



    constructor(
        injector: Injector,
        private _payComponetsesServiceProxy: PayComponetsesServiceProxy,
        private router:Router,
        private route:ActivatedRoute,
    ) {
        super(injector);
    }
    onShown(): void {

        document.getElementById('PayComponets_Name').focus();
    }
    
    
    public BoolDropdownDouct: any = [
        { label: 'Select', value: undefined},
        { label: 'Propotionately', value: 1 },
        { label: 'Pay Month', value: 0},
      ];
      public BoolDropdownValue: any = [
        { label: 'Select', value: undefined},
        { label: 'Yes', value: 1 },
        { label: 'No', value: 0},
      ];

      public BoolDropdownValueForm16: any = [
        { label: 'Select', value: undefined},
        { label: 'Section 10', value: 1 },
        { label: 'Section 16', value: 2 },
        { label: 'Section 17(2)', value: 3 },
        { label: 'Section 17(3)', value: 4 },
        { label: 'Section 10(5)', value: 5 },
        { label: 'Section 10(10)', value: 6 },
        { label: 'Section 10(10A)', value: 7 },
        { label: 'Section 10(10AA)', value: 8 },
        { label: 'Section 10(13A)', value: 9 },
        { label: 'Include without bill amount in income', value: 10 },
      ];

      public PaymentTypeList: any = [
        { label: 'Select', value: undefined},
        { label: 'Allowances', value: 1 },
        { label: 'Deduction', value: 2},
        { label: 'Fixed', value: 3},
        { label: 'Advance', value: 4},
        {label:  'Other', value: 5},
        {label:  'Reimbursable', value: 6},
        {label:  'Reimbursement ', value: 7}
      ];
    
      public TextableList: any = [
        { label: 'Select', value: undefined},
        { label: 'Taxable', value: 1 },
        { label: 'Non-Taxable', value: 0},
        { label: 'Partial Taxable', value: 2},
      ];


      public CalcuationList:any=[
        { label: 'Select', value: undefined},
          { label:'AMOUNT',value:1},
            { label:'SIMPLE FLAT',value:2},
            {label:'REDUCING BALANCE' ,value:3},
            {label:'REDUCING BALANCE (INSTALMENT + INTEREST)',value:4}
      ]

public RoundingList:any=[
  { label: 'Select', value: undefined},
    {label:'None',value:1},
    {label:'50 Paise',value:2},
    {label:'1 Rupees',value:3}

]
    ngOnInit():void{
 
        this.route.params.subscribe(param=>{
            if(param.id!=undefined && param.id!='' && param.id!=0)
            {
                
                this.show(param.id);
            }
            if(param.id==0)
            { 
                
                this.show();
            }
        });
    
    }
    show(payComponetsId?: number): void {
    

        if (!payComponetsId) {
            this.payComponets = new CreateOrEditPayComponetsDto();
            this.payComponets.id = payComponetsId;
//this.TextableHideShow();
            this.active = true;
           // this.modal.show();
        } else {
            this._payComponetsesServiceProxy.getPayComponetsForEdit(payComponetsId).subscribe(result => {
                this.payComponets = result.payComponets;

                this.TextableHideShow();
                this.active = true;
                this.modal.show();
            });
        }
        
    }

    save(): void {
        
            this.saving = true;
            //console.log"PayComponents",this.payComponets)
            this._payComponetsesServiceProxy.createOrEdit(this.payComponets)
             .pipe(finalize(() => { this.saving = false;}))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
             });
    }

    TextableHideShow()
    {
        
        if(this.payComponets.taxable==0)
        {
this.textablehide=false;
        }
        else{
            this.textablehide=true; 
        }
    }






    close(): void {
        this.router.navigateByUrl('app/main/payComponets/payComponetses');
    }
}
