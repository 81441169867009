<div id="kt_aside_brand" class="kt-aside__brand kt-grid__item">
    <div class="kt-aside__brand-logo">
        <a routerLink="/" target="_blank">
            <img *ngIf="!appSession.tenant || !appSession.tenant.logoId" [src]="defaultLogo" alt="logo" height="38" />
            <img *ngIf="appSession.tenant && appSession.tenant.logoId" [src]="remoteServiceBaseUrl + '/TenantCustomization/GetTenantLogo?skin=' + currentTheme.baseSettings.menu.asideSkin + '&tenantId=' + appSession.tenant.id + '&id=' + appSession.tenant.logoId" alt="logo" height="38" />
        </a>
    </div>
    <div class="kt-aside__brand-tools">
        <div class="kt-aside__brand-tools">
            <button class="kt-aside__brand-aside-toggler" id="kt_aside_toggler">
                <span></span>
            </button>
        </div>
    </div>
</div>
