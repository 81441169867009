import { Injector, Component, OnInit, Inject, ViewChild } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { ThemesLayoutBaseComponent } from '@app/shared/layout/themes/themes-layout-base.component';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { DOCUMENT } from '@angular/common';
import { OffcanvasOptions } from '@metronic/app/core/_base/layout/directives/offcanvas.directive';
import { PupUpMarkattendanceComponent } from '@app/main/markattendance/pup-up-markattendance/pup-up-markattendance.component';
import { Router } from '@angular/router';
import { WizardsComponent } from '@app/main/installationwizards/wizards/wizards.component';

@Component({
    templateUrl: './default-layout.component.html',
    selector: 'default-layout',
    animations: [appModuleAnimation()]
})
export class DefaultLayoutComponent extends ThemesLayoutBaseComponent implements OnInit {
     @ViewChild('wizardscomponent', { static: true }) wizardscomponent: WizardsComponent;
    menuCanvasOptions: OffcanvasOptions = {
        baseClass: 'kt-aside',
        overlay: true,
        closeBy: 'kt_aside_close_btn',
        toggleBy: {
            target: 'kt_aside_mobile_toggler',
            state: 'kt-header-mobile__toolbar-toggler--active'
        }
    };

    constructor(
        injector: Injector,
        @Inject(DOCUMENT) private document: Document,
         private router:Router
    ) {
        super(injector);
    
    }

    ngOnInit() {
        this.installationMode = UrlHelper.isInstallUrl(location.href);
    }
    createMarkAttendance(): void {
       
        this.wizardscomponent.show();        
    }
    MyProfilePicture()
    {
   
        this.router.navigateByUrl('app/main/myprofileemployee/myprofileemployee/'+0); 
    }
    
}
