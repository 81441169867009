import { Component, ViewChild, Injector, Output, EventEmitter} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { StatesServiceProxy, CreateOrEditStateDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';
import { StateCountryLookupTableModalComponent } from './state-country-lookup-table-modal.component';

@Component({
    selector: 'createOrEditStateModal',
    templateUrl: './create-or-edit-state-modal.component.html'
})
export class CreateOrEditStateModalComponent extends AppComponentBase {
   
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @ViewChild('stateCountryLookupTableModal', { static: true }) stateCountryLookupTableModal: StateCountryLookupTableModalComponent;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;

    state: CreateOrEditStateDto = new CreateOrEditStateDto();

    countryName = '';


    constructor(
        injector: Injector,
        private _statesServiceProxy: StatesServiceProxy
    ) {
        super(injector);
    }
    onShown(): void {

        document.getElementById('State_Name').focus();
    }
    
    show(stateId?: number): void {
    

        if (!stateId) {
            this.state = new CreateOrEditStateDto();
            this.state.id = stateId;
            this.countryName = '';

            this.active = true;
            this.modal.show();
        } else {
            this._statesServiceProxy.getStateForEdit(stateId).subscribe(result => {
                this.state = result.state;

                this.countryName = result.countryName;

                this.active = true;
                this.modal.show();
            });
        }
        
    }

    save(): void {
            this.saving = true;

			
			
            this._statesServiceProxy.createOrEdit(this.state)
             .pipe(finalize(() => { this.saving = false;}))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
             });
    }

    openSelectCountryModal() {
        this.stateCountryLookupTableModal.id = this.state.countryId;
        this.stateCountryLookupTableModal.displayName = this.countryName;
        this.stateCountryLookupTableModal.show();
    }


    setCountryIdNull() {
        this.state.countryId = null;
        this.countryName = '';
    }


    getNewCountryId() {
        this.state.countryId = this.stateCountryLookupTableModal.id;
        this.countryName = this.stateCountryLookupTableModal.displayName;
        this.state.name='';
    }


    close(): void {
        this.active = false;
        this.modal.hide();
    }
}
