import { Component, ViewChild, Injector, Output, EventEmitter, ElementRef} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { LeaveTypesServiceProxy, CreateOrEditLeaveTypesDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';
import { forEach } from 'lodash';

@Component({
    selector: 'createOrEditLeaveTypesModal',
    templateUrl: './create-or-edit-leaveTypes-modal.component.html'
})
export class CreateOrEditLeaveTypesModalComponent extends AppComponentBase {
   
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @ViewChild('myInput',{static:true}) myInput: ElementRef; 
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;
    LeaveType:boolean;
    leaveTypes: CreateOrEditLeaveTypesDto = new CreateOrEditLeaveTypesDto();
    type:string;
    public ReservedType: any = [
        { value: 'A' },
        ,{ value:'NA'},
        { value:'P'},
        { value:'HO'},
        { value:'HL'},
        { value:'OD'},
        { value:'RD'},
        { value:'WOU'},
        { value:'LOP'},
        { value:'FD'},
        { value:'UFD'}
      
      ];

    constructor(
        injector: Injector,
        private _leaveTypesServiceProxy: LeaveTypesServiceProxy
    ) {
        super(injector);
    }
  
  
    onShown():void
    {
        document.getElementById('LeaveTypes_LeaveType').focus();
    }
    show(leaveTypesId?: number): void {
    

        if (!leaveTypesId) {
            this.leaveTypes = new CreateOrEditLeaveTypesDto();
            this.leaveTypes.id = leaveTypesId;
          this.LeaveType=false;
            this.active = true;
            this.modal.show();
        } else {
            this.LeaveType=true;
            this._leaveTypesServiceProxy.getLeaveTypesForEdit(leaveTypesId).subscribe(result => {
                this.leaveTypes = result.leaveTypes;


                this.active = true;
                this.modal.show();
            });
        }
        
    }

    CheckReservedType()
    {
        
        this.type=this.leaveTypes.leaveType.toUpperCase()
        for( var i in this.ReservedType)
        {
            if(this.type==this.ReservedType[i].value)
            {
               
                this.notify.info("Do Not Used Reserved Type");
                this.leaveTypes.leaveType='';            
            }
        }
    }
    save(): void {
            this.saving = true;
         
			
			
            this._leaveTypesServiceProxy.createOrEdit(this.leaveTypes)
             .pipe(finalize(() => { this.saving = false;}))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
             });
    }







    close(): void {
        this.active = false;
        this.modal.hide();
    }
}
