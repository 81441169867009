import { Component, ViewChild, Injector, Output, EventEmitter, Renderer2} from '@angular/core';
import { ModalDirective, YearPickerComponent } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { HolidaypolicyDetailsesServiceProxy, CreateOrEditHolidaypolicyDetailsDto, HolidayDetails, GetHolidaypolicyDetailsForEditOutput } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';
import { CreateOrEditHolidayModalComponent } from '@app/main/holiday/holidaies/create-or-edit-holiday-modal.component';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { Paginator } from 'primeng/primeng';

@Component({
    selector: 'createOrEditHolidaypolicyDetailsModal',
    templateUrl: './create-or-edit-holidaypolicyDetails-modal.component.html'
})
export class CreateOrEditHolidaypolicyDetailsModalComponent extends AppComponentBase {
   
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    
    @ViewChild('dataTable', { static: true }) dataTable: Table;
    @ViewChild('paginator', { static: true }) paginator: Paginator;
    active = false;
    saving = false;
    filterText:string;
    NoofDays:number;
    counts:number=0;
    Fincanceyear=moment().dayOfYear;
    @ViewChild('createOrEditHolidayModal', { static: true }) createOrEditHolidayModal: CreateOrEditHolidayModalComponent;
    holidaypolicyDetails: CreateOrEditHolidaypolicyDetailsDto = new CreateOrEditHolidaypolicyDetailsDto();
     PolicyName:string;
     PolicyNameDisable:boolean;
     HolidaypolicyId:number = 0;
    holidaydetails:HolidayDetails[];
    selectedholiday:any;
Holodaydetailslist:Array<GetHolidaypolicyDetailsForEditOutput>;
date:any;
    constructor(
        injector: Injector,
        private _holidaypolicyDetailsesServiceProxy: HolidaypolicyDetailsesServiceProxy,
       private render:Renderer2
    ) {
        super(injector);
        (moment as any).fn.toString = function() { return this.format('DD/MM/YYYY'); };
    }
    ngOnInit():void{
       // this.holidaydetails=new Array<HolidayDetails>();
       this.holidaypolicyDetails.financialYear=new Date().getFullYear();
       
    }

    ngAfterViewInit():void{
        this.getHolidaypolicy();
    }
//     oncheckbox(event:any){
//         
//         const isChecked = event.target.checked;
//         if(isChecked){
// //this.selectedholiday.push(selectedholidays);
// this.selectedholiday =  parseInt(event.target.value, 10);
// for (var i in this.primengTableHelper.records){
//     if(this.primengTableHelper.records[i].holidayDetails.holidayId==this.selectedholiday){
//         this._holidaypolicyDetailsesServiceProxy.getHolidaydate(this.selectedholiday).subscribe(result => {
//             this.date = result;
            
//                 });
//                 this.primengTableHelper.records[i].holidayDetails.holidayDate=this.date;
//                 this.date = undefined;
//     }
// }
    
//         }
        
// else{
//     for (var i in this.primengTableHelper.records){
//         if(this.primengTableHelper.records[i].holidayDetails.holidayId==this.selectedholiday){
//                     this.primengTableHelper.records[i].holidayDetails.holidayDate=undefined
//         }
//     }
// }
//     }
async oncheckbox(event: any) {
    
    const isChecked = event.target.checked;
    if (isChecked) {
      this.selectedholiday = parseInt(event.target.value, 10);
      for (var i in this.primengTableHelper.records) {
        if (this.primengTableHelper.records[i].holidayDetails.holidayId == this.selectedholiday) {
          try {
            const result = await this.getHolidayDate(this.selectedholiday);
            this.primengTableHelper.records[i].holidayDetails.holidayDate = result;
          } catch (error) {
            console.error("Error fetching holiday date: ", error);
          }
        }
      }
    } else {
      for (var i in this.primengTableHelper.records) {
        if (this.primengTableHelper.records[i].holidayDetails.holidayId == this.selectedholiday) {
          this.primengTableHelper.records[i].holidayDetails.holidayDate = undefined;
        }
      }
    }
  }
  
  getHolidayDate(holidayId: number): Promise<any> {
    return this._holidaypolicyDetailsesServiceProxy.getHolidaydate(holidayId).toPromise();
  }    
    show(holidaypolicyDetailsId?: number,Ploicy?:string,NoOFDays?:number): void {
    
        this.NoofDays=NoOFDays;
            this.holidaypolicyDetails = new CreateOrEditHolidaypolicyDetailsDto();
           this.HolidaypolicyId = holidaypolicyDetailsId;
            this.holidaypolicyDetails.holidayDate = moment().startOf('day');
            this.PolicyName=Ploicy;
            this.active = true;
        this.holidaypolicyDetails.financialYear=new Date().getFullYear();
            this.PolicyNameDisable=true;
            this.getHolidaypolicy();
            this.modal.show();

    }
    Refresh()
    {
        this.getHolidaypolicy();
    }
    // createHoliday(): void {
    //     this.createOrEditHolidayModal.show();        
    // }
    save(): void {
    
            this.saving = true;
this.holidaypolicyDetails.holidayPolicy=this.HolidaypolicyId;
            //console.log"list",this.primengTableHelper.records);
           this.holidaydetails=new Array<HolidayDetails>()
            for(var i in this.primengTableHelper.records)
            {
                let item=new HolidayDetails();
                if(this.primengTableHelper.records[i].holidayDetails.applicable)
                {
                    if(this.primengTableHelper.records[i].holidayDetails.holidayDate==null)
                    {
                        this.notify.error("Please select holiday date of "+this.primengTableHelper.records[i].holidayDetails.holidayName);
                        this.saving=false;
                        return;
                    }
                }
            
                if(this.primengTableHelper.records[i].holidayDetails.holidayDate!=null)
                {
                    item.holidayDate=moment(this.primengTableHelper.records[i].holidayDetails.holidayDate).add().minutes(330);
                }
                item.holidayId=this.primengTableHelper.records[i].holidayDetails.holidayId;
                item.applicable=this.primengTableHelper.records[i].holidayDetails.applicable;
                item.nationalHoliday=this.primengTableHelper.records[i].holidayDetails.nationalHoliday;
                item.reservedHoliday=this.primengTableHelper.records[i].holidayDetails.reservedHoliday;
                this.holidaydetails.push(item);
            }
        
           let count=0;
           this.counts=0;
           for(var i in this.holidaydetails)
           {
               if(this.holidaydetails[i].applicable==true)
               {
                this.counts=this.counts+1;
                
               }
           }
//console.log"count chek",this.counts);
           if(this.counts>this.NoofDays)
           {
               this.notify.info("Restricted holiday limit exceeded");
               this.saving=false;
               return;
           }
            this.holidaypolicyDetails.holidayDetails=this.holidaydetails;

            this._holidaypolicyDetailsesServiceProxy.createOrEdit(this.holidaypolicyDetails)
             .pipe(finalize(() => { this.saving = false; this._holidaypolicyDetailsesServiceProxy.sPupate().subscribe(()=>{})}))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                //this.reload.emit(null);
                this.modalSave.emit(null);
             });
    }

    getHolidaypolicy() {
    
      //this.filterText=this.HolidaypolicyId.toString();
       
        this.primengTableHelper.showLoadingIndicator();

        this._holidaypolicyDetailsesServiceProxy.getAllHolidays(
            this.HolidaypolicyId.toString()
            // this.primengTableHelper.getSorting(this.dataTable),
            // this.primengTableHelper.getSkipCount(this.paginator),
            // this.primengTableHelper.getMaxResultCount(this.paginator,event)
        ).subscribe(result => {
            this.primengTableHelper.totalRecordsCount = result.totalCount;
        
            for(var i in result.items)
            {
            
                if(result.items[i].holidayDetails.holidayDate!=undefined)
                {
                    if(result.items[i].holidayDetails.holidayId==this.selectedholiday){
                        result.items[i].holidayDetails.holidayDate = this.date;
                    }
                    else{
                        result.items[i].holidayDetails.holidayDate=result.items[i].holidayDetails.holidayDate.add().hours(3); 
                    }
                    
                }
            }
            undefined
            this.primengTableHelper.records = result.items;
            // for(var i in  this.primengTableHelper.records)
            // {
            //   
            //     if( this.primengTableHelper.records[i].holidaydetails.holidayDate!=undefined)
            //     {
            //         this.primengTableHelper.records[i].holidaydetails.holidayDate=this.primengTableHelper.records[i].holidaydetails.holidayDate.add().minutes(330); 
            //     }
            // }


            //console.log"this Details",this.primengTableHelper.records);
            //console.log"Result",result.items)
            this.primengTableHelper.hideLoadingIndicator();
        });
    }
   

    reloadPage(): void {
        this.paginator.changePage(this.paginator.getPage());
    }






    close(): void {
        this.active = false;
        this.modal.hide();
    }
}
