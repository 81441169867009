import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CreateOrEditPayGradeDetailsDto, CreateOrEditPayGradeDto, PayGradeDetailsesServiceProxy } from '@shared/service-proxies/service-proxies';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'app-pay-component-set-value',
  templateUrl: './pay-component-set-value.component.html',
  styleUrls: ['./pay-component-set-value.component.css']
})
export class PayComponentSetValueComponent extends AppComponentBase {
  @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;

  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

  active = false;
  saving = false;
  payGradeIds:number;
  payComponentIds:number;
  payComponentName:string;
  values:string;
  amount:string;
  payGradeDetailList:Array<CreateOrEditPayGradeDetailsDto>=new Array<CreateOrEditPayGradeDetailsDto>();
  payGradeDetail:CreateOrEditPayGradeDetailsDto=new CreateOrEditPayGradeDetailsDto();
  payDetail:CreateOrEditPayGradeDto=new CreateOrEditPayGradeDto();
  public DropdownValue: any = [
    { label: 'Amount', value: "A" },
    { label: 'Formula', value: "F"},
  ];
  constructor(
    injector: Injector,
    private _payGradeDetailsesServiceProxy: PayGradeDetailsesServiceProxy,
  ) {
    super(injector);
   }

   show(payComponentId?: number,payComponentName?: string,payGradeId?:number): void {
    
    this.saving = false;
    this.values="A";
    this.payComponentIds = payComponentId;
    this.payComponentName=payComponentName;
    this.payGradeIds=payGradeId;
    this.payDetail=new CreateOrEditPayGradeDto();
        this._payGradeDetailsesServiceProxy.payComponentSetValue(payGradeId,payComponentId).subscribe(result => {
            this.payDetail = result;
            if(this.payDetail.payGradeDetails.length>0)
            {
                this.values=result.payGradeDetails[0].calculationType;
                this.amount=result.payGradeDetails[0].amount.toString();
            }
            else
            {
              this.values="A";
              this.amount="0";
            }
            this.active = true;
            this.modal.show();
        });
        this.active = true;
        this.modal.show();
}

save(): void {
        this.saving = true;
        this.payGradeDetail=new CreateOrEditPayGradeDetailsDto();
        this.payGradeDetail.calculationType=this.values;
        this.payGradeDetail.amount=parseFloat(this.amount);
        this.payGradeDetail.formulaString="";
        this.payGradeDetail.payComponetId=this.payComponentIds;
        this.payGradeDetail.payGradeId=this.payGradeIds;
        this.payGradeDetail.sequence=1;
        this.payGradeDetailList.push(this.payGradeDetail);
        this.modalSave.emit(this.payGradeDetailList);
        this.close();
}

close(): void {
    this.active = false;
    this.modal.hide();
}

}
