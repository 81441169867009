import { AbpHttpInterceptor } from '@abp/abpHttpInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';

@NgModule({
    providers: [
        ApiServiceProxies.FinalSettlementAppServicesServiceProxy,
        ApiServiceProxies.CustomerMappingServiceProxy,
        ApiServiceProxies.TimeSheetReportServiceProxy,
        ApiServiceProxies.CompanyDetailServiceProxy,
        ApiServiceProxies.MyTeamServiceProxy,
        ApiServiceProxies.IncrementServiceProxy,
        ApiServiceProxies.HeadquarterServiceProxy,
        ApiServiceProxies.ReviewStatusServiceProxy,
        ApiServiceProxies.KPIAssignPolicyServiceProxy,
        ApiServiceProxies.KPICriteriaServiceProxy,
        ApiServiceProxies.PerformancePolicyServiceProxy,
        ApiServiceProxies.BirthdayWishesSettingServiceProxy,
        ApiServiceProxies.KraAppServicesServiceProxy,
        ApiServiceProxies.OnDutyTypesAppServicesServiceProxy,
        ApiServiceProxies.EmployeeAttendanceModeServiceProxy,
        ApiServiceProxies.MonthlyPFpdfServiceProxy,
        ApiServiceProxies.MonthlyESIPdfServiceProxy,
        ApiServiceProxies.ClearanceTaskListAppServicesServiceProxy,
        ApiServiceProxies.ExitTaskListServiceProxy,
        ApiServiceProxies.SeparationRequestServiceProxy,
        ApiServiceProxies.ExitInterviewAnswerServiceProxy,
        ApiServiceProxies.ExitInterviewServiceProxy,
        ApiServiceProxies.ReasonServiceProxy,
        ApiServiceProxies.TrnExpensesClaimServiceProxy, 
        ApiServiceProxies.ExpensePolicyServiceProxy,       
        ApiServiceProxies.ExpenseServiceProxy, 
        ApiServiceProxies.OffersTempletesServiceProxy,        
        ApiServiceProxies.CandidateDocumnetsesServiceProxy,        
        ApiServiceProxies.CandidateinterviewDetailsesServiceProxy,        
        ApiServiceProxies.SubLocationsServiceProxy,        
        ApiServiceProxies.JObRoundDetailsesServiceProxy,        
        ApiServiceProxies.TenancyConfiguratonWizardsServiceProxy,  
        ApiServiceProxies.ExpensePolicyMapAppServicesServiceProxy,      
       // ApiServiceProxies.MessageService,
        ApiServiceProxies.QuikLinksesServiceProxy,        
        ApiServiceProxies.ProvidentFundsServiceProxy,        
        ApiServiceProxies.LeaveBlancesesServiceProxy,        
        ApiServiceProxies.StatutoryConfigurationsServiceProxy,        
        ApiServiceProxies.ProfessionalsServiceProxy,        
        ApiServiceProxies.LabourWelfaresettingsServiceProxy,        
        ApiServiceProxies.FreezsalariesServiceProxy,        
        ApiServiceProxies.MonthWiseportattandnacesServiceProxy,        
        ApiServiceProxies.SalaryDetialsesServiceProxy,        
        ApiServiceProxies.SalariesServiceProxy,        
        ApiServiceProxies.ShiftDetailsesServiceProxy,        
        ApiServiceProxies.ReportSchemasesServiceProxy,        
        ApiServiceProxies.JobOpeningsServiceProxy,        
        ApiServiceProxies.PoliciesesServiceProxy,        
        ApiServiceProxies.AnnouncementsServiceProxy,        
        ApiServiceProxies.AttendancesServiceProxy,
        ApiServiceProxies.EmployeebankdetailsesServiceProxy,        
        ApiServiceProxies.BankBranchDetailsesServiceProxy,        
        ApiServiceProxies.BankBranchsServiceProxy,        
        ApiServiceProxies.EmployeeAccountDetailsServiceServiceProxy,  
        ApiServiceProxies.SubDepartmentsServiceProxy,        
        ApiServiceProxies.EmplolyeeCodeSettingsServiceProxy,        
        ApiServiceProxies.ContratorsServiceProxy,        
        ApiServiceProxies.WorkflowServiceProxy,
        ApiServiceProxies.EmployeeSalaryComponentsesServiceProxy,
        ApiServiceProxies.AdvanceRequestsServiceProxy,        
        ApiServiceProxies.SalaryAdvancesServiceProxy,        
        ApiServiceProxies.AdvanceTypesServiceProxy,        
        ApiServiceProxies.PayGradeDetailsesServiceProxy,        
        ApiServiceProxies.PayGradesServiceProxy,        
        ApiServiceProxies.LeaveRulesServiceProxy,        
        ApiServiceProxies.MarkAttendancesServiceProxy, 
        ApiServiceProxies.AttendanceRulesServiceProxy, 
        ApiServiceProxies.AssetsFnFServiceProxy,  
        ApiServiceProxies.PayablesFnFServiceProxy,   
        ApiServiceProxies.DuesOverridesFnFServiceProxy,  
        ApiServiceProxies.ReportingEmployeesFnFServiceProxy,      
        ApiServiceProxies.PayComponetsesServiceProxy,        
        ApiServiceProxies.RequestWorkflowStepsServiceProxy,        
           
        ApiServiceProxies.RequestForApprovalsServiceProxy,        
        ApiServiceProxies.LeaveRequestsesServiceProxy,        
        ApiServiceProxies.AttendanceCorrectionsServiceProxy,        
        ApiServiceProxies.ProvidentFundSettingsServiceProxy,        
        ApiServiceProxies.LeaveTypesServiceProxy,        
        ApiServiceProxies.ShiftsServiceProxy,        
        ApiServiceProxies.CitiesServiceProxy,        
        ApiServiceProxies.StatesServiceProxy,        
        ApiServiceProxies.CountriesServiceProxy,        
        ApiServiceProxies.CandidateQualificationsServiceProxy,        
        ApiServiceProxies.CandidatesServiceProxy,        
        ApiServiceProxies.DivisionsServiceProxy,        
        ApiServiceProxies.NationalitiesServiceProxy,        
        ApiServiceProxies.EmployeesServiceProxy,        
        ApiServiceProxies.DesignationsServiceProxy,        
        ApiServiceProxies.LocationsesServiceProxy,        
        ApiServiceProxies.DepartmentsesServiceProxy,        
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.DemoUiComponentsServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DropdownServicesServiceProxy, 
        ApiServiceProxies.HoldiayPoliciesServiceProxy  ,
        ApiServiceProxies.HolidaiesServiceProxy,
        ApiServiceProxies.HolidaypolicyDetailsesServiceProxy,
        ApiServiceProxies.DocumentTypeServiceProxy, 
        ApiServiceProxies.HRMSReportsServiceServiceProxy,
        ApiServiceProxies.EmployeeStateInsuranceServiceProxy,
        ApiServiceProxies.EmployeeVPFServiceProxy,
        ApiServiceProxies.CustomerMappingServiceProxy,
        ApiServiceProxies.AssetServiceProxy,
        ApiServiceProxies.AssetMasterServiceProxy,
        ApiServiceProxies.TrnAssetMasterDetailsesServiceProxy,
        ApiServiceProxies.TDSFeedingServiceProxy,
        ApiServiceProxies.EducationServiceProxy,
        ApiServiceProxies.LocationServiceProxy,
        ApiServiceProxies.InvestmentDeclarationAppServicesServiceProxy,
        ApiServiceProxies.ExpensePolicyServiceProxy,
        ApiServiceProxies.DataCapturingServiceProxy,
        ApiServiceProxies.MonthlyPerformanceReportServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
