import { Component, ViewChild, Injector, Output, EventEmitter} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { PayGradeDetailsesServiceProxy, CreateOrEditPayGradeDetailsDto, PayGradesServiceProxy, DropDown, CreateOrEditPayGradeDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';
import { PayGradeDetailsPayGradeLookupTableModalComponent } from './payGradeDetails-payGrade-lookup-table-modal.component';
import { PayGradeDetailsPayComponetsLookupTableModalComponent } from './payGradeDetails-payComponets-lookup-table-modal.component';
import { PayComponentSetValueComponent } from '../pay-component-set-value/pay-component-set-value.component';

@Component({
    selector: 'createOrEditPayGradeDetailsModal',
    templateUrl: './create-or-edit-payGradeDetails-modal.component.html'
})
export class CreateOrEditPayGradeDetailsModalComponent extends AppComponentBase {
   
    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @ViewChild('payGradeDetailsPayGradeLookupTableModal', { static: true }) payGradeDetailsPayGradeLookupTableModal: PayGradeDetailsPayGradeLookupTableModalComponent;
    @ViewChild('payGradeDetailsPayComponetsLookupTableModal', { static: true }) payGradeDetailsPayComponetsLookupTableModal: PayGradeDetailsPayComponetsLookupTableModalComponent;
    @ViewChild('payComponentSetValue', { static: true }) payComponentSetValue: PayComponentSetValueComponent;   
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() onMovetoSource: EventEmitter<any> = new EventEmitter(); 
    @Output() onTargetSelect: EventEmitter<any> = new EventEmitter(); 
    active = false;
    saving = false;
    sourceProducts: DropDown[];
    
    targetProducts: DropDown[];

    payGradeDetails: CreateOrEditPayGradeDto = new CreateOrEditPayGradeDto();
    payGradeDetailList: Array<CreateOrEditPayGradeDetailsDto> = new Array<CreateOrEditPayGradeDetailsDto>();
    payGradeDetailWithValueList: Array<CreateOrEditPayGradeDetailsDto> = new Array<CreateOrEditPayGradeDetailsDto>();
    payDetail:CreateOrEditPayGradeDetailsDto=new CreateOrEditPayGradeDetailsDto();
    payGradeName = '';
    payComponetsName = '';
    payGradeIds:number;
    paygradesource:string="";
    


    constructor(
        injector: Injector,
        private _payGradeDetailsesServiceProxy: PayGradeDetailsesServiceProxy,
        private _payGradesServiceProxy: PayGradesServiceProxy
    ) {
        super(injector);
    }
  
    show(payGradesId?:number,payGradeDetailsId?: number): void {
            this.payGradeDetails = new CreateOrEditPayGradeDto();
            this.payGradeDetails.id = payGradeDetailsId;
            this.payGradeIds=payGradesId;
        this._payGradeDetailsesServiceProxy.getPayGradeDetailsForEdit(payGradesId).subscribe(result => {
        this.payGradeDetails = result;
     
        if(this.payGradeDetails.payGradeDetails.length>0)
        {
            this.payGradeDetails.saveType="E";
        }
        else
        {
            this.payGradeDetails.saveType="A";
        }
        for(let i=0;i<result.payGradeDetails.length;i++)
        {
            this.payDetail=new CreateOrEditPayGradeDetailsDto();
            this.payDetail.calculationType=this.payGradeDetails.payGradeDetails[i].calculationType;
            this.payDetail.amount=parseFloat(this.payGradeDetails.payGradeDetails[i].amount.toString());
            this.payDetail.formulaString="";
            this.payDetail.payComponetId=this.payGradeDetails.payGradeDetails[i].payComponetId;
            this.payDetail.payGradeId=this.payGradeDetails.payGradeDetails[i].payGradeId;
            this.payDetail.sequence=this.payGradeDetails.payGradeDetails[i].sequence;
            this.payGradeDetailWithValueList.push(this.payDetail);
        }
        if(this.payGradeDetails.payGradeDetails.length>0)
        {
                this.paygradesource="";
                for(let i=0;i<this.payGradeDetails.payGradeDetails.length;i++)
                {
                    this.paygradesource=this.paygradesource==""?this.payGradeDetails.payGradeDetails[i].payComponetId.toString():this.paygradesource+","+this.payGradeDetails.payGradeDetails[i].payComponetId;
                }
                    this._payGradesServiceProxy.getPayComponentSource(this.paygradesource).subscribe(products =>
                    this.sourceProducts = products);
                    this._payGradesServiceProxy.getPayComponentTarget(this.paygradesource,this.payGradeIds).subscribe(products =>
                    this.targetProducts = products);
        }
        else
        {
            this._payGradesServiceProxy.getPayComponent().subscribe(products =>
            this.sourceProducts = products);
            this.targetProducts = [];
        }
      });
  
       

        this.active = true;
        this.modal.show();
        
    }
    setPayComponentValue(event)
    {
        this.payComponentSetValue.show(event.items[0].value,event.items[0].label,this.payGradeIds);
    }
    addControlSheetItem(event): void{
        for(let i=0;i<event.length;i++)
        {
            this.payDetail=new CreateOrEditPayGradeDetailsDto();
            this.payDetail.calculationType=event[i].calculationType;
            this.payDetail.amount=parseFloat(event[i].amount.toString());
            this.payDetail.formulaString="";
            this.payDetail.payComponetId=event[i].payComponetId;
            this.payDetail.payGradeId=event[i].payGradeId;
            this.payDetail.sequence=event[i].sequence;
            this.payGradeDetailWithValueList.push(this.payDetail);
        }
    }

    save(): void {
            this.saving = true;
            this.payGradeDetailList=new Array<CreateOrEditPayGradeDetailsDto>();
            //console.logthis.sourceProducts);
            //console.logthis.targetProducts)
            for(var i=0;i<this.targetProducts.length;i++)
            {
                let paydetails=new CreateOrEditPayGradeDetailsDto();
                    paydetails.payComponetId=this.targetProducts[i].value;
                    paydetails.payGradeId=this.payGradeIds;
                    paydetails.calculationType="";
                    paydetails.formulaString="";
                    paydetails.amount=0;
                    paydetails.sequence=i+1;
               for(let j=0;j<this.payGradeDetailWithValueList.length;j++)
               {
                    if(this.payGradeIds==this.payGradeDetailWithValueList[j].payGradeId && this.targetProducts[i].value==this.payGradeDetailWithValueList[j].payComponetId)
                    {
                        paydetails.calculationType=this.payGradeDetailWithValueList[j].calculationType;
                        paydetails.formulaString="";
                        paydetails.amount=this.payGradeDetailWithValueList[j].amount;
                    }
               }
               this.payGradeDetailList.push(paydetails);
            }
            this.payGradeDetails.payGradeDetails=this.payGradeDetailList;
         
            if(this.payGradeDetails.saveType=="E")
            {
                this.payGradeDetails.id=this.payGradeIds;
            }
            this._payGradeDetailsesServiceProxy.createOrEdit(this.payGradeDetails)
             .pipe(finalize(() => { this.saving = false;}))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
             });
    }

    openSelectPayGradeModal() {
        //this.payGradeDetailsPayGradeLookupTableModal.id = this.payGradeDetails.payGradeId;
        this.payGradeDetailsPayGradeLookupTableModal.displayName = this.payGradeName;
        this.payGradeDetailsPayGradeLookupTableModal.show();
    }
    openSelectPayComponetsModal() {
       // this.payGradeDetailsPayComponetsLookupTableModal.id = this.payGradeDetails.payComponetsId;
        this.payGradeDetailsPayComponetsLookupTableModal.displayName = this.payComponetsName;
        this.payGradeDetailsPayComponetsLookupTableModal.show();
    }


    setPayGradeIdNull() {
        //this.payGradeDetails.payGradeId = null;
        this.payGradeName = '';
    }
    setPayComponetsIdNull() {
        //this.payGradeDetails.payComponetsId = null;
        this.payComponetsName = '';
    }


    getNewPayGradeId() {
        //this.payGradeDetails.payGradeId = this.payGradeDetailsPayGradeLookupTableModal.id;
        this.payGradeName = this.payGradeDetailsPayGradeLookupTableModal.displayName;
    }
    getNewPayComponetsId() {
        //this.payGradeDetails.payComponetsId = this.payGradeDetailsPayComponetsLookupTableModal.id;
        this.payComponetsName = this.payGradeDetailsPayComponetsLookupTableModal.displayName;
    }


    close(): void {
        this.active = false;
        this.modal.hide();
    }
}
