import { Component, ViewChild, Injector, Output, EventEmitter } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { GetPayGradeForViewDto, PayGradeDto, PayGradesServiceProxy } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
    selector: 'viewPayGradeModal',
    templateUrl: './view-payGrade-modal.component.html'
})
export class ViewPayGradeModalComponent extends AppComponentBase {

    @ViewChild('createOrEditModal', { static: true }) modal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;
    name:any[]= []; 

    item: GetPayGradeForViewDto;   
   
    paygrade:GetPayGradeForViewDto[];


    constructor(
        injector: Injector,
        private _payGradesServiceProxy: PayGradesServiceProxy,
    ) {
        super(injector);
        this.item = new GetPayGradeForViewDto();
        this.item.payGrade = new PayGradeDto();
    }

    show(item: GetPayGradeForViewDto): void {
        
        this.item = item;
        this.active = true;
        this.modal.show();
        this._payGradesServiceProxy.getAllView(item.payGrade.name).subscribe(result => {
           
            
            this.paygrade = result.items;
            for(var i = 0; i<result.items.length;i++){
                this.name.push({label:result.items[i].componetsName});
            }
            this.primengTableHelper.hideLoadingIndicator();

        });

    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }
}
