import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AttendancesServiceProxy, CreateOrEditMarkAttendanceDto, GpsLocationLatLon, MarkAttendancesServiceProxy } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { formatDate, ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pup-up-markattendance',
  templateUrl: './pup-up-markattendance.component.html',
  styleUrls: ['./pup-up-markattendance.component.css']
})
export class PupUpMarkattendanceComponent extends AppComponentBase {
  @ViewChild('createOrEditModal', {static:true }) modal: ModalDirective;
  @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
  active = false;
  saving = false;
  today:any;
  lat:any;
  lon:any;
  latlonArray: Array<GpsLocationLatLon> = [];
  markAttendance: CreateOrEditMarkAttendanceDto = new CreateOrEditMarkAttendanceDto();
  currdate = new Date()
  title:string;
  marked: boolean = false;
  
  constructor(
    injector: Injector,
    private router: Router,
    private _markAttendanceServiceProxy: MarkAttendancesServiceProxy,
    private _candiateSerive:AttendancesServiceProxy
  ) {
    super(injector);
    (moment as any).fn.toString = function() { return this.format('HH:mm'); };
   }

  ngOnInit() {
    var curHr = this.currdate.getHours()
    if (curHr < 12) {
      this.title='Good Morning';
    } else if (curHr < 18) {
      this.title='Good Afternoon';
    } else {
      this.title='Good Evening';
    }
  }
  show(): void {
    
    this.latlonArray=new Array<GpsLocationLatLon>();
    (moment as any).fn.toString = function() { return this.format('HH:mm'); };
    this.today=moment(new Date());
        this.active = true;
        this.modal.show();
      }
      save(): void {
        
        this.saving = true;
        this.getPosition();
        this.markAttendance.gpsLocations=this.latlonArray;
        let currentDate=new Date();
        this.markAttendance.punchTime=moment(new Date()).toLocaleString();
          this._markAttendanceServiceProxy.createOrEdit(this.markAttendance)
         .pipe(finalize(() => { this.saving = false;}))
         .subscribe(() => {
             this.notify.info(this.l('Attendance marked'));
            this._candiateSerive.syncAttandance();
            this.close();
            this.marked = true;
            this.modalSave.emit(null);
            if(this.marked == true){
              setTimeout(() => {
                window.location.reload();
              }, 2000); // 3000 milliseconds = 3 seconds
            }
         });
       
         
}
getPosition(){
  navigator.geolocation.getCurrentPosition( pos => {
    this.lon = +pos.coords.longitude;
    this.lat = +pos.coords.latitude;
  });
let locations=new GpsLocationLatLon()
locations.lattitude=this.lat;
locations.longitude=this.lon;
this.latlonArray.push(locations);
}


close(): void {
  
  this.active = false;
  this.modal.hide();
}

}
