import { Component, ViewChild, Injector, Output, EventEmitter, Input, ElementRef} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { DepartmentsesServiceProxy, CreateOrEditDepartmentsDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/common/app-component-base';
import * as moment from 'moment';
import { read } from 'fs';

@Component({
    selector: 'createOrEditDepartmentsModal',
    templateUrl: './create-or-edit-departments-modal.component.html'
})
export class CreateOrEditDepartmentsModalComponent extends AppComponentBase {
   
    @ViewChild('createOrEditModal',{static:true}) modal: ModalDirective;
    @ViewChild("inputBox",{static:true}) _el: ElementRef;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    active = false;
    saving = false;
    isFocus: boolean = true;
    departments: CreateOrEditDepartmentsDto = new CreateOrEditDepartmentsDto();
    @Input('focuMe') isFocused: boolean;


    constructor(
        injector: Injector,
        private _departmentsesServiceProxy: DepartmentsesServiceProxy,
        private hostElement: ElementRef
    ) {
        super(injector);
    }
    onShown(): void {

        document.getElementById('Departments_Name').focus();
    }
   
    show(departmentsId?: number): void {
    

        if (!departmentsId) {
            this.departments = new CreateOrEditDepartmentsDto();
            this.departments.id = departmentsId;
            this.active = true;
            this.modal.show();
        } else {
            this._departmentsesServiceProxy.getDepartmentsForEdit(departmentsId).subscribe(result => {
                this.departments = result.departments;

                this.active = true;
                this.modal.show();
            });
        }
        
    }

    save(): void {

            this.saving = true;
         if(this.departments.name=="" || this.departments.name==undefined)
         {
             this.notify.error("Please enter department")
             this.saving = false;
             return ;
         }
			
			
            this._departmentsesServiceProxy.createOrEdit(this.departments)
             .pipe(finalize(() => { this.saving = false;}))
             .subscribe(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
             });
    }







    close(): void {
        this.active = false;
        this.modal.hide();
    }
}
