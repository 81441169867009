import {
    Component,
    ViewChild,
    Injector,
    Output,
    EventEmitter,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { finalize } from "rxjs/operators";
import {
    CreateOrEditRequestWorkflowStepDto,
    DropDown,
    DropdownServicesServiceProxy,
    WorkflowServiceProxy,
    WorkFlowSteps,
} from "@shared/service-proxies/service-proxies";
import { AppComponentBase } from "@shared/common/app-component-base";
import * as moment from "moment";
import { values } from "lodash";

@Component({
    selector: "createOrEditRequesrFlowModal",
    templateUrl: "./create-or-edit-requesrFlow-modal.component.html",
})
export class CreateOrEditRequesrFlowModalComponent extends AppComponentBase {
    @ViewChild("createOrEditModal", { static: true }) modal: ModalDirective;

    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    public Reqt: any = [
        { label: "Self", value: 0 },
        { label: "Advance Request", value: 1 },
        { label: "AttendanceCorrection", value: 2 },
        { label: "Leave Request", value: 3 },
        { label: "On Duty", value: 4 },
        { label: "Update Personal Details", value: 5 },
        { label: "Job Opening", value: 6 },
        { label: "Expense", value: 7 },
        { label: "Separation Request", value: 8 },
        { label: "Time Sheet Activity", value: 9 },
        { label: "Increment Request", value: 10 },
        {label:"Comoff Leave",value:11},
    ];
    public HierarchyDropDown: any=[{label: "Incremental",value :1},{label : "Parallel" ,value:2}];

    roles: DropDown[];
    active = false;
    saving = false;

    requesrFlow: CreateOrEditRequestWorkflowStepDto =
        new CreateOrEditRequestWorkflowStepDto();

    updateddon: Date;
    dynamicArray: Array<WorkFlowSteps> = [];
    newDynamic: WorkFlowSteps[];
    levelvalue : number;

    constructor(
        injector: Injector,
        private _requesrFlowsServiceProxy: WorkflowServiceProxy,
        private _dropdown: DropdownServicesServiceProxy
    ) {
        super(injector);
        (moment as any).fn.toString = function () {
            return this.format("DD/MM/YYYY");
        };
    }

    show(requesrFlowId?: number): void {
        debugger
        this.updateddon = null;

        if (!requesrFlowId) {
            this.requesrFlow = new CreateOrEditRequestWorkflowStepDto();
            this.dynamicArray = new Array<WorkFlowSteps>();
            this.requesrFlow.id = requesrFlowId;
            this.requesrFlow.description = "";

            this.active = true;
            this.modal.show();
        } else {
            this._requesrFlowsServiceProxy
                .getRequesrFlowForEdit(requesrFlowId)
                .subscribe((result) => {
                    this.requesrFlow.id = requesrFlowId;
                    this.requesrFlow.type = result.workFlow.type;
                    this.requesrFlow.name = result.workFlow.name;
                    this.requesrFlow.description = result.workFlow.description;

                    this.dynamicArray = new Array<WorkFlowSteps>();
                    for (var i in result.workFlow.steps) {
                        let item = new WorkFlowSteps();
                        item.level = result.workFlow.steps[i].level;
                        item.intimationOnly =
                            result.workFlow.steps[i].intimationOnly;
                        item.roleId = result.workFlow.steps[i].roleId;
                        item.turnAroundTime =
                            result.workFlow.steps[i].turnAroundTime;
                            item.hierarchy = result.workFlow.steps[i].hierarchy;
                        this.dynamicArray.push(item);
                    }
                    this.active = true;
                    this.modal.show();
                });
        }
    }
    levelSelector(hierarchy: number,level?: number,dynamiclevel?: number)  {
        if(hierarchy == 1)//incremental
        {
            if (this.dynamicArray.length > 1) 
                {
                const lastLevel = this.dynamicArray[level-2].level;
                this.dynamicArray[level - 1].level =  lastLevel + 1 ;
              }
              else
              this.dynamicArray[level - 1].level =  level;
        }
        else//h
        {
            const lastLevel = this.dynamicArray[level-2].level;
            this.dynamicArray[level-1].level = level >= 1 ? lastLevel : level;

        }
       
    }

    filterRoles() {
        this._dropdown.getRoles().subscribe((result) => {
            this.roles = result;
        });
    }
    save(): void {
        this.saving = true;
        this.requesrFlow.name = "";
        //console.log"list", this.dynamicArray);
        if (this.dynamicArray.length == 0) {
            this.notify.error("Pls Select Work Flows");
            this.saving = false;
            return;
        }
        this.newDynamic = new Array<WorkFlowSteps>();
        for (var i in this.dynamicArray) {
            let item = new WorkFlowSteps();
            item.hierarchy = this.dynamicArray[i].hierarchy;
            item.level =this.dynamicArray[i].level;
            item.intimationOnly = this.dynamicArray[i].intimationOnly;
            item.roleId = this.dynamicArray[i].roleId;
            item.turnAroundTime = this.dynamicArray[i].turnAroundTime;
            this.newDynamic.push(item);
        }

        this.requesrFlow.steps = this.newDynamic;
        this._requesrFlowsServiceProxy
            .createOrEdit(this.requesrFlow)

            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .subscribe(() => {
                this.notify.info(this.l("SavedSuccessfully"));
                this.close();
                this.modalSave.emit(null);
            });
    }

    ngOnInit(): void {
        // this.newDynamic = {: "", indate: "",intime:"", outdate: "",outtime:"",remarks:""};
        this.newDynamic = new Array<WorkFlowSteps>();
        // //   this.newDynamic.push(dip)
        //   this.dynamicArray.push(dip);
        this.filterRoles();
    }

    addRow() {
        
       
        let dip = new WorkFlowSteps();
        this.dynamicArray.push(dip);
        // this.notify.success('New row added successfully', 'New Row');
        // //console.logthis.dynamicArray);
        return true;
    }

    deleteRow(index) {
        if (this.dynamicArray.length == 1) {
            this.notify.error("Can Not Delete Last Row", "Warning");
            return false;
        } else {
            this.dynamicArray.splice(index, 1);

            return true;
        }
    }
    cleargriddata() {
        this.newDynamic = new Array<WorkFlowSteps>();
        this.dynamicArray = new Array<WorkFlowSteps>();
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }
}

export class DynamicGrid {
    date: string;
    indate: string;
    intime: string;
    outdate: string;
    outtime: string;
    remarks: string;
}
