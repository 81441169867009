import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { ReviewStatusServiceProxy, SubscriptionStartType, TenantDashboardServiceProxy } from '@shared/service-proxies/service-proxies';
import { ChatSignalrService } from 'app/shared/layout/chat/chat-signalr.service';
import * as moment from 'moment';
import { AppComponentBase } from 'shared/common/app-component-base';
import { SignalRHelper } from 'shared/helpers/SignalRHelper';
import { LinkedAccountsModalComponent } from '@app/shared/layout/linked-accounts-modal.component';
import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { NotificationSettingsModalComponent } from '@app/shared/layout/notifications/notification-settings-modal.component';
import { UserNotificationHelper } from '@app/shared/layout/notifications/UserNotificationHelper';
import { PupUpMarkattendanceComponent } from './main/markattendance/pup-up-markattendance/pup-up-markattendance.component';
import { CrateOrEditEmployeeReviewPopUpComponent } from './main/employee-self-review/crate-or-edit-employee-review-pop-up/crate-or-edit-employee-review-pop-up.component';

@Component({
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent extends AppComponentBase implements OnInit {
    
    subscriptionStartType = SubscriptionStartType;
    theme: string;
    installationMode = true;
    buttonText = 'Initiate Review';
    reviewID : number;
    
    @ViewChild('loginAttemptsModal', {static: true}) loginAttemptsModal: LoginAttemptsModalComponent;
    @ViewChild('linkedAccountsModal', {static: false}) linkedAccountsModal: LinkedAccountsModalComponent;
    @ViewChild('changePasswordModal', {static: true}) changePasswordModal: ChangePasswordModalComponent;
    @ViewChild('changeProfilePictureModal', {static: true}) changeProfilePictureModal: ChangeProfilePictureModalComponent;
    @ViewChild('mySettingsModal', {static: true}) mySettingsModal: MySettingsModalComponent;
    @ViewChild('notificationSettingsModal', {static: true}) notificationSettingsModal: NotificationSettingsModalComponent;
    @ViewChild('chatBarComponent', {static: false}) chatBarComponent;
    @ViewChild('pupUpMarkattendance', { static: true }) pupUpMarkattendance: PupUpMarkattendanceComponent;
    @ViewChild('employeeReviewPopUp',{static:true}) employeeReviewPopUp:CrateOrEditEmployeeReviewPopUpComponent;

    isQuickThemeSelectEnabled: boolean = this.setting.getBoolean('App.UserManagement.IsQuickThemeSelectEnabled');

    public constructor(
        injector: Injector,
        private _chatSignalrService: ChatSignalrService,
        private _userNotificationHelper: UserNotificationHelper,
        private _dashboardService:TenantDashboardServiceProxy,
        private _reviewStatusServiceProxy: ReviewStatusServiceProxy,
    ) {
        super(injector);
    }

    ngOnInit(): void {
        this._userNotificationHelper.settingsModal = this.notificationSettingsModal;
        this.theme = abp.setting.get('App.UiManagement.Theme').toLocaleLowerCase();
        this.installationMode = UrlHelper.isInstallUrl(location.href);

        this.registerModalOpenEvents();
        if (this.isGranted('Pages.Tenant.Dashboard'))
            this.showInactiveTenancyAlert()

        if (this.appSession.application) {
            SignalRHelper.initSignalR(() => { this._chatSignalrService.init(); });
        }
    }

    
    tenancynotification = null;
    showInactiveTenancyAlert() {
        this._dashboardService.showInActiveTenancyNotification().subscribe(message => {
            if (message !== null)
                this.tenancynotification = message
        })
    }


    subscriptionStatusBarVisible(): boolean {
        return this.appSession.tenantId > 0 &&
            (this.appSession.tenant.isInTrialPeriod ||
                this.subscriptionIsExpiringSoon());
    }

    subscriptionIsExpiringSoon(): boolean {
        if (this.appSession.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNootifyDayCount, 'days') >= moment(this.appSession.tenant.subscriptionEndDateUtc);
        }

        return false;
    }

    registerModalOpenEvents(): void {

        abp.event.on('app.show.loginAttemptsModal', () => {
            this.loginAttemptsModal.show();
        });

        abp.event.on('app.show.linkedAccountsModal', () => {
            this.linkedAccountsModal.show();
        });

        abp.event.on('app.show.changePasswordModal', () => {
            this.changePasswordModal.show();
        });

        abp.event.on('app.show.changeProfilePictureModal', () => {
            this.changeProfilePictureModal.show();
        });

        abp.event.on('app.show.mySettingsModal', () => {
            this.mySettingsModal.show();
        });
        abp.event.on('app.show.pupUpMarkattendance', () => {
            this.pupUpMarkattendance.show();
        });
        abp.event.on('app.show.employeeReviewPopUp', (reviewId) => {
            this.employeeReviewPopUp.show(reviewId);
        });
    }

    getRecentlyLinkedUsers(): void {
        abp.event.trigger('app.getRecentlyLinkedUsers');
    }

    getButtonText(){
        this._reviewStatusServiceProxy.checkPendingReviewForEmployeeForPopUP().subscribe(result=>{
            if(result != null || result.length != 0)
            {
              this.buttonText = result[0].buttonText;
              this.reviewID = result[0].reviewId;
            }
            
           });
    }
    onMySettingsModalSaved(): void {
        abp.event.trigger('app.onMySettingsModalSaved');
    }
}
